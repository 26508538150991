.RelatedArticlesByCollection {
  padding: 1rem 0 0.5rem 1rem;
  background-color: color('collection-background-grey');
  margin-right: -0.9375rem;

  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1080px) {
    margin-right: calc(-1 * 100% / 12);
    margin-left: calc(100% / 12);
  }

  @include media('md-up') {
    float: right;

    width: 30%;
    max-width: 372px;
    min-width: 288px;

    margin-left: 1.5rem;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0.5rem;

    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }

  @include media('lg') {
    margin-right: 8.3333333333%;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
  }

  .header {
    padding-right: 1rem;
    .tag {
      color: color('red');
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding-bottom: 0.5rem;
    }

    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 30px;
    }

    .description {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .articles {
    display: flex;
    overflow-x: auto;

    margin-left: -1rem;
    padding-left: 1rem;

    @include media('md-up') {
      flex-direction: column;

      margin-left: 0;
      padding-left: 0;
    }

    .TextStoryTeaserWidget {
      border-right: none;
      border-bottom: none;

      position: relative;

      width: 33%;
      min-width: 19.5rem;
      height: auto;

      padding-top: 0;
      padding-right: 1rem;
      padding-bottom: 1.5rem;
      margin-right: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      &:last-child {
        &::after {
          content: unset;
        }
      }

      &::after {
        background-image: linear-gradient(
          to bottom,
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right;
        background-size: 1px 3px;
        background-repeat: repeat-y;

        content: '';
        height: 100%;
        position: absolute;
        right: 0;
        width: 1px;
      }

      @include media('md-up') {
        padding-right: 0rem;
        margin-right: 0rem;

        width: auto;
        min-width: auto;

        &:last-child {
          &::after {
            content: '';
          }
        }

        &::after {
          background-image: linear-gradient(
            to right,
            color('black') 24%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: top;
          background-size: 4px 1px;
          background-repeat: repeat-x;

          content: '';
          height: 1px;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &:first-child {
          &::after {
            background-image: none;
          }
        }
      }

      .TextStoryTeaserWidget__section-button {
        padding-top: 0.5rem;
      }
    }
  }
}
