.ErrorPage {
  position: relative;

  &__container {
    max-width: 39rem;
  }

  &__title {
    font-size: 3.75rem;
  }

  &__button {
    border: $border-black;
    min-width: 15rem;
  }

  &__eventId {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    font-family: Consolas, monaco, monospace;
    font-size: 11px;

    button {
      outline: none;
      color: inherit;
      background-color: transparent;
      font-family: inherit;
      color: #e4e4e0;
    }
  }
}
