.ArticleNav {
  &__logo {
    animation: $transition-animate-disappear-to-top;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &__logo--active {
    animation: $transition-animate-appear-from-bottom;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &__article-info {
    animation: $transition-animate-disappear-to-bottom;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &__article-info--active {
    animation: $transition-animate-appear-from-top;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &__section {
    &--active {
      pointer-events: none;
      opacity: 0;

      @include media('lg-up') {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &__title {
    font-size: 1.25rem;

    @include media('lg-up') {
      font-size: 1.5rem;
    }
  }

  .ShareButton__text--print {
    padding-left: 30px;
    background: color('off-white');
  }

  &__share-button-container::before {
    content: '';
    position: absolute;
    height: 100%;
    right: -1rem;
    width: 175%;
    background: linear-gradient(
      90deg,
      color('transparent') 0%,
      color('off-white') 30%,
      color('off-white') 100%
    );
    z-index: -1;
  }

  &__share-button::before {
    content: '';
    position: absolute;
    background-color: color('off-white');
    z-index: -1;
    height: calc(#{$nav-mobile-height} - 2px);

    @include media('lg-up') {
      height: calc(#{$nav-desktop-height} - 2px);
    }
  }

  &__share-button::after {
    content: '';
    position: absolute;
    height: 100%;
    right: 0;
    width: 150%;
    background: linear-gradient(
      90deg,
      color('transparent') 0%,
      color('off-white') 30%,
      color('off-white') 100%
    );
    z-index: -1;
  }

  &__section-logo {
    height: 0.8125rem;

    @include media('lg-up') {
      opacity: 1;
    }
  }

  &__logo-icon {
    width: 7rem;

    @include media('lg-up') {
      width: 9.875rem;
    }
  }
}
