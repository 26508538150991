.SectionPage {
  min-width: 100vw;
  max-width: 100vw;

  &__overlay {
    &:hover {
      .SectionPage__arrow {
        transform: translateY(0.2rem);
      }
    }
  }

  &__overlay-section {
    background-image: linear-gradient(
      color('transparent') 65%,
      color('beige') 90%,
      color('beige') 100%
    );
  }

  &__overlay-home {
    background-image: linear-gradient(
      color('transparent') 65%,
      color('off-white') 90%,
      color('white') 100%
    );
  }

  &--inactive {
    overflow: hidden;
    max-height: 100vh;
    opacity: 0;
    transition-delay: $transition-duration-medium;
  }
}
