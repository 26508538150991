.ArchiveView {
  .TopBar__container {
    border-bottom: none;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    padding-bottom: 0.5rem;
    padding-top: 3.75rem;
  }
}
