.TagWidget {
  padding-left: 0;
  padding-top: 0;

  @include media('sm-down') {
    padding-right: 0;
    border-right: none;
  }

  @include media('md-up') {
    border-top: $border-black;
  }

  &--small,
  &--medium,
  &--large {
    &__article {
      @extend %dotted-border-right-black;

      @include media('sm-down') {
        width: 9.375rem;
      }

      @include media('md-up') {
        background-image: linear-gradient(
          to right,
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }
    }
  }

  &--small,
  &--medium {
    &__featured-article {
      @extend %dotted-border-right-black;

      @include media('md-up') {
        background-image: linear-gradient(
          to right,
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }
    }
  }

  &--large {
    &__article {
      &:first-of-type {
        @include media('md-up') {
          padding-top: 0;
        }
      }
    }
  }

  &__header-container {
    @include media('sm-down') {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }

    @include media('md-up') {
      border-bottom: $border-black;
    }
  }

  &__articles-container {
    div:last-of-type {
      background-image: none;
    }
  }

  &__tag-title {
    @include media('sm-down') {
      font-size: 1.5rem;
    }
  }

  &__article-title {
    @include media('sm-down') {
      font-size: 1.3125rem;
    }
  }

  &__brief {
    @include media('sm-down') {
      font-size: 1.125rem;
    }
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }
}
