$related-articles-width: 18.8125rem;
$related-article-image-width: 17.25rem;
$related-article-image-height: 12.75rem;

.RelatedArticles {
  @include media('lg') {
    margin-right: 8.3333333333%;
  }

  &--small,
  &--large,
  &--with-image {
    border-bottom: $border-gray-darkest;

    @include media('lg-up') {
      width: $related-articles-width;
      border-left: $border-gray-darkest;
    }
  }

  &__header {
    border-top: $border-black;
    padding-bottom: 1.125rem;
    padding-top: 1.8125rem;

    @include media('lg-up') {
      border-top: 0;
      border-bottom: $border-black;
      background-image: none;
      padding: 1.5rem 2rem;
    }
  }

  &__articles-container {
    @media (max-width: 1079px) {
      overflow: auto;
      margin-left: -0.9375rem;
      margin-right: -0.9375rem;

      > div:first-of-type {
        padding-left: 0.9375rem;
      }
    }
  }

  &__article-container {
    @media (max-width: 1079px) {
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
      width: 33.333%;
      min-width: 19.5rem;
      margin-top: 0.75rem;
      margin-bottom: 1.625rem;
      padding-right: 0.9375rem;
      padding-left: 0.75rem;
    }

    @include media('sm-down') {
      min-width: 9.375rem;
      margin-top: 0.625rem;
      margin-bottom: 1.875rem;
      padding-right: 1.4375rem;
      padding-left: 0.9375rem;
    }

    &:last-of-type {
      background-image: none;
    }
  }

  &__image {
    width: $related-article-image-width;

    .grayscale-img {
      filter: grayscale(1);
      mix-blend-mode: multiply;
    }

    .color-img {
      opacity: 0;
      transition: $transition-duration-shorter $transition-easing;
    }

    &:hover {
      .color-img {
        opacity: 1;
      }
    }
  }

  &__section-icon {
    max-width: 8rem;
  }

  &__brief {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  a::after {
    display: none;
  }

  &__article-title {
    font-size: 1.3125rem;
    line-height: 1.5rem;

    @include media('md-up') {
      font-size: 1.75rem;
      line-height: 1.875rem;
      padding-bottom: 0.875rem;
    }
  }

  &__podcast-link {
    text-decoration: underline;
    text-decoration-color: color('transparent');

    &:hover {
      text-decoration-color: color('black');
    }
  }

  &__hidden-section {
    visibility: hidden;
  }
}
