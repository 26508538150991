.LatestArticles {
  margin-right: -2rem;
  position: relative;
  margin-bottom: 1.5rem;

  @include media('sm-up') {
    margin-right: auto;
  }

  @include media('xl-up') {
    margin-bottom: 2.5rem;
  }

  &__container {
    @include media('lg-up') {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }

  &__section {
    @include media('md-up') {
      border-bottom: $border-black;
      border-right: $border-black;
    }

    &:last-of-type {
      border-bottom: $border-black;
    }

    &__date {
      padding-top: 1.75rem;

      &:not(:first-of-type) {
        border-top: $border-black;
      }

      &__title {
        font-size: 1.5rem;
        line-height: 1.125rem;
        margin-right: 2.125rem;

        @include media('md-up') {
          line-height: 1.5rem;
        }

        @include media('lg-up') {
          font-size: 1.875rem;
          line-height: 1.25rem;
        }
      }

      &__day {
        font-size: 1.125rem;
        line-height: 1.25rem;
        margin-bottom: 11px;
      }
    }

    &__stories {
      padding-top: 0.75rem;

      @include media('md-up') {
        background-image: none;
        border-top: $border-black;
      }
    }

    &__story {
      min-width: 80vw;
      padding-top: 0.65rem;

      @include media('md-up') {
        min-width: auto;
        padding-bottom: 1.5rem;

        &:not(:first-of-type) {
          padding-top: 1.35rem;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }

      // dotted border between stories
      &:not(:last-of-type) {
        background-position: right;
        background-repeat: repeat-y;
        background-size: 1px 3px;
        background-image: linear-gradient(
          to bottom,
          color('black') 33%,
          transparent 0%
        );
        @include media('md-up') {
          background-position: bottom;
          background-repeat: repeat-x;
          background-size: 4px 1px;
          background-image: linear-gradient(
            to right,
            color('black') 24%,
            transparent 0%
          );
        }
      }

      &__title {
        font-size: 1.875rem;
        line-height: 2rem;
        margin-bottom: 1rem;

        @include media('md-up') {
          font-size: 1.5rem;
          line-height: 1.75rem;
          margin-bottom: 0.25rem;
        }

        @include media('lg-up') {
          font-size: 1.75rem;
          line-height: 1.875rem;
        }

        @include media('lg') {
          font-size: 1.875rem;
          line-height: 2rem;
        }
      }

      &__dek {
        font-size: 1.25rem;
        line-height: 1.5rem;
        margin-bottom: 2.125rem;

        @include media('md-up') {
          margin-bottom: 0.85rem;
          font-size: 1.125rem;
        }
      }
    }
  }
}
