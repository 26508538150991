$mock-bar1-width: 2.3125rem;

.HomeSidebar {
  width: $mock-bar1-width;

  &:hover {
    .HomeSidebar__button {
      transform: translate3d(calc(#{$mock-bar1-width} * -1), 0, 0);
    }

    .HomeSidebar__mock-bar1 {
      transform: translate3d(0, 0, 0);
      box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.3);
    }

    .HomeSidebar__mock-bar2 {
      transform: translate3d(0, 0, 0);
      box-shadow: -10px -10px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &__button {
    @extend .transition;
    transform: translate3d(0, 0, 0);
    width: $bar-width;
    box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.3);

    &__inner {
      transform: rotate(90deg);
    }
  }

  &__mock-bar1 {
    transition-delay: 0.05s;
    width: $mock-bar1-width;
    transform: translate3d($mock-bar1-width, 0, 0);

    &:hover {
      .HomeSidebar__button {
        transform: translate3d(calc(#{$mock-bar1-width} * -1), 0, 0);
      }

      .HomeSidebar__mock-bar1 {
        box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.3);
      }

      .HomeSidebar__mock-bar2 {
        box-shadow: -10px -10px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__mock-bar2 {
    transition-delay: 0.07s;
    width: 0.875rem;

    &:hover {
      .HomeSidebar__button {
        transform: translate3d(calc(#{$mock-bar1-width} * -1), 0, 0);
      }

      .HomeSidebar__mock-bar1 {
        transform: translate3d(0, 0, 0);
        box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.3);
      }

      .HomeSidebar__mock-bar2 {
        transform: translate3d(0, 0, 0);
        box-shadow: -10px -10px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__icon {
    transform: rotate(-90deg);
  }

  &__text {
    max-height: 1rem;
    margin: 0 1.1em 0.1em;
    font-size: 1.125rem;
    letter-spacing: 0.0625rem;
  }

  &__arrow {
    font-size: 1.5rem;
    transform: rotate(-90deg);
  }
}
