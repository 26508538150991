.SectionNavC {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 0.9375rem;
  padding-top: 0.9375rem;

  @include media('md-up') {
    grid-row-gap: 1.5rem;
    padding-top: 1.5rem;
  }

  &__image {
    margin-bottom: 1.0625rem;

    @include media('md-up') {
      margin-bottom: 0.75rem;
    }

    @include media('lg-up') {
      margin-bottom: 0.8125rem;
    }

    @media (min-width: 1440px) {
      margin-bottom: 1.25rem;
    }
  }

  &__brief {
    text-align: center;
    margin-bottom: 0.625rem;

    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('md-up') {
      margin-bottom: 1.3125rem;
      font-size: 1.25rem;
    }

    @media (min-width: 1440px) {
      margin-bottom: 0.3125rem;
    }
  }

  &__article1 {
    padding-bottom: 0.9375rem;

    @include media('md-up') {
      padding-bottom: 1.8125rem;
    }

    @media (min-width: 1440px) {
      padding-bottom: 2.5rem;
    }

    .SectionNavC__title {
      font-weight: 600;
      text-align: center;
      font-size: 1.75rem;
      line-height: 1.875rem;

      @include media('md-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }

      @media (min-width: 1440px) {
        margin-bottom: 1.3125rem;
        font-weight: 300;
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.016875rem;
      }

      .SectionNavC__brief {
        text-align: center;
        margin-bottom: 0.625rem;

        font-size: 1.125rem;
        line-height: 1.5rem;

        @include media('md-up') {
          margin-bottom: 1.3125rem;
          font-size: 1.25rem;
        }

        @media (min-width: 1440px) {
          margin-bottom: 1.0625rem;
        }
      }
    }

    .AuthorButtons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__article-rest {
    .SectionNavC__title {
      font-weight: 600;
      text-align: center;
      font-size: 1.75rem;
      line-height: 1.875rem;

      @include media('md-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }

      @media (min-width: 1440px) {
        font-size: 1.875rem;
        line-height: 2rem;
        margin-bottom: 0.75rem;
      }
    }
  }
}
