.FeatureArticleView {
  overflow: hidden;

  &__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      border-bottom: 1px solid currentColor;
      width: 100%;
    }
  }

  &__button {
    justify-content: flex-start;
  }

  &__button-text {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  &__arrow-icon {
    transform: rotate(180deg);
    font-size: 1.25rem;
  }

  &__border {
    margin-top: 1.1875rem;
  }

  &__authors {
    p {
      font-family: sans-serif;
      font-size: 0.85rem;
      margin-top: 2rem;
    }

    a {
      color: var(--black);
    }
  }

  &__footer {
    margin-top: clamp(5rem, calc(4.56rem + 1.8vw), 6rem);
  }

  &__footer-sections {
    border-top: 1.5rem solid var(--black);
    padding-top: 1rem;

    &.auto-grid {
      display: grid;
      grid-template-columns: repeat(
        var(--auto-grid-placement, auto-fit),
        minmax(var(--auto-grid-min-item-size, 16rem), 1fr)
      );
      column-gap: clamp(0.94rem, calc(0.69rem + 1.01vw), 1.5rem);
      row-gap: clamp(1.25rem, calc(1.14rem + 0.45vw), 1.5rem);

      li {
        border-right: 1px solid var(--black);
        border-bottom: 1px solid var(--black);
        padding: clamp(0.94rem, calc(0.86rem + 0.34vw), 1.13rem);
        text-align: center;
        list-style-type: none;

        a {
          text-align: center;
        }

        img {
          display: inline-block;
          height: 1.5rem;
          margin: 0 auto;

          &.w-95 {
            width: 95%;
          }
        }
      }
    }
  }

  &__secondary-footer {
    margin-top: clamp(3.75rem, calc(3.42rem + 1.35vw), 4.5rem);
    border-top: 1px solid var(--black);
    justify-content: space-between;
  }

  &__secondary-footer-links div {
    display: flex;
  }

  .body-text-color {
    [class*='color-'] {
      color: inherit;
    }

    a,
    span,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote {
      color: inherit;
    }

    [class*='linked-border-bottom-'] {
      background-image: linear-gradient(currentColor, currentColor);
    }

    [class*='border-'] {
      border-color: currentColor;
    }

    .InsetImg {
      border-color: currentColor;
    }

    .Nav__logo-icon {
      g {
        fill: currentColor;
      }
    }

    .Divider--short-rule:before {
      background-color: currentColor;
    }

    .Divider--rule {
      border-color: currentColor;
    }

    .FeatureArticleView {
      &__footer-sections {
        border-top-color: currentColor;

        li {
          border-color: currentColor;
        }

        svg {
          fill: currentColor;
        }
      }

      &__secondary-footer {
        border-top-color: currentColor;
      }
    }

    .SocialMediaLinks {
      path {
        fill: currentColor;
      }
    }

    %linked-border-bottom-black {
      background-image: linear-gradient(currentColor, currentColor);
    }
  }
}
