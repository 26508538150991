.AuthorDateButtons {
  word-break: break-word;
  font-size: 12px;
  line-height: 0.75rem;

  &__button {
    text-decoration: none;
  }

  &__button:after {
    display: none;
  }

  &__button-text {
    position: relative;
    text-decoration: underline;
    text-decoration-color: color('transparent');

    &:hover {
      text-decoration-color: color('black');
    }
  }
}
