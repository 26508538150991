.ColumnsAuthor {
  padding-top: 1rem;
  border-top: $border-black;
  width: 14rem;

  @include media('sm-up') {
    width: calc(50% - 3rem);
    margin-right: 0;
    padding-top: 1.5rem;
    flex: none;
  }

  @include media('md-up') {
    width: calc(33.3% - 3rem);
  }

  @include media('lg-up') {
    width: calc(25% - 3rem);
  }

  &__container {
    background-color: color('black');
    margin-right: 0.5rem;
    border-left: $border-black;
    height: 100%;
    width: 14rem;

    @include media('sm-up') {
      margin-right: 0;
      flex: none;
      width: 100%;
    }

    &__content {
      text-align: center;
      color: color('white');
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: 1rem;

      @include media('sm-up') {
        padding: 1.5rem;
      }
    }

    &__image {
      padding: 1rem;
      padding-bottom: 0.5rem;
      height: auto;

      @include media('sm-up') {
        padding: 1.5rem;
        padding-bottom: 0;
      }

      border-radius: 100%;
    }
  }
}
