@import 'views/ArchiveView';
@import 'views/ArticleView';
@import 'views/FeatureArticlePageView';
@import 'views/FictionArticleView';
@import 'views/HomeView';
@import 'views/SectionView';
@import 'views/RecipeView';
@import 'views/RecipesView';
@import 'views/PodcastView';
@import 'views/CollectionsView';
@import 'views/CollectionView';
@import 'views/ColumnsView.scss';
@import 'views/ColumnistsView.scss';

.view {
  animation: $transition-fade-in;
  animation-fill-mode: forwards;
  transition: opacity $transition;

  &.exit,
  &.exit-active {
    opacity: 0;
  }
}

.fiction-view {
  @include media('sm-down') {
    height: auto;
    margin-bottom: 1rem;
    // height: 100%;

    // &.split-layout {
    //   height: calc(100% - 1rem);
    // }
  }

  &--more-padded {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 0.9375rem;

    @include media('sm-up') {
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 0px;
    }
  }

  &--less-padded {
    padding-left: 16px;
    padding-right: 16px;
  }

  background-color: color('fiction-blue');
  color: color('fiction-pink');

  p,
  h2,
  a,
  span,
  button,
  div {
    color: color('fiction-pink');
    border-color: color('fiction-pink');
    text-decoration-color: color('fiction-pink');
  }

  .BlockContent a {
    color: color('fiction-pink');
  }

  .linked-border-bottom-lg-on-hover-red,
  .linked-border-bottom-md-on-hover-red,
  .linked-border-bottom-sm-on-hover-red {
    background-image: linear-gradient(
      color('fiction-pink'),
      color('fiction-pink')
    );
  }

  .AuthorButtons__button-text:hover {
    text-decoration-color: color('fiction-pink');
  }

  .fiction-teaser-img {
    margin-top: 24px;

    .color-img {
      opacity: 1;
    }

    img {
      filter: grayscale(1);

      &:hover {
        filter: none;
      }
    }
  }

  &.teaser-widget-container {
    @include media('sm-up') {
      border-bottom: color('fiction-blue');
      border-right: color('fiction-blue');
    }
  }
}
