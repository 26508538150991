.ImageLightboxStoryTeaserWidget {
  background-color: color('black');

  padding: 1rem 1rem 3rem 1rem;
  margin-top: 1rem;
  height: calc(100% - 1rem);

  @include media('sm-up') {
    padding: 1.5rem 1.5rem 4.5rem 1.5rem;
    margin-top: 0;
    height: 100%;
  }

  &__image {
    img {
      height: auto;
    }

    &__details {
      padding-top: 0.5rem;
      line-height: 1.25rem;

      @include media('sm-up') {
        line-height: 1.5rem;
      }
    }

    &__caption {
      font-size: 1rem;
      margin-right: 0.25rem;
      @include media('sm-up') {
        font-size: 1.125rem;
      }
    }

    &__credit {
      font-size: 0.875rem;
      letter-spacing: 0.5px;
      color: color('gray-darker');
    }
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: screen;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }
}
