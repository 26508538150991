.Recipe {
  line-height: 1.875rem;

  @media (min-width: 1080px) {
    padding-left: 0;
    padding-right: 0;
  }

  &--sidebar {
    border: $border-gray-darkest;

    @media (min-width: 768px) {
      border-bottom: $border-black;
      border-left: $border-black;
      border-right: none;
      border-top: none;
      width: 22.875rem;
      padding-top: 0;
      padding-right: 0;
      padding-left: 1.5rem;
      float: right;
      margin-left: 1.5rem;
    }

    @media (min-width: 1080px) {
      margin-right: 8.3333333333%;
      width: 25.5rem;
    }

    @media (min-width: 1440px) {
      width: 31.5rem;
    }

    &__title {
      padding: 1.1875rem 0 1.1875rem 0;
      margin-bottom: 1.1875rem;

      @media (min-width: 1080px) {
        padding: 0.75rem 0 1.875rem 0;
      }
    }

    &__directions {
      padding-bottom: 1.1875rem;

      @media (min-width: 375px) {
        padding-bottom: 1.75rem;
      }

      @media (min-width: 1080px) {
        padding-bottom: 1.5rem;
      }
    }

    &__notes,
    &__footnotes {
      p {
        margin-top: 1.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.25rem;
      }

      a,
      > p > em > a,
      > p > strong > a {
        color: color('gray-darker');
        text-decoration: none;
        background-image: linear-gradient(
          color('gray-darker'),
          color('gray-darker')
        );
        background-position: right 94%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        transition: background-size $transition-duration-short;

        &:hover {
          background-size: 0% 1px;
        }
      }
    }
  }

  &--full-width {
    border-top: $border-gray-darkest;
    border-bottom: $border-gray-darkest;

    @media (min-width: 1080px) {
      border-top: $border-black;
      border-bottom: $border-black;
      border-left: none;
      border-right: none;
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding: 0;
    }

    @media (min-width: 1440px) {
      margin-left: 8.3333333333%;
      margin-right: 8.3333333333%;
    }

    &__container {
      @media (min-width: 1080px) {
        flex-direction: row;
      }
    }

    &__ingredient-outer-container {
      @media (min-width: 1080px) {
        padding-right: 2rem;
        padding-left: 1.5rem;
        width: calc(100% / 12 * 4);
      }
    }

    &.Recipe__ingredient-title {
      @media (min-width: 1080px) {
        width: calc(100% / 12 * 10);
      }
    }

    &.Recipe__quantity {
      @media (min-width: 1080px) {
        justify-items: flex-end;
      }
    }

    &__title {
      padding: 1.1875rem 0 1.1875rem 0;
      margin-bottom: 1.1875rem;
    }

    &__directions {
      padding-bottom: 9.8125rem;

      @media (min-width: 1080px) {
        padding-left: 1rem;
        padding-top: 0rem;
        padding-bottom: 11.1875rem;
        width: calc(100% / 12 * 8);
      }
    }

    &__notes,
    &__footnotes {
      p {
        margin-top: 2rem;
        margin-bottom: 0;
      }

      a,
      > p > em > a,
      > p > strong > a {
        color: inherit;
        text-decoration: none;
        @extend %linked-border-bottom-black;
      }
    }
  }

  &__ingredient-outer-container {
    @media (max-width: 1079px) {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &__ingredient-title,
  &__direction-title {
    font-weight: 600 !important;
  }

  &__direction-title {
    margin-bottom: 0 !important;
  }

  &__directions {
    .Recipe__direction-container {
      ol > li:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__ingredient-container:first-of-type {
    margin-top: 0;
  }

  &__ingredient {
    margin-bottom: 0.3rem !important;
    font-size: 1.125rem;
    line-height: 1.875rem;

    @include media('md-up') {
      line-height: 1.5rem;
    }

    @media (min-width: 1500px) {
      line-height: 1.875rem;
    }

    @media (min-width: 1440px) {
      font-size: 1.25rem;
      line-height: 2.25rem;
    }
  }

  &__ingredient:first-of-type {
    margin-top: 0;
  }

  &__direction-container:first-of-type {
    margin-top: 0;
  }

  &__direction {
    &:first-of-type {
      margin-top: 0;
    }

    line-height: 1.875rem;
    list-style: decimal inside none;

    > li {
      transform: translateX(0rem);
      width: 100%;
      padding: 0;
    }
  }

  &__direction-text,
  &__footnotes,
  &__notes {
    font-family: $graebenbach !important;
    font-size: 1.125rem;
    line-height: 1.875rem;

    @media (min-width: 1440px) {
      font-size: 1.25rem;
    }
  }

  &__direction--single {
    > li:first-child {
      margin-top: 0;
    }
  }

  &__arrow-icon {
    transform: rotate(-45deg);
    font-size: 1.25rem;
  }

  &__print-button-container {
    transform: translateX(5rem);
  }

  &__print-button-container::after {
    display: none;
  }

  &__print-button:hover {
    .Recipe__arrow-icon {
      transform: rotate(0);
    }
  }

  &__share-container {
    padding-top: 1.4375rem;
    padding-bottom: 1.4375rem;
  }

  .ShareButton {
    transform: translate3d(-5rem, 0, 0);
  }

  .ShareButton--active {
    transform: translate3d(0, 0, 0);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 2rem;
      background: linear-gradient(
        90deg,
        color('off-white') 65%,
        rgba(247, 246, 242, 0.37) 90%,
        color('transparent') 100%
      );
      z-index: -1;
    }
  }
}
