.TeaserScroll {
  position: relative;

  &__container {
    scroll-behavior: smooth;
    overflow-x: scroll;
    margin-left: -$content-padding-x-sm;
    margin-right: -$content-padding-x-sm;
    width: calc(100% + (#{$content-padding-x-sm} * 2));
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    @include media('md-up') {
      margin-left: -$content-padding-x-md;
      margin-right: -$content-padding-x-md;
      width: calc(100% + (#{$content-padding-x-md} * 2));
    }
    @include media('lg-up') {
      margin-left: -$content-padding-x-lg;
      margin-right: -$content-padding-x-lg;
      width: calc(100% + (#{$content-padding-x-lg} * 2));
    }
  }

  &__carouselButton {
    display: none;
    position: absolute;
    z-index: 20;
    top: 0;
    height: 100%;
    width: 28px;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    border: 0;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s;
    background-color: transparent;

    img {
      width: 30px;
    }

    @include media('md-up') {
      display: flex;
      width: 4.4375rem;
      padding: 0 25px;
    }

    @include media('lg-up') {
      width: 5.25rem;
    }

    &--prev {
      right: 100%;
      align-items: flex-end;
      background-image: linear-gradient(
        to right,
        rgba(239, 233, 220, 1) 55%,
        rgba(239, 233, 220, 0.3) 80%,
        transparent
      );
    }
    &--next {
      left: 100%;
      background-image: linear-gradient(
        to left,
        rgba(239, 233, 220, 1) 55%,
        rgba(239, 233, 220, 0.3) 80%,
        transparent
      );
    }

    &--visible {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__innerPadding {
    width: 100%;
    padding: 15px $content-padding-x-sm;
    @include media('md-up') {
      padding: 15px $content-padding-x-md;
    }

    @include media('lg-up') {
      padding: 15px $content-padding-x-lg;
    }
  }

  &__inner {
    display: flex;
    width: 100%;
  }

  &__padding {
    padding-right: $content-padding-x-sm;
    @include media('md-up') {
      padding-right: $content-padding-x-md;
    }

    @include media('lg-up') {
      padding-right: $content-padding-x-lg;
    }
  }

  &__button {
    cursor: pointer;
    background: none;
    position: absolute;
    bottom: 100%;
    right: 0;
    font-size: 18px;
  }

  .RecipeTeaser {
    padding: 0 15px 15px 0;

    @include media('md-up') {
      padding: 0 25px 25px 0;
    }
  }

  .Teaser {
    min-width: 300px;
    max-width: 80vw;

    &:first-child {
      margin-left: -15px;
      @include media('md-up') {
        margin-left: 0;
        padding: 0;
      }
    }

    @include media('md-up') {
      margin-right: 25px;
      width: calc(25% - (25px * 0.75));
    }

    @include media('lg-up') {
      margin-right: 25px;
      width: calc(25% - (25px * 0.75));
      min-width: calc(25% - (25px * 0.75));
    }

    &:nth-last-child(2) {
      @include media('md-up') {
        margin-right: 0px;
      }
    }
  }

  .Teaser + .Teaser {
    @include dotted-border('left');
    @include media('md-up') {
      background-image: none;
    }

    &.FinalTeaser {
      background-size: 0;
    }
  }
}

.FinalTeaser {
  &__text {
    font-size: 18px;
  }
  &__button {
    background: none;
    cursor: pointer;
  }
}
