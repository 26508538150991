.content-margin-x {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .content-margin-x {
    margin-left: 4.4375rem;
    margin-right: 4.4375rem;
  }
}

@media (min-width: 1080px) {
  .content-margin-x {
    margin-left: 5.25rem;
    margin-right: 5.25rem;
  }
}

.collection-background-grey {
  background-color: var(--collection-background-grey);
}

.collection-background-grey-section {
  background-color: var(--collection-background-grey-section);
}

.collections-4-stories {
  padding: 1rem 1rem 2rem 1rem;

  @include media('sm-up') {
    padding: 1.5rem 1.5rem 3rem 1.5rem;
  }

  &__container {
    display: flex;
    flex-direction: column;

    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: auto;

    @include media('sm-up') {
      padding-top: 1.5rem;
      display: grid;
    }

    .teaser-widget-container {
      padding: 0;
      margin: 0;
      border: none;
    }

    @include media('sm-up') {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(3, auto);
    }

    @include media('md-up') {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(2, auto);
    }

    @include media('lg-up') {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      grid-template-rows: repeat(2, minmax(0, auto));
    }

    &__article1-container {
      grid-column: 1/2;
      grid-row: 3/4;
      border-bottom: $border-black-dotted;
      padding-top: 1rem;
      padding-bottom: 4rem;

      @include media('sm-up') {
        grid-column: 1/3;
        grid-row: 1/2;
        padding-bottom: 6rem;
        padding-top: 0;
      }

      @include media('md-up') {
        grid-column: 1/5;
        grid-row: 1/2;
      }

      @include media('lg-up') {
        grid-column: 3/7;
        grid-row: 1/3;
        border-bottom: none;
        padding-left: 0.75rem;
        border-left: $border-black-dotted;
      }
    }

    &__article2-container {
      padding-right: 0.75rem;
      padding-top: 1rem;
      border-top: $border-black-dotted;

      @include media('sm-up') {
        grid-column: 1/2;
        grid-row: 2/3;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
        border-bottom: $border-black-dotted;
        border-top: none;
        padding-right: 0;
        margin-right: 0.75rem;
      }

      @include media('md-up') {
        grid-column: 1/2;
        grid-row: 2/3;
        border-bottom: none;
      }

      @include media('lg-up') {
        grid-column: 1/2;
        grid-row: 1/2;
        padding-top: 0;
      }
    }

    &__article3-container {
      @include media('sm-up') {
        grid-column: 1/2;
        grid-row: 3/4;
        padding-top: 1.5rem;
        padding-right: 0.75rem;
        padding-bottom: 3rem;
      }

      @include media('md-up') {
        grid-column: 2/3;
        grid-row: 2/3;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin-top: 1.5rem;
        border-left: $border-black-dotted;
        padding-top: 0;
      }

      @include media('lg-up') {
        grid-column: 2/3;
        grid-row: 1/2;
        margin-top: 0;
      }
    }

    &__article4-container {
      grid-column: 1/2;
      grid-row: 2/3;
      padding-bottom: 3rem;
      border-top: $border-black-dotted;
      padding-top: 1rem;

      @include media('sm-up') {
        border-top: none;
        padding-top: 0;
        margin-top: 1.5rem;
        border-left: $border-black-dotted;
        padding-left: 0.75rem;
      }

      @include media('sm-up') {
        grid-column: 2/3;
        grid-row: 2/4;
      }

      @include media('md-up') {
        grid-column: 3/5;
        grid-row: 2/3;
        border-left: $border-black-dotted;
      }

      @include media('lg-up') {
        grid-column: 1/3;
        grid-row: 2/3;
        border-left: none;
        border-top: $border-black-dotted;
        padding-top: 1.5rem;
        padding-left: 0;
        margin-right: 0.75rem;
      }
    }

    &__article2-article3-container {
      grid-column: 1/2;
      grid-row: 1/2;

      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: auto;
      padding-bottom: 1rem;
      padding-top: 1rem;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0.9375rem;
        height: calc(100% - 1rem - 0.9375rem);
      }
      @include media('sm-up') {
        display: none;
      }
      .non-main-teaser {
        padding-bottom: 4rem;
      }
      .col1 {
        padding-right: 0.75rem;
        border-right: $border-black-dotted;
      }
      .col2 {
        padding-left: 0.75rem;
      }
    }
  }
}
