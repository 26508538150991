$team-member-container-max-width: 16rem;
$desktop-grid-column-gap: 3rem;
$mobile-grid-column-gap: 1rem;

.WhoWeAreBlock {
  &__team-members-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $mobile-grid-column-gap;
    grid-row-gap: 2rem;
    justify-items: center;

    @include media('sm-up') {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: $desktop-grid-column-gap;
      grid-row-gap: 4rem;
    }
  }

  &__image-background {
    padding-top: 100%;
    height: 0;
  }

  &__image {
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }

  &__image-colored {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &__button:hover {
    .WhoWeAreBlock__image-colored {
      opacity: 1;
    }
  }
}
