.PodcastListenButton::after {
  @extend %podcast-description-md;
  content: ',';
  position: absolute;
  right: -0.5rem;
  bottom: -0.3rem;
  color: color('white-dark');
  font-family: $graebenbach;
  font-weight: 300;
}

.PodcastListenButton:last-of-type {
  &::after {
    content: '';
  }
}
