.PodcastInfo {
  @include media('sm-up') {
    top: calc(#{$nav-desktop-height} + 1.5rem);
  }

  &__credit {
    letter-spacing: 0.0625rem;

    @include media('lg-up') {
      line-height: 1.5rem;
    }
  }

  &__subscribe {
    .Button {
      position: relative;
      margin-right: 0.5rem;
    }

    .Button:not(:last-of-type)::after {
      @extend %podcast-description-md;
      content: ',';
      position: absolute;
      right: 0;
      bottom: -0.3rem;
      color: white;
      font-family: $graebenbach;
      font-weight: 300;
    }
  }

  &__podcast-title {
    margin-top: 1.125rem;
  }

  &__title {
    margin-top: 3rem;

    @include media('md-up') {
      margin-top: 0;
    }
  }

  &__description {
    margin-top: 1.125rem;
  }

  &__credits {
    margin-top: 1rem;
  }

  &__title--style-reduced-padding {
    margin-top: 0.875rem;
  }

  &__img-desktop {
    @include media('md-up') {
      margin-top: 3rem;
    }
  }
}
