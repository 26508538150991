.ArticleGridItem {
  padding-bottom: 2.4375rem;

  &.fiction-view {
    border: none !important;
  }

  @include media('xs-down') {
    &.fiction-view {
      position: relative;
      overflow: visible;
      padding-bottom: 1rem;

      &.ItemGrid__item::after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: $border-black;
        bottom: -1rem;
        left: 0;
      }

      &.ArticleGridItem--split-layout {
        &::after {
          bottom: 0;
        }
        &:nth-child(odd)::after {
          content: unset;
        }

        margin-bottom: 0;
        background: transparent;

        padding-left: 0;
        padding-right: 0;

        > div {
          width: 100%;
          > div {
            padding-bottom: 33px;
            height: 100%;
            background: color('fiction-blue');
            padding-right: 1rem;
            padding-left: 1rem;
          }
        }
      }
    }
  }

  @include media('md-up') {
    padding-bottom: 2.5625rem;
  }

  &__section-button {
    padding-top: 1.25rem;

    @include media('sm-up') {
      padding-top: 1.625rem;
    }
  }

  &__title {
    word-break: break-word;
    padding-top: 1.25rem;
    font-size: 1.875rem;
    line-height: 2rem;

    @include media('sm-up') {
      font-size: 1.75rem;
      line-height: 1.875rem;
    }
  }

  &__dek {
    padding-top: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -0.005625rem;

    @include media('sm-up') {
      letter-spacing: 0;
    }

    @include media('lg-up') {
      font-size: 1.25rem;
    }
  }

  &__authors {
    padding-top: 1.375rem;

    @include media('sm-up') {
      padding-top: 0.8125rem;
    }
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }

  &--split-layout {
    .ArticleGridItem__section-button {
      padding-top: 1.25rem;
    }

    .ArticleGridItem__title {
      @media (max-width: 767px) {
        padding-top: 1.4375rem;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .ArticleGridItem__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .ArticleGridItem__authors {
      @media (max-width: 767px) {
        padding-top: 1.375rem;
        padding-bottom: 0.625rem;
      }
    }
  }
}
