.PodcastEpisodeNav {
  &__logo {
    animation: $transition-animate-disappear-to-top;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &__logo--active {
    animation: $transition-animate-appear-from-bottom;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &__podcast-episode-title {
    animation: $transition-animate-disappear-to-bottom;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &__podcast-episode-title--active {
    animation: $transition-animate-appear-from-top;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &__title {
    font-size: 1.25rem;

    @include media('md-up') {
      font-size: 1.5rem;
    }
  }

  &__share-button-container::before {
    content: '';
    position: absolute;
    height: 100%;
    right: 0;
    width: 150%;
    background: linear-gradient(
      90deg,
      rgba(34, 34, 34, 0.001) 0%,
      color('black') 30%,
      color('black') 100%
    );
    z-index: -1;
  }

  &__share-button::before {
    content: '';
    position: absolute;
    right: -100vw;
    width: 100vw;
    background-color: color('black');
    z-index: -1;
    height: calc(#{$nav-mobile-height} - 2px);

    @include media('md-up') {
      height: calc(#{$nav-desktop-height} - 2px);
    }
  }

  &__share-button::after {
    content: '';
    position: absolute;
    height: 100%;
    right: 0;
    width: 150%;
    background: linear-gradient(
      to right,
      rgba(34, 34, 34, 0.001) 0%,
      color('black') 30%,
      color('black') 100%
    );
    position: absolute;
    z-index: -1;
  }

  &__logo-icon {
    width: 7rem;

    @include media('md-up') {
      width: 9.875rem;
    }
  }
}
