.HolidaysGrid {
  @include media('lg-up') {
    > div:first-child > div,
    > div:nth-child(4n) > div {
      margin-left: 0;
    }

    > div:nth-child(3n) > div {
      margin-right: 0;
    }
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.25rem;

    @media (max-width: 767px) {
      > div:last-of-type {
        border-bottom: 0;
        padding-bottom: 2.75rem;
      }
    }

    @include media('md-up') {
      grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
      grid-auto-rows: auto;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
    }

    @include media('lg-up') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__container {
    @media (max-width: 767px) {
      border-right: 0;
    }
  }
}
