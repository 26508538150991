$border-transparent: 1px solid color('transparent');
$border-black: 1px solid color('black');
$border-gray: 1px solid color('gray');
$border-red: 1px solid color('red');
$border-gray-lighter: 1px solid color('gray-lighter');
$border-gray-darker: 1px solid color('gray-darker');
$border-gray-darkest: 1px solid color('gray-darkest');
$border-black-dotted: 1px dotted color('black');
$border-thick-red: 2px solid color('red');
$border-thick-black: 2px solid color('black');

.site-content-max-width {
  max-width: $site-content-max-width;
}

.inner-content-max-width {
  max-width: $inner-content-max-width;
}

.content-padding-x {
  padding-left: $content-padding-x-sm;
  padding-right: $content-padding-x-sm;

  @include media('sm-up') {
    padding-left: $content-padding-x-custom-sm;
    padding-right: $content-padding-x-custom-sm;
  }

  @include media('md-up') {
    padding-left: $content-padding-x-lg;
    padding-right: $content-padding-x-lg;
  }
}

.content-with-nav {
  padding-top: 2.25rem;

  @include media('md-up') {
    padding-top: 3rem;
  }
}

.content-with-section-nav {
  padding-top: $nav-section-mobile-height;

  @include media('md-up') {
    padding-top: $nav-section-desktop-height;
  }
}

.content-height {
  min-height: 100vh;
}

.content-height-with-footer {
  min-height: calc(100vh - #{$footer-mobile-height});

  @include media('md-up') {
    min-height: calc(100vh - #{$footer-desktop-height});
  }
}

.article-content-container {
  margin-top: 0.4375rem;

  @include media('sm-up') {
    margin-top: 0.8125rem;
  }

  @include media('md-up') {
    margin-top: 4.4375rem;
  }

  @include media('lg-up') {
    margin-top: 4.1875rem;
  }

  @include media('xl-up') {
    margin-top: 3.5rem;
  }
}

.article-content-padding-x {
  @include media('sm-up') {
    padding-left: 4.875rem;
    padding-right: 4.875rem;
  }

  @include media('lg-up') {
    padding-left: 6.75rem;
    padding-right: 6.75rem;
  }

  @include media('xl-up') {
    padding-left: 8.25rem;
    padding-right: 8.25rem;
  }
}

.article-content-margin-x {
  @include media('sm-up') {
    margin-left: 4.875rem;
    margin-right: 4.875rem;
  }

  @include media('lg-up') {
    margin-left: 6.75rem;
    margin-right: 6.75rem;
  }

  @include media('xl-up') {
    margin-left: 8.25rem;
    margin-right: 8.25rem;
  }
}

.bg-color-collections {
  background-color: color('collection-background-grey');
}

.podcast-title-container {
  @include media('md-up') {
    max-width: $podcast-title-width;
    padding: 0 1.5rem 2.5rem 0;
    margin-right: 1.5rem;
    border-bottom: $border-gray-darker;
    border-right: $border-gray-darker;
  }
}

.linked-border-bottom-red,
%linked-border-bottom-red {
  background-image: linear-gradient(color('red'), color('red'));
  background-position: right 95%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 0% 1px;
  }
}

.linked-border-bottom-black,
%linked-border-bottom-black {
  background-image: linear-gradient(color('black'), color('black'));
  background-position: right 92%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 0% 1px;
  }
}

.linked-border-bottom-on-hover-black {
  background-image: linear-gradient(color('black'), color('black'));
  background-position: left 94%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 100% 1px;
  }
}

.linked-border-bottom-white,
%linked-border-bottom-white {
  background-image: linear-gradient(color('white'), color('white'));
  background-position: right 95%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 0% 1px;
  }
}

.linked-border-bottom-md-white-dark,
.linked-border-bottom-sm-white-dark,
%linked-border-bottom-sm-white-dark {
  background-image: linear-gradient(color('white-dark'), color('white-dark'));
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 0% 1px;
  }
}

.linked-border-bottom-md-white-dark {
  background-position: right 94%;

  @include media('xl-up') {
    background-position: right 92%;
  }
}

.linked-border-bottom-sm-white-dark,
%linked-border-bottom-sm-white-dark {
  background-position: right 98%;
}

.linked-border-bottom-on-hover {
  background-position: left 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 100% 1px;
  }
}

.linked-border-bottom-on-hover-white-dark {
  background-image: linear-gradient(color('white-dark'), color('white-dark'));
  background-position: left 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 100% 1px;
  }
}

.linked-border-bottom-lg-on-hover-red,
.linked-border-bottom-md-on-hover-red,
.linked-border-bottom-sm-on-hover-red {
  background-image: linear-gradient(color('red'), color('red'));
  background-repeat: no-repeat;
  background-size: 0% 1.75px;
  transition: background-size $transition-duration-short;

  &:hover {
    background-size: 100% 1.75px;
  }
}

.linked-border-bottom-lg-on-hover-red {
  background-position: left 89%;
}

.linked-border-bottom-md-on-hover-red {
  background-position: left 95%;
}

.linked-border-bottom-sm-on-hover-red {
  background-position: left 100%;
}

.dotted-border-red,
%dotted-border-red {
  background-image: linear-gradient(
    to right,
    color('red') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.dotted-border-black,
%dotted-border-black {
  &.fiction-teaser-border {
    background-image: linear-gradient(
      to right,
      color('fiction-pink') 24%,
      rgba(255, 255, 255, 0) 0%
    );
  }

  background-image: linear-gradient(
    to right,
    color('black') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.dotted-border-right-black,
%dotted-border-right-black {
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: 1px 3px;
  background-repeat: repeat-y;
}

.dotted-border-left-black,
%dotted-border-left-black {
  background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
  background-position: left;
  background-size: 1px 3px;
  background-repeat: repeat-y;
}

.dotted-border-bottom-black,
%dotted-border-bottom-black {
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

.dotted-border-top-black,
%dotted-border-top-black {
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}

// Dotted border mixin, accepts:
// position: top, right, left, or bottom
// color: string, can use color() mixin
// content: optional content to append to property
@mixin dotted-border($position: top, $color: black, $content: null) {
  background-position: $position $content;

  @if $position == 'bottom' or 'top' {
    background-repeat: repeat-x $content;
    background-size: 4px 1px $content;
    background-image: linear-gradient(to right, $color 24%, transparent 0%)
      $content;
  }
  @if $position == 'right' or 'left' {
    background-repeat: repeat-y $content;
    background-size: 1px 3px $content;
    background-image: linear-gradient(to bottom, $color 33%, transparent 0%)
      $content;
  }
}

.border-right-black {
  border-right: $border-black;
}

.border-bottom-black {
  border-bottom: $border-black;
}

.border-bottom-red {
  border-bottom: $border-red;
}

.border-top-black {
  border-top: $border-black;
}

.border-right-black {
  border-right: $border-black;
}

.border-bottom-gray-darker {
  border-bottom: $border-gray-darker;
}

.border-right-gray-darker {
  border-right: $border-gray-darker;
}

.border-bottom-gray-lighter {
  border-bottom: $border-gray-lighter;
}

.border-right-gray-lighter {
  border-right: $border-gray-lighter;
}

.border-bottom-transparent {
  border-bottom: $border-transparent;
}

.border-bottom-dotted-black {
  background-image: linear-gradient(
    to right,
    color('black') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.border-top-dotted-black {
  background-image: linear-gradient(
    to right,
    color('black') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.border-thick-red,
%border-thick-red {
  border: $border-thick-red;
}

.border-thick-black,
%border-thick-black {
  border: $border-thick-black;
}

.dotted-border-red,
%dotted-border-red {
  background-image: linear-gradient(
    to right,
    color('red') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.dotted-border-beige,
%dotted-border-beige {
  background-image: linear-gradient(
    to right,
    color('beige') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.dotted-border-off-white,
%dotted-border-off-white {
  background-image: linear-gradient(
    to right,
    color('off-white') 24%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;
}

.margin-text-legacy-article-header-md,
.margin-text-legacy-article-header-sm {
  margin-top: 4.5rem;
  margin-bottom: 1.25rem;
}

.margin-text-legacy-article-header-md {
  @include media('md-up') {
    margin-top: 6rem;
    margin-bottom: 1.75rem;
    margin-left: $article-reference-text-spacing-md;
  }

  @include media('lg-up') {
    margin-left: $article-reference-text-spacing-lg;
  }

  @include media('xl-up') {
    margin-left: $article-reference-text-spacing-xl;
  }
}

.margin-text-legacy-article-header-sm {
  @include media('md-up') {
    margin-top: 5rem;
    margin-bottom: 1.75rem;
    margin-left: $article-reference-text-spacing-md;
  }

  @include media('lg-up') {
    margin-left: $article-reference-text-spacing-lg;
  }

  @include media('xl-up') {
    margin-left: $article-reference-text-spacing-xl;
  }
}

.widget-container-red {
  @extend %border-thick-red;
  padding: 1.1875rem 1.5rem;

  .img-container {
    background-color: rgba(221, 52, 24, 0.5);
    mix-blend-mode: multiply;
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: screen;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }

  @media (max-width: 374px) {
    margin: 2.5rem 0.9375rem;
  }
}

.gray-img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  mix-blend-mode: multiply;
}

.widget-container-black {
  @extend %border-thick-black;
  padding: 1.1875rem 1.5rem;

  .img-container {
    background-color: rgba(221, 52, 24, 0.5);
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }

  @media (max-width: 374px) {
    margin: 2.5rem 0.9375rem;
  }
}

.move-image-right-on-hover {
  & img {
    @extend %transition-short;
  }

  .button-move-arrow {
    @extend %transition-short;
  }

  &:hover {
    & img {
      transform: translateX(0.25rem);
    }

    .button-move-arrow {
      transform: translateX(0.25rem);
    }
  }
}

.move-image-down-on-hover {
  & > img {
    @extend %transition-short;
  }

  .button-move-arrow {
    @extend %transition-short;
  }

  &:hover {
    & > img {
      transform: translateY(0.25rem);
    }

    .button-move-arrow {
      transform: translateY(0.25rem);
    }
  }
}

.move-image-up-on-hover {
  & > img {
    @extend %transition-short;
  }

  .button-move-arrow {
    @extend %transition-short;
  }

  &:hover {
    & > img {
      transform: translateY(-0.25rem);
    }

    .button-move-arrow {
      transform: translateY(-0.25rem);
    }
  }
}

.layout-sticky-widget {
  @include media('sm-up') {
    top: calc(#{$nav-desktop-height} + 1.5rem);
    position: sticky;
  }
}

.layout-widgets-container {
  > div {
    margin-top: 1.375rem;

    @media (max-width: 374px) {
      margin-top: 1.875rem;
    }
  }

  > div:first-child {
    margin-top: 1.5rem;

    @media (max-width: 374px) {
      margin-top: 1.875rem;
    }
  }
}

.sectionNavPadding {
  padding: 0 0.9375rem;

  @include media('md-up') {
    padding: 0 1.5625rem;
  }
}

.teaser-widget-container {
  width: 100%;
  height: 100%;

  @include media('sm-up') {
    border-bottom: $border-black;
    border-right: $border-black;
    padding: 0 1.5rem 3rem 0;
  }

  .grayscale-img {
    filter: grayscale(1);
    mix-blend-mode: multiply;
  }

  .color-img {
    opacity: 0;
    transition: $transition-duration-medium $transition-easing;
  }

  &:hover {
    .color-img {
      opacity: 1;
    }
  }
}

.layout-widget-container {
  padding: $content-padding-x-sm 0 2.5rem;

  @include media('sm-up') {
    padding: 0 1.5rem 3rem 0;
  }

  @media (max-width: 374px) {
    border-bottom: $border-black;
  }
}

.layout-story-container {
  @media (max-width: 374px) {
    border-bottom: $border-black;
  }
}

.safe-visibility-hidden {
  position: absolute;
  text-indent: -10000px;
  white-space: nowrap;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.global-header-spacing {
  margin-top: 3.5rem;

  @include media('md-up') {
    margin-top: 5.75rem;
  }

  @include media('lg-up') {
    margin-top: 6.25rem;
  }
}

.block-important {
  display: block !important;
}

//twitter embed style
twitter-widget.twitter-tweet {
  max-width: calc(100vw - 2rem) !important;
}

blockquote.twitter-tweet {
  display: inline-block;
  font-family: 'Helvetica Neue', Roboto, 'Segoe UI', Calibri, sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  font-style: normal;
  border-color: #eee #ddd #bbb;
  background-color: color('white');
  color: color('black');
  border-radius: 0.3125rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 1.5rem 0.3125rem;
  padding: 1rem;
  max-width: 29.25rem;
  transform: translateX(0);
}

blockquote.twitter-tweet p {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25rem;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
  background-image: none;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid currentColor;
  margin: 20px 0;
}
