@import 'layouts/LayoutA';
@import 'layouts/LayoutB';
@import 'layouts/LayoutC';
@import 'layouts/LayoutD';
@import 'layouts/LayoutE';
@import 'layouts/LayoutF';
@import 'layouts/LayoutG';
@import 'layouts/LayoutH';
@import 'layouts/LayoutI';
@import 'layouts/LayoutJ';
@import 'layouts/LayoutK';
@import 'layouts/LayoutL';
@import 'layouts/LayoutM';
@import 'layouts/LayoutN';
@import 'layouts/LayoutO';
@import 'layouts/LayoutHero1';
@import 'layouts/LayoutHero2';
@import 'layouts/LayoutHero3';
@import 'layouts/LayoutHero4';
@import 'layouts/LayoutHolidayHero';
@import 'layouts/SectionNavA';
@import 'layouts/SectionNavB';
@import 'layouts/SectionNavC';
@import 'layouts/SectionNavHoliday';
@import 'layouts/collections/Collections4Stories';
@import 'layouts/collections/Collections6Stories';
@import 'layouts/collections/Collections8Stories';
@import 'layouts/collections/Collections10Stories';
@import 'layouts/collections/Collections4StoriesWidget';
@import 'layouts/collections/Collections6StoriesWidget';
@import 'layouts/collections/Collections8StoriesWidget';
@import 'layouts/collections/Collections10StoriesWidget';

.LayoutModuleSwitch > {
  .collections-container:first-child,
  .collections-widget-container:first-child,
  .collections-4-stories:first-child,
  .collections-10-stories:first-child {
    margin-top: 1rem;

    @include media('sm-up') {
      margin-top: 1.5rem;
    }
  }
}
