.Ad {
  &--size-300x300 {
    .Ad__container__target {
      width: 300px;
      height: 300px;
    }

    @include media('md-up') {
      width: 400px;
      float: right;
      margin-right: calc(100% / 12);

      .Ad__container {
        align-items: flex-end;
      }
    }
  }

  &--size-300x600 {
    .Ad__container__target {
      width: 300px;
      height: 600px;
    }

    @include media('md-up') {
      width: 400px;
      float: right;
      margin-right: calc(100% / 12);

      .Ad__container {
        align-items: flex-end;
      }
    }
  }

  &--size-320x50 {
    .Ad__container__target {
      width: 320px;
      height: 50px;
    }
  }

  &--size-728x90 {
    .Ad__container__target {
      width: 728px;
      height: 90px;
    }
  }

  &--size-970x250 {
    .Ad__container__target {
      width: 970px;
      height: 250px;
    }
  }

  &--size-970x90 {
    .Ad__container__target {
      width: 970px;
      height: 90px;
    }
  }

  &__container {
    &__ad {
      padding: 0.75rem;
      border: 1px solid color('gray');
    }
  }
}
