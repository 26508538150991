.PhotoSlideshowWidget {
  padding: 1.1875rem 0;

  &__header,
  &__slide {
    padding: 0 1.5rem;
  }

  &__slideshow-image {
    max-height: 18rem;
    max-width: 100%;
  }

  &__image-credit-link {
    margin-bottom: 1.125rem;
    text-align: right;
  }

  &__caption {
    margin-top: 1.5625rem;
  }

  .ImgCarousel:focus {
    outline: none;
  }

  .flickity-enabled:focus {
    outline: none;
  }
}
