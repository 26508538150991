.App__content-container--columnists {
  .content-padding-x {
    @include media('sm-up') {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @include media('md-up') {
      padding-left: 5.25rem;
      padding-right: 5.25rem;
    }
  }
}

.ColumnistsGridItems {
  gap: 1.5rem;
  padding-bottom: 6rem;

  .RecentArticlesBySection {
    &__article {
      padding-top: 1rem;
      background-image: none;

      @include media('sm-up') {
        padding-top: 0;
      }

      &__title {
        font-size: 1.75rem;
        line-height: 2rem;
      }
    }
  }

  .RecentArticlesBySection {
    &__article {
      &__authors {
        margin-top: 0.25rem;
      }
    }
  }

  .teaser-widget-container {
    border-right: none;
    border-bottom: none;
    padding: 0 1.5rem 0 0;

    background-image: linear-gradient(
      to right,
      color('black') 24%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    @include media('sm-up') {
      padding: 0 1.5rem 1rem 0;
    }
  }

  .last-item {
    @include media('xs-down') {
      border: none;
    }
  }

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
  }

  @include media('md-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__title {
    font-family: 'Graebenbach', serif;
    color: color('black');
    font-size: 1rem;
    margin-bottom: 0.5rem;

    &__link {
      text-decoration: none;
    }
  }

  &__container {
    padding-bottom: 2rem;
    display: flex;
    gap: 20px;

    @include media('sm-up') {
      padding-bottom: 0rem;
    }
  }

  &__image {
    width: 80px;
    height: 80px;
    min-width: 80px;
  }

  &__image img {
    margin-top: 0.9375rem;
    vertical-align: middle;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover; /* Ensures the image scales appropriately */

    @include media('sm-up') {
      margin-top: 0;
    }
  }

  &__title-button {
    padding-top: 1rem;

    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.25rem;
    letter-spacing: -0.02em;
    display: flex;
    flex-direction: column;

    @include media('lg-up') {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  &__description {
    padding-top: 1rem;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      letter-spacing: 0;
    }
  }

  &__author-and-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    &__date {
      font-size: 0.875rem;
      line-height: 1.5rem;
      letter-spacing: 0.0625rem;
      margin-top: 0.25rem;
    }
  }
}
