.ColumnsCarousel {
  .slick-slider {
    height: 100%;
  }

  .slick-list {
    height: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    @include media('sm-up') {
      overflow-x: visible;
    }

    .ColumnsAuthor {
      width: 100%;
      height: 100%;
      padding-right: 0.5rem;

      @include media('sm-up') {
        width: 100%;
      }
    }

    .ColumnsAuthor__container {
      width: 100%;
      position: relative;
      border-right: $border-black;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -0.5rem;
        border-right: $border-black;
        height: 100%;
        display: flex;
      }

      @include media('sm-up') {
        width: 100%;
        border-right: none;
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  .slick-track {
    height: 696px;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-prev,
  .slick-next {
    display: none;

    &::before {
      content: '';
    }

    @include media('sm-up') {
      display: block;
      content: '';
      width: 60px;
      height: 60px;
      z-index: 5;
      //this removes the slick default
      transform: none;
      transition: transform 0.3s ease-in-out;
    }
  }

  .slick-prev {
    @include media('sm-up') {
      background: url('/assets/icons/red-arrow-left.svg');

      &:hover {
        transform: translateX(-10px);
      }
    }
  }

  .slick-next {
    @include media('sm-up') {
      background: url('/assets/icons/red-arrow-right.svg');

      &:hover {
        transform: translateX(10px);
      }
    }
  }

  .slick-disabled {
    display: none !important;
  }

  &__embed {
    padding-bottom: 4rem;

    @include media('sm-up') {
      padding-bottom: 5rem;
    }
  }

  &__underline {
    height: 1.5rem;
    width: 100%;
    background-color: color('black');
    margin-top: 2.5rem;

    @include media('sm-up') {
      margin-top: 4.5rem;
    }

    @include media('md-up') {
      margin-top: 5rem;
    }
  }

  .ImageTextStoryTeaserWidget--small {
    @include media('xs-down') {
      padding-top: 0;
    }
  }

  &__teaser {
    width: 14rem;
    border-top: $border-black;
    flex: none;
    padding-top: 1rem;
    height: 100%;

    .teaser-widget-container {
      border-right: $border-black;
      padding: 0 0.5rem 0.5rem 0.5rem;
    }

    @include media('sm-up') {
      border-left: none;
      padding-left: 1.5rem;
      padding-right: unset;
      padding-top: 1.5rem;
      width: calc(50% - 1rem);

      .teaser-widget-container {
        padding: 0 1.5rem 1.5rem 0;
      }
    }

    @include media('md-up') {
      width: calc(33.3% - 1rem);
    }

    @include media('lg-up') {
      width: calc(25% - 1rem);
    }
  }

  &__article {
    &__title {
      height: 24px;

      font-family: 'Graebenbach';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      text-align: center;

      /* Tablet/Tablet White */

      color: #f7f6f2;

      /* Inside auto layout */

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    &__container {
      .Button {
        color: color('white');
      }

      margin: 0 0 1rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      padding-top: 24px;
      gap: 16px;

      width: 100%;

      /* Tablet/Tablet Black */

      background: #222222;

      /* Inside auto layout */

      flex: none;
      order: 1;
      flex-grow: 0;

      @include media('md-up') {
        margin: 0 0 1.5rem 0;
        padding: 24px;
      }

      @include media('lg-up') {
        margin: 0.375rem 0 1.5rem 0;
        padding: 24px;
      }
    }

    &__vertical {
      margin-bottom: 0px;
      margin-top: 2rem;

      @include media('md-up') {
        margin-bottom: 0.75rem;
        margin-top: 3rem;
      }

      @include media('xl-up') {
        margin-top: 4rem;
      }
    }

    &__description {
      font-family: 'Graebenbach';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      /* or 133% */

      text-align: center;

      /* Tablet/Tablet White */

      color: #f7f6f2;

      a {
        margin-left: 10px;
      }
    }
  }

  &__floating-left-arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 20px;
    border-style: solid;
    border-width: 2.25px 2.25px 0 0;
    border-color: color('white');

    transform: rotate(225deg);

    > div {
      position: absolute;
      width: 140%;
      transform: rotate(-45deg);
      top: 6.7px;
      left: -2px;

      border-style: solid;
      border-width: 0 0 2.25px 0;
      border-color: color('white');
    }

    &__container {
      display: none;

      @include media('sm-up') {
        display: block;
      }

      position: absolute;
      z-index: 10;
      top: calc(50% - 6rem);
      position: absolute;
      width: 60px;
      height: 60px;
      left: -30px;

      /* Tablet/Red */

      background: color('red');
      border-radius: 30px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: translateX(-10px);
      }
    }
  }

  &__floating-right-arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 20px;
    top: 20px;
    border-style: solid;
    border-width: 2.25px 2.25px 0 0;
    border-color: color('white');

    transform: rotate(45deg);

    > div {
      position: absolute;
      width: 140%;
      transform: rotate(-45deg);
      top: 6.7px;
      left: -2px;

      border-style: solid;
      border-width: 0 0 2.25px 0;
      border-color: color('white');
    }

    &__container {
      display: none;

      @include media('sm-up') {
        display: block;
      }

      position: absolute;
      z-index: 10;
      top: calc(50% - 6rem);
      position: absolute;
      width: 60px;
      height: 60px;
      right: -14px;

      /* Tablet/Red */

      background: color('red');
      border-radius: 30px;
      transition: transform 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: translateX(10px);
      }

      @include media('xl-up') {
        right: -30px;
      }
    }
  }

  &__content {
    display: flex;
    position: relative;
    overflow-x: visible;
    transition: all 0.3s ease-in-out;

    &__container {
      position: relative;
      height: 100%;
    }
  }

  &__name {
    font-size: 1.875rem;
    line-height: 1.875rem;
    padding-bottom: 0.5rem;

    @include media('sm-up') {
      padding-bottom: 0;
    }

    @include media('md-up') {
      padding-bottom: 1.5rem;
    }
  }
  &__more {
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding-bottom: 1.375rem;
    margin-bottom: 0 !important;
    color: color('black');

    @include media('sm-up') {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }
  &__header {
    h1 {
      font-weight: bold !important;
      margin-bottom: 0;
    }

    @include media('sm-up') {
      display: flex;
      justify-content: space-between;
    }
  }
}
