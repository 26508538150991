.BlockRichText {
  max-width: $inner-content-max-width;

  &--sans-serif {
    @media (max-width: 767px) {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }

  &--serif {
    font-size: 1.875rem;
    line-height: 2.25rem;

    @include media('lg') {
      font-size: 2.25rem;
      line-height: 3rem;
    }
  }

  p {
    padding-bottom: 1rem;
  }

  a {
    color: color('black');
    @extend %linked-border-bottom-black;
    text-decoration: none;
  }

  ul {
    margin-left: $list-spacing-desktop;
  }
}
