.DonationWidget {
  &__info-container {
    @include media('sm-up') {
      max-width: 37.5rem;
    }

    @include media('md') {
      max-width: 45.75rem;
    }

    @include media('xl-up') {
      max-width: 55.875rem;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 2.25rem;
    margin-top: 2.375rem;

    @include media('md-up') {
      line-height: 3rem;
      margin-top: 2.3125rem;
    }

    @include media('lg') {
      font-size: 2.25rem;
      margin-top: 3rem;
    }
  }

  &__description {
    margin: 1.25rem 0;
    text-align: center;
  }

  &__support-button {
    font-size: 1rem;
    line-height: 1.3125rem;
    padding: 1.1875rem 1.9375rem;

    @include media('md-up') {
      padding: 1.1875rem 2.1875rem;
    }

    @include media('lg') {
      font-size: 1.125rem;
      line-height: 1.4375rem;
      padding: 1.0625rem 2.5625rem;
    }

    &:hover {
      .DonationWidget__support-button-arrow {
        transform: translateX(0.25rem);
      }
    }
  }
}
