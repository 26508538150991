.ImgCarousel {
  position: relative;

  &--multi-image {
    .flickity-prev-next-button {
      &.previous {
        left: 0;
        cursor: url('/assets/images/carousel-left-arrow.svg'), w-resize;
      }

      &.next {
        right: 0;
        cursor: url('/assets/images/carousel-right-arrow.svg'), e-resize;
      }
    }
  }

  .flickity-page-dots {
    display: flex;
    justify-content: center;
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1.5rem 2px 0;
    background: color('transparent');
    border-radius: 50%;
    cursor: pointer;
    border: $border-red;
  }

  .dot.is-selected {
    background: color('red');
  }

  .flickity-prev-next-button {
    position: absolute;
    width: 10%;
    height: 100%;
    top: 0;
    opacity: 0;
  }
}
