.EncyclopediaGridNav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;

  @include media('xs-up') {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }

  @include media('md-up') {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5;
    grid-row-gap: 0.5rem;
  }

  &__content {
    word-break: break-all;
  }

  &__letter {
    &:hover {
      background-color: color('gray-lightest-opacity-50');
      border-radius: radius('xs');
    }
  }
}
