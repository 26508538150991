.SectionNavHeader {
  padding-top: 4.1875rem;

  @include media('sm-up') {
    padding-top: 6rem;
  }

  @include media('md-up') {
    padding-top: 6.75rem;
  }

  &__container {
    transition: 0.35s $transition-easing;
  }

  &__section-logo {
    height: 20px;
    transform: translateX(0);

    @media (min-width: 768px) {
      height: 24px;
    }

    @media (min-width: 1440px) {
      height: 30px;
    }

    @media (min-width: 1440px) {
      height: 36px;
    }

    @media (min-width: 1660px) {
      height: 30px;
    }

    @media (min-width: 1920px) {
      height: 36px;
    }
  }

  &__arrow {
    font-size: 2.25rem;
    line-height: 1rem;
    transform: translateX(0);
    transition: 0.35s $transition-easing;
  }
}
