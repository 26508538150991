.ArticleEditorsNote {
  margin-bottom: 1.75rem;
  padding-top: 0.45rem;

  &__content {
    &.clamp {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      margin-bottom: 0;
    }

    a {
      @extend %linked-border-bottom-black;
      color: color('black');
      text-decoration: none;
    }

    @include media('md-up') {
      padding-top: 1.45rem;
    }

    @include media('lg-up') {
      padding-top: 3rem;

      + .article-content-container {
        margin-top: 2.75rem;
      }
    }

    @include media('xl-up') {
      padding-top: 4rem;

      + .article-content-container {
        margin-top: 4rem;
      }
    }
  }

  &__read-more {
    display: inline;
    background-color: transparent;

    .underline {
      text-decoration: underline;
    }
  }

  // constrict width of floated notes to half
  &--half-width {
    .ArticleEditorsNote__content.clamp {
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-wrap: break-word;
      margin-bottom: 0;
    }

    @include media('lg-up') {
      padding-top: 0;

      + .article-content-container {
        margin-top: 2rem;
      }
    }

    @include media('xl-up') {
      + .article-content-container {
        margin-top: 3rem;
      }
    }
  }
}
