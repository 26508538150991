.ContributorsLetterResult {
  &__title {
    padding-bottom: 3rem;

    @include media('md-up') {
      padding-bottom: 1.375rem;
    }

    @include media('lg') {
      padding-bottom: 1.625rem;
    }
  }

  &__letter {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 0 0.15rem;
  }

  &__letters {
    @include media('md-up') {
      padding-top: 3.375rem;
    }

    @include media('lg-up') {
      padding-top: 4.875rem;
    }
  }
}
