.CollectionView {
  padding-top: calc(#{$nav-mobile-height} + 1.5rem);

  @include media('md-up') {
    padding-top: calc(#{$nav-desktop-height} + 1.5rem);
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    padding-bottom: 0.5rem;
    padding-top: 3.75rem;
    width: 100%;
  }
}
