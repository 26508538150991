.ProgressBar {
  height: 0.25rem;

  &--side-nav-is-active {
    margin-left: $side-nav-mobile-width;

    @include media('md-up') {
      margin-left: $side-nav-desktop-width;
    }
  }
}
