.ColumnistsPageHeader {
  padding-bottom: 1.5rem;
  margin-bottom: 0rem;
  background-color: color('collection-background-grey');
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  h1 {
    color: color('black');
    font-weight: 400;
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 0.5rem;

    @include media('sm-up') {
      padding-bottom: 1rem;
    }

    @include media('lg-up') {
      font-size: 3.75rem;
      line-height: 3.75rem;
    }
  }

  &__description {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      width: 50%;
      margin: 0 auto;
    }

    @include media('lg-up') {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  @include media('sm-up') {
    margin-bottom: 1.5rem;
  }
}
