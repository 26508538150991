.QuoteStoryTeaserWidget {
  padding-bottom: 1.5rem;

  @include media('sm-up') {
    padding-bottom: 1.875rem;
  }

  @include media('lg-up') {
    padding-bottom: 2rem;
  }

  @include media('xl-up') {
    padding-bottom: 2.625rem;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.75rem;
    padding-top: 1.375rem;

    @include media('sm-up') {
      padding-top: 0.8125rem;
    }

    @include media('lg-up') {
      font-size: 1.875rem;
      line-height: 2rem;
      padding-top: 0.5625rem;
    }

    @include media('xl-up') {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }

    &::before {
      content: '“';
    }

    &::after {
      content: '”';
    }
  }

  &__attribution {
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      font-size: 1.125rem;
    }

    @include media('lg-up') {
      font-size: 1.25rem;
    }
  }

  &--split-layout {
    @media (max-width: 767px) {
      padding-bottom: 0.625rem;
    }

    .QuoteStoryTeaserWidget__text {
      @media (max-width: 767px) {
        padding-top: 1.4375rem;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .QuoteStoryTeaserWidget__attribution {
      @media (max-width: 767px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }
}
