/**
  * ArticleView-specific grid overrides
  */
.ArticleView {
  // overflow: hidden;

  &__edit-button {
    position: relative;
    z-index: 2000;
  }
  @media print {
    // .RelatedArticles,
    .InsetImgSlideshow,
    .InsetImg,
    .Hero__image {
      display: none;
    }
  }
  &__content {
    .BlockContent a {
      @extend %linked-border-bottom-black;
      color: var('black');
      text-decoration: none;

      @media print {
        position: relative;
        background-image: none;

        &:after {
          content: '';
          position: absolute;
          top: calc(100% - 0.13em);
          width: 100%;
          left: 0;
          border-bottom: 1px solid;
        }
      }
    }

    > div > .lg\:col-10 {
      width: 100% !important;

      @media (min-width: 768px) {
        width: calc(100% / 12 * 10) !important;
      }

      @media (min-width: 1080px) {
        width: calc(100% / 12 * 8) !important;
      }

      @media (min-width: 1440px) {
        &.BlockContent p {
          font-size: 1.75rem !important;
          line-height: 1.5;
        }
      }
    }
  }

  &__share {
    align-items: center;
    display: flex;
    height: 9rem;
    justify-content: center;

    @media print {
      display: none;
    }

    // overrides default ShareButton component
    .ShareButton {
      &__button {
        .ShareButton__text {
          font-family: $itc-cushing;

          @include media('md-up') {
            font-size: 2.25rem;
          }
        }

        &--active {
          padding-right: 21rem;

          @include media('md-up') {
            padding-right: 33rem;
          }
        }
      }

      &__text,
      &__arrow-icon {
        @include media('md-up') {
          font-size: 1.875rem;
          line-height: 1.5rem;
        }
      }

      &__buttons {
        transform: translate3d(2.5rem, 0, 0);

        @include media('md-up') {
          transform: translate3d(4rem, 0, 0);
        }
      }
    }
  }

  // Graphic Story article type overrides
  &--graphic-story {
    // Tighter article content container spacing
    .article-content-container {
      margin-top: 0.75rem;

      @include media('lg-up') {
        margin-top: 1.5rem;
      }
    }

    // Reset default InsetImage padding/margin
    .InsetImg--hide-rules {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    // A text block after an inset image
    .InsetImg--hide-rules + .BlockContent {
      border-top: $border-black;
      margin-top: 3.75rem;
      padding-top: 0.45rem;

      @include media('sm-up') {
        margin-top: 4.25rem;
        padding-top: 0.75rem;
      }

      @include media('md-up') {
        margin-top: 5.75rem;
        padding-top: 1.25rem;
      }

      @include media('xl-up') {
        padding-top: 2.5rem;
      }
    }

    // An inset image after a text block
    .BlockContent + .InsetImg--hide-rules {
      margin-top: 3.25rem;

      @include media('md-up') {
        margin-top: 4.65rem;
      }

      @include media('xl-up') {
        margin-top: 5.25rem;
      }
    }

    // First text block (adjusts relationship to hero container)
    .BlockContent:first-of-type {
      padding-top: 1.25rem;
    }

    // Adjust Author Bio block's distance from last story block
    .AuthorBioBlock {
      margin-top: 3.75rem;

      @include media('md-up') {
        margin-top: 3.5rem;
      }

      @include media('lg-up') {
        margin-top: 6rem;
      }
    }

    // Increase spacing above caption containers
    .InsetImg__caption-container {
      margin-top: 0.25rem;

      @include media('md-up') {
        margin-top: 0.5rem;
      }
    }

    // Increase margin between stacked inset images
    .InsetImg--hide-rules + .InsetImg--hide-rules {
      @include media('md-up') {
        margin-top: 1.5rem;
      }
    }
  }

  // Fix for backwards-compatibility with legacy method of
  // including full-width hero images in article conten
  &--is-legacy {
    .ArticleView__content-switch {
      > div:first-of-type {
        &.InsetImg--center {
          border-top: 0;
          padding-top: 0;

          @include media('lg-up') {
            margin: 0 auto 2rem;
            width: 100%;
            display: inline-block;
            position: relative;
          }
        }
      }
    }
  }
}

.ArticleRelatedRecipes {
  hr {
    margin: 15px 0 0;
  }
  &__title {
    font-size: 20px;
  }

  &--aside {
    flex-direction: column;
    border-top: 1px solid;
    border-bottom: 1px solid;

    .ArticleRelatedRecipes__title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: initial;
      margin: 12px 0;
    }

    hr {
      margin: 0;
    }

    @include media('md-up') {
      float: right;
      border-top: 0;
      border-left: 1px solid;
      padding: 0 0 15px 15px;
      margin: 15px 0 15px 15px;
      width: 50%;
      max-width: 500px;
    }
  }
}

.ArticleRelatedRecipe {
  padding: 25px 0;
  display: flex;
  &:last-child {
    padding-bottom: 0;
  }

  &__image {
    width: 300px;
    margin-right: 25px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__link {
    font-size: 18px;
  }
  .ArticleRelatedRecipe__link a {
    text-decoration: none;
    background-image: none;
    color: inherit;
  }

  .RecipeTeaser__byline {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  & + & {
    @extend %dotted-border-top-black;
  }

  &--aside {
    padding: 15px 0;

    .ArticleRelatedRecipe__title {
      font-size: 24px;
      margin-bottom: 0.5rem;
    }

    .ArticleRelatedRecipe__image {
      margin-right: 12px;
      flex-basis: 50%;
    }
    .ArticleRelatedRecipe__body {
      flex-basis: 50%;
      &:only-child {
        flex-basis: 100%;
      }
    }

    &:last-child {
      padding-bottom: 15px;
    }

    @media screen and (max-width: 768px) {
      .ArticleRelatedRecipe__image {
        flex-basis: 25%;
        margin-right: 15px;
      }

      .Teaser__byline {
        margin: 0.5rem 0;
      }
      .ArticleRelatedRecipe__link {
        margin-bottom: 0;
      }
    }

    @include media('md-up') {
      .ArticleRelatedRecipe__title {
        font-size: 30px;
        margin-bottom: 1.5rem;
      }

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
}
