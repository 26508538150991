.collections-10-stories {
  .collections-6-stories__article3-article4-container {
    @include media('xs-down') {
      grid-gap: 1.5rem;
    }
  }
  .collections-6-stories__article5-article6-container {
    @include media('xs-down') {
      grid-gap: 1.5rem;
    }
  }
  .collections-4-stories {
    margin: 0rem 1rem 2rem 1rem;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    border-top: $border-black-dotted;

    @include media('sm-up') {
      margin: 0rem 1.5rem 3rem 1.5rem;
    }
  }
  // .collections-6-stories__article2-container {
  //   @include media('xs-down') {
  //     padding-top: 0;
  //   }
  // }
  .collections-6-stories {
    &__container {
      padding: 1rem 1rem 0rem 1rem;

      @include media('sm-up') {
        padding: 1.5rem 1.5rem 0rem 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
