.PodcastWidget {
  &--style-red {
    color: color('red');

    .PodcastWidget__podcast-container {
      @extend %dotted-border-red;
    }
  }

  &--style-black {
    color: color('off-white');
    background-color: color('black');

    .PodcastWidget__podcast-container {
      @extend %dotted-border-off-white;
    }
  }

  &--style-beige {
    color: color('beige');

    .PodcastWidget__podcast-container {
      @extend %dotted-border-beige;
    }
  }

  .PodcastWidget__podcast-container:last-child {
    background-image: none;
  }

  &__sound-icon {
    width: 2.25rem;
  }

  &__podcast-title {
    letter-spacing: -0.028125rem;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }
}
