.ColumnistWidget {
  &__heading-container {
    justify-content: space-between;
    border-bottom: 1px solid var(--black);
    padding-bottom: 1.3125rem;
  }

  &__see-all-button {
    color: var(--black);
  }
}

.Columnist {
  &__article-container {
    background-image: linear-gradient(
      to right,
      color('black') 24%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    &:last-child {
      background-image: none;
    }
  }

  &__row {
    padding: 19px 0;

    &__article-button {
      text-decoration: none;
      margin-bottom: 0.25rem;
      font-size: 1.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  &__article-image {
    width: 64px;
    height: 64px;
    margin-right: 8px;
    object-fit: cover;
    flex-shrink: 0;
  }

  &__text {
    gap: 6px;

    &__title {
      margin-bottom: 0.25rem;

      &__link {
        text-decoration: none;
      }
    }
  }
}
