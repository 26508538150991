@import 'config';
@import 'scss-utils';
@import 'settings', 'sizing', 'fontface', 'reset', 'typography', 'global';
@import 'components', 'views', 'amp';

*:focus {
  outline-color: color('gray');
}

[data-whatintent='mouse'] *:focus {
  outline: none;
}

.disable-scroll {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.App {
  min-height: 100vh;

  &__content-container {
    min-width: 100vw;

    &--side-nav-is-active {
      margin-left: $side-nav-mobile-width;

      @include media('md-up') {
        margin-left: $side-nav-desktop-width;
      }
    }

    // &--section-nav-is-active {
    //   position: fixed;
    // }
  }

  &__close-side-nav-button {
    left: $side-nav-mobile-width;
    cursor: default;

    @include media('md-up') {
      left: $side-nav-desktop-width;
    }
  }

  &__main--side-nav-active {
    min-width: 100vw;
  }

  @media print {
    background-color: transparent;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 500ms $transition-easing 300ms;
  opacity: 1;
}

.fade-exit {
  transition: opacity 200ms;
  opacity: 1;
  position: absolute;
  width: 100%;
  top: 0;
}

.fade-exit-active {
  opacity: 0;
}

.no-display-in-print {
  @media print {
    display: none;
  }
}
