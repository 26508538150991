.ShareButton {
  &--events-none {
    .ShareButton__buttons {
      pointer-events: none;
    }
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.5rem;

    &--print {
      margin-right: 0.75rem;
    }
  }

  &__copied-text {
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-top: 0.15rem;
  }

  &__copied-text--active {
    margin-right: -3.6rem;
  }

  &__arrow-icon {
    transform: rotate(-45deg);
    font-size: 1.25rem;
  }

  &__button--active {
    .ShareButton__arrow-icon {
      transform: rotate(0);
    }
  }

  &__button:hover {
    .ShareButton__arrow-icon {
      transform: rotate(0);
    }
  }

  &--subtle-transition {
    .ShareButton__buttons {
      @extend %transition;
      transform: translate3d(8.5rem, 0, 0);
    }

    .ShareButton__buttons--active {
      @extend %transition;
      transform: translate3d(0, 0, 0);
    }
  }
}
