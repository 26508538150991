.collection-background-grey {
  .teaser-widget-container {
    padding: 0;
    margin: 0;
    border: none;

    &.fiction-view--more-padded {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.collection-background-grey-section {
  .teaser-widget-container {
    padding: 0;
    margin: 0;
    border: none;

    &.fiction-view--more-padded {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.collections-8-stories {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;
  margin-top: 1.5rem;
  border-top: $border-black-dotted;
  padding-top: 1rem;
  column-gap: 1.5rem;

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, auto));
  }

  &__article1-container {
    grid-column: 1/2;
    grid-row: 1/2;
    position: relative;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 1/2;
      padding-bottom: 1.5rem;

      &::after {
        content: '';
        border-right: $border-black-dotted;
        position: absolute;
        right: -0.75rem;
        top: 0;
        height: 100%;
      }
    }

    @include media('lg-up') {
      grid-column: 1/2;
      grid-row: 1/2;
      padding-bottom: 0;
    }
  }

  &__article2-container {
    grid-column: 1/2;
    grid-row: 2/3;
    padding-top: 1rem;
    border-top: $border-black-dotted;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 1/2;
      padding-bottom: 1.5rem;
      padding-top: 0;
      border-top: none;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 1/2;
      border-right: $border-black-dotted;
      padding-bottom: 0;
    }
  }

  &__article3-container {
    grid-column: 1/2;
    grid-row: 3/4;
    position: relative;
    border-top: $border-black-dotted;
    padding-top: 1rem;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 2/3;
      padding-top: 1.5rem;

      &::after {
        content: '';
        border-right: $border-black-dotted;
        position: absolute;
        right: -0.75rem;
        top: 0;
        height: 100%;
      }
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 1/2;
      padding-top: 0;
      border-top: none;
    }
  }

  &__article4-container {
    grid-column: 1/2;
    grid-row: 4/5;
    padding-top: 1rem;
    border-top: $border-black-dotted;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 2/3;
      padding-top: 1.5rem;
    }

    @include media('lg-up') {
      grid-column: 4/5;
      grid-row: 1/2;
      padding-top: 0;
      border-top: none;
    }
  }

  > div {
    padding-bottom: 3rem;
  }
}
