.LayoutC {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(4, auto);

  @include media('sm-up') {
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  @include media('md-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, auto));
  }

  //right-to-left alignment
  &__article1-container--right-to-left {
    grid-column: 1/3;
    grid-row: 1/2;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @include media('md-up') {
      grid-column: 2/4;
      grid-row: 1/2;
    }

    @include media('lg-up') {
      grid-column: 3/5;
      grid-row: 1/4;
    }

    @include media('xl-up') {
      grid-column: 3/5;
      grid-row: 1/3;
    }
  }

  &__article2-container--right-to-left {
    grid-column: 1/2;
    grid-row: 2/3;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('xl-up') {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  &__article3-container--right-to-left {
    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 2/3;
    }

    @include media('xl-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  &__article4-container--right-to-left {
    grid-column: 1/3;
    grid-row: 3/4;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 4/5;
      border-bottom: none;
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 3/4;
    }

    @include media('xl-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }

  &__article2-article3-container--right-to-left {
    grid-gap: 1.875rem;
    grid-column: 1/3;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.875rem;
    border-bottom: $border-black;

    &::after {
      content: '';
      border-right: $border-black;
      position: absolute;
      left: 50%;
      top: 0.9375rem;
      height: calc(100% - 1rem - 0.9375rem);
    }

    @include media('sm-up') {
      display: grid;
      grid-column: 2/3;
      grid-row: 2/3;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      border-bottom: none;

      &::after {
        border-right: none;
      }
    }

    @include media('md-up') {
      display: none;
    }
  }

  &__article3-article4-container--right-to-left {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap: 1.875rem;

    @include media('md-up') {
      display: grid;
      grid-column: 3/4;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }

    @include media('xl-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  &__widget-container--right-to-left {
    grid-column: 1/3;
    grid-row: 4/5;
    border-bottom: $border-black;

    @include media('sm-up') {
      border-right: $border-black;
      padding: 0 3rem 3rem 0;

      grid-column: 1/2;
      grid-row: 2/5;
    }

    @include media('md-up') {
      grid-column: 1/2;
      grid-row: 1/3;
    }

    @include media('lg-up') {
      grid-column: 1/2;
      grid-row: 1/4;
    }

    @include media('xl-up') {
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }

  //left-to-right alignment
  &__article1-container--left-to-right {
    grid-column: 1/3;
    grid-row: 1/2;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @include media('md-up') {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @include media('lg-up') {
      grid-column: 1/3;
      grid-row: 1/3;
    }
  }

  &__article2-container--left-to-right {
    grid-column: 1/2;
    grid-row: 2/3;
    display: none;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    @include media('md-up') {
      grid-column: 1/2;
      grid-row: 2/3;
      display: grid;
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 1/2;
    }

    @include media('xl-up') {
      grid-column: 3/4;
      grid-row: 1/2;
    }
  }

  &__article3-container--left-to-right {
    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('xl-up') {
      grid-column: 3/4;
      grid-row: 2/3;
    }
  }

  &__article4-container--left-to-right {
    grid-column: 1/3;
    grid-row: 3/4;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 3/4;
      border-bottom: none;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }

    @include media('xl-up') {
      grid-column: 3/4;
      grid-row: 3/4;
    }
  }

  &__article2-article3-container--left-to-right {
    grid-gap: 1.875rem;
    grid-column: 1/3;
    grid-row: 2/3;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.875rem;
    border-bottom: $border-black;

    &::after {
      content: '';
      border-right: $border-black;
      position: absolute;
      left: 50%;
      top: 0.9375rem;
      height: calc(100% - 1rem - 0.9375rem);
    }

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 2/3;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      border-bottom: none;

      &::after {
        border-right: none;
      }
    }

    @include media('md-up') {
      display: none;
    }
  }

  &__article3-article4-container--left-to-right {
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-gap: 1.875rem;

    @include media('md-up') {
      display: grid;
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 2/3;
    }
  }

  &__widget-container--left-to-right {
    grid-column: 1/3;
    grid-row: 4/5;
    border-bottom: $border-black;

    @include media('sm-up') {
      padding: 0 0 3rem 1.5rem;

      grid-column: 2/3;
      grid-row: 2/4;
    }

    @include media('md-up') {
      grid-column: 3/4;
      grid-row: 1/3;
    }

    @include media('lg-up') {
      grid-column: 4/5;
      grid-row: 1/3;
    }
  }
}
