.ContributorsNav {
  &__logo {
    @include media('lg-up') {
      pointer-events: none;
      animation: $transition-animate-disappear-to-top;
      animation-fill-mode: forwards;
    }
  }

  &__logo--active {
    opacity: 1;
    pointer-events: all;

    @include media('lg-up') {
      animation: $transition-animate-appear-from-bottom;
      animation-fill-mode: forwards;
    }
  }

  &__logo-icon {
    width: 7rem;

    @include media('md-up') {
      width: 9.875rem;
    }
  }

  &__info {
    max-width: $inner-content-max-width;
    opacity: 0;
    pointer-events: none;
    animation: $transition-animate-disappear-to-bottom;
    animation-fill-mode: forwards;
  }

  &__info--active {
    @include media('lg-up') {
      opacity: 1;
      animation: $transition-animate-appear-from-top;
      animation-fill-mode: forwards;
      pointer-events: all;
    }
  }

  &__letter {
    font-size: 0.8125rem;

    @include media('lg-up') {
      font-size: 1rem;
      margin: 0 0.1rem;
    }
  }

  &__mobile-letter-container {
    padding-bottom: 10%;
  }
}
