.SideNav {
  left: 0;
  width: $side-nav-mobile-width;
  transform: translate3d(-$side-nav-mobile-width, 0, 0);

  @include media('md-up') {
    left: 0;
    width: $side-nav-desktop-width;
    transform: translate3d(-$side-nav-desktop-width, 0, 0);
  }

  &--active {
    transform: translate3d(0, 0, 0);

    @include media('md-up') {
      transform: translate3d(0, 0, 0);
    }
  }

  &__sections {
    border-top: $border-gray-darkest;
  }

  &__section-container {
    &:nth-of-type(odd) {
      &:nth-child(odd) {
        padding-right: 0.25rem;

        @include media('md-up') {
          padding-right: 0.5rem;
        }
      }
    }
  }

  &__section {
    border-right: $border-gray-darkest;
    border-bottom: $border-gray-darkest;
    height: 3.75rem;
  }

  &__nav-section-icon {
    max-height: 0.75rem;

    @include media('md-up') {
      max-height: 1rem;
    }
  }

  &__icon {
    display: inline-block;
  }

  &__search-icon {
    width: 1.25rem;
    padding-bottom: 0.3rem;
  }

  &__search-bar-icon {
    width: 0.875rem;
  }

  &__twitter-icon {
    max-width: 0.879375rem;
  }

  &__facebook-icon {
    max-width: 0.515625rem;
  }

  &__instagram-icon {
    max-width: 0.875rem;
  }

  &__secondary-menu {
    line-height: 1.4375rem;

    a {
      text-decoration: none !important;

      > span {
        background-position: left 98% !important;
      }
    }
  }

  &__footer-credits {
    line-height: 1.125rem;
    letter-spacing: 0rem;

    @include media('md-up') {
      line-height: 1.5rem;
    }

    a {
      color: color('gray-dark');

      @include media('md-up') {
        color: color('gray-darker');
      }
    }
  }

  &__text-field {
    padding: 0.25rem 0.9375rem 0.25rem 0.625rem;
    width: calc(100% - 0.9375rem);
  }
}
