.ImageTextStoryTeaserWidget {
  &--small {
    padding-top: 1rem;
    padding-bottom: 3rem;

    @include media('sm-up') {
      padding-top: 0;
    }

    .ImageTextStoryTeaserWidget__container__image {
      overflow: hidden;
      height: auto;
    }

    .ImageTextStoryTeaserWidget__image {
      vertical-align: middle;
      height: auto;

      @include media('sm-up') {
        margin-top: 0;
      }

      &.carousel-teaser-img {
        .grayscale-img,
        .color-img {
          object-fit: cover;
          object-position: top;
        }
      }
    }

    .ImageTextStoryTeaserWidget__section-button {
      padding-top: 1rem;
    }

    .ImageTextStoryTeaserWidget__title-button {
      padding-top: 1rem;

      font-weight: 600;
      font-size: 1.875rem;
      line-height: 2rem;

      @include media('sm-up') {
        font-size: 1.75rem;
        line-height: 1.875rem;
      }

      @include media('lg-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }
    }

    .ImageTextStoryTeaserWidget__dek {
      padding-top: 0.5rem;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.5rem;

      &.carousel-teaser-dek {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-wrap: break-word;
        white-space: normal;

        @include media('lg-up') {
          -webkit-line-clamp: 4;
        }
      }
    }

    .ImageTextStoryTeaserWidget__authors {
      padding-top: 1rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.0625rem;
      padding-bottom: 2rem;
    }
  }

  &--medium {
    padding-bottom: 3rem;

    @include media('sm-up') {
      padding-bottom: 4.5rem;
    }

    .ImageTextStoryTeaserWidget__image {
      margin-top: 0.9375rem;
      vertical-align: middle;

      @include media('sm-up') {
        margin-top: 0;
      }
    }

    .ImageTextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 2rem;
      }
    }

    .ImageTextStoryTeaserWidget__title-button {
      padding-top: 1.5rem;
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;

      @include media('lg-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
      }
    }

    .ImageTextStoryTeaserWidget__dek {
      padding-top: 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;

      @include media('sm-up') {
        letter-spacing: 0;
      }
    }

    .ImageTextStoryTeaserWidget__authors {
      font-weight: 700;
      padding-top: 1rem;
      line-height: 1.125rem;
      padding-bottom: 2rem;

      .AuthorButtons__button-text {
        line-height: 1.125rem;
      }
    }
  }

  &--large {
    padding-bottom: 3rem;

    @include media('sm-up') {
      padding-bottom: 4.5rem;
    }

    .ImageTextStoryTeaserWidget__image {
      vertical-align: middle;
      margin-top: 0.9375rem;

      @include media('sm-up') {
        margin-top: 0;
      }
    }

    .ImageTextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 2rem;
      }
    }

    .ImageTextStoryTeaserWidget__title-button {
      padding-top: 1.5rem;
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;

      @include media('md-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
      }

      @include media('xl-up') {
        font-size: 4.5rem;
        line-height: 4.5rem;
        letter-spacing: -0.046875rem;
      }
    }

    .ImageTextStoryTeaserWidget__title {
      @include media('md-up') {
        background-position: left 95%;
      }

      @include media('lg-up') {
        background-position: left 93%;
      }

      @include media('xl-up') {
        background-position: left 91%;
      }
    }

    .ImageTextStoryTeaserWidget__dek {
      padding-top: 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .ImageTextStoryTeaserWidget__authors {
      font-weight: 700;
      padding-top: 1rem;
      padding-bottom: 2rem;

      .AuthorButtons__button-text,
      .byline {
        line-height: 1.125rem;
      }

      @include media('sm-up') {
        padding-top: 1.0625rem;
      }

      @include media('md-up') {
        padding-top: 1rem;
      }

      @include media('xl-up') {
        padding-top: 1.0625rem;
      }
    }
  }

  &--split-layout {
    @media (max-width: 767px) {
      padding-bottom: 3rem;
    }

    .ImageTextStoryTeaserWidget__section-button {
      padding-top: 1rem;
    }

    .ImageTextStoryTeaserWidget__title-button {
      @media (max-width: 767px) {
        padding-top: 1rem;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .ImageTextStoryTeaserWidget__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .ImageTextStoryTeaserWidget__authors {
      @media (max-width: 767px) {
        padding-top: 1.375rem;
        padding-bottom: 2rem;
      }
    }
  }
}
