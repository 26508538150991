.Footer {
  @media (max-width: 767px) {
    padding-right: $content-padding-x-sm;
    padding-left: $content-padding-x-sm;
  }

  &__container {
    border-top: $border-black;
    padding: 0.3rem 0 0.5rem;

    &--no-max-width {
      @include media('sm-up') {
        max-width: none !important;
      }
    }

    @include media('sm-up') {
      justify-content: space-between;
    }
  }

  &__links {
    @include media('sm-up') {
      width: 70%;
      column-gap: 15px;
    }
  }

  &__section {
    @extend %dotted-border-black;
    height: 4.5rem;

    .termsBtn1 {
      margin-right: 1.125rem;

      @include media('sm-up') {
        margin-right: 0;
      }
    }

    @include media('sm-up') {
      height: 2.5rem;
      background-image: none;
    }
  }

  &__section:first-of-type {
    background-image: none;
  }

  &__section--black {
    border-color: color('gray-darker') !important;
    background-image: linear-gradient(
      to right,
      color('gray-darker') 24%,
      rgba(255, 255, 255, 0) 0%
    );

    @include media('sm-up') {
      background-image: none;
    }
  }

  &__section--subscribe {
    line-height: 24px;
  }

  &__text {
    @include media('sm-up') {
      margin-right: 1.125rem;
    }
  }

  &__subscribe-newsletter-text {
    line-height: 1.4rem;
  }

  &__twitter-icon {
    width: 0.875rem;
  }

  &__facebook-icon {
    width: 0.52rem;
  }

  &__instagram-icon {
    width: 0.875rem;
  }
}

.Footer--no-border-top {
  .Footer__section:first-of-type {
    background-image: none;
    border-top: none;
  }
}
