@mixin l-bracket-right($color) {
  box-shadow: inset -1px -1px 0px $color;
}

@function player-top-spacing($spacing) {
  @return calc(var(--top-offset) + #{$spacing});
}

$breakpoint-xs: 375px;
$breakpoint-s: 768px;
$breakpoint-m: 1080px;
$breakpoint-l: 1440px;
$breakpoint-xl: 1920px;

:root {
  --blur-gradient: rgba(247, 246, 242, 0);
  --podcast-title-color: var(--black);
  --podcast-ruler-color: var(--black);
}

.dark-player {
  --black: #cccccc;
  --off-white: #222222;
  --gray-darker: #999999;
  --blur-gradient: rgba(34, 34, 34, 0);
  --podcast-title-color: #e6e5e6;
  --podcast-ruler-color: #666666;
}

.sand-player {
  --black: #222222;
  --off-white: #f1d5b9;
  --gray-darker: #999999;
  --blur-gradient: rgba(34, 34, 34, 0);
  --podcast-title-color: #e6e5e6;
  --podcast-ruler-color: #666666;
}

.simple-player,
.podcast-player {
  // 17px leads to 0 spacing between handle number and content above
  --top-offset: 17px;
  margin-bottom: 48px;
  margin-top: player-top-spacing(40px);

  @media (max-width: $breakpoint-m) {
    margin-top: player-top-spacing(24px);
    margin-bottom: 40px;
  }

  @media (max-width: $breakpoint-s) {
    margin-bottom: 32px;
  }
}

.podcast-player {
  --top-offset: 0px;

  &.player-at-top {
    margin-top: 7px;
  }
}

.AudioPlayer {
  font-family: $graebenbach;
  background: color('off-white');

  .player-slider {
    margin-bottom: 1px;

    .rc-slider {
      padding: 7px 0;
    }

    .rc-slider-handle {
      transition: opacity 0.2s ease-in-out;

      .audio-slider-tooltip {
        opacity: 0;
      }

      &:active {
        .audio-slider-tooltip {
          opacity: 1;
        }
      }
    }

    .rc-slider-track,
    .rc-slider-rail {
      transition: max-height 0.2s ease-in-out;
    }

    .rc-slider-track {
      max-height: 2px;
    }
    .rc-slider-rail {
      max-height: 1px;
    }

    &:active,
    &:focus-within,
    &:hover {
      .rc-slider-track {
        max-height: 4px;
      }

      .audio-slider-tooltip {
        opacity: 1;
      }
    }

    @media (min-width: $breakpoint-m) {
      .rc-slider-handle {
        opacity: 0;
      }

      &:active,
      &:focus-within,
      &:hover {
        .rc-slider-handle {
          opacity: 1;
        }
      }
    }
  }

  &__ControlsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 8px 14px 0px;
    margin-right: 8px;

    @include l-bracket-right(var(--podcast-ruler-color));

    width: 232px;
    height: 48px;

    @media (min-width: $breakpoint-s) {
      width: 128px;
    }

    @media (max-width: $breakpoint-s) and (min-height: $breakpoint-xs) {
      width: 120px;
    }

    @media (max-width: $breakpoint-xs) {
      flex: 1;
      padding-right: 0;

      &__InnerContainer {
        max-width: 120px;
      }
    }

    &__InnerContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }

  &__TimestampContainer {
    display: block;
    white-space: nowrap;
    padding: 9px 16px 13px 0px;
    margin-right: 8px;

    height: 48px;

    .widest-timestamp {
      visibility: hidden;
    }

    @media (min-width: $breakpoint-s) {
    }

    @media (max-width: $breakpoint-s) and (min-height: $breakpoint-xs) {
      flex: 1;

      &__Text {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    @media (max-width: $breakpoint-xs) {
      display: none;
      padding-top: 9px;
      padding-bottom: 13px;
      padding-left: 7.5px;
    }

    @include l-bracket-right(var(--podcast-ruler-color));

    &__Text {
      font-size: 18px;
      line-height: 24px;

      height: 19px;
    }
  }

  &__MainBox {
    display: flex;
    padding-top: 9px;
    padding-bottom: 13px;
    margin-right: 8px;
    height: 48px;

    flex: 1;
    overflow: hidden;
    white-space: nowrap;

    width: 100%;

    &:hover {
      .slide-left {
        animation: slideLeft 10s infinite linear;
        animation-play-state: running;
      }
    }

    .slide-left {
      display: inline-block;
      white-space: nowrap;
    }

    @include l-bracket-right(var(--podcast-ruler-color));

    @media (max-width: $breakpoint-s) {
      display: none;
    }

    &.small-screen {
      margin-top: 8px;
      margin-right: 0;

      @media (min-width: $breakpoint-s) {
        display: none;
      }

      @media (max-width: $breakpoint-s) {
        display: flex;
      }
    }

    position: relative;

    .subscribe-options {
      margin: 0 auto;
      line-height: 1px;

      .subscribe-option {
        cursor: pointer;
        margin-right: 4px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: color('black');
        background: none;
      }

      .subscribe-text {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: color('black');

        margin-right: 8px;

        @media (max-width: $breakpoint-m) {
          display: none;
        }
      }
    }

    .plain-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }

    span.blur-end {
      background: linear-gradient(
        270deg,
        color('off-white') 20.31%,
        var(--blur-gradient) 100%
      );

      position: absolute;
      width: 47px;
      height: 40px;
      right: 1px;
      top: 3px;
    }
  }

  .marquee-spacing {
    margin-right: 40px;
  }

  @keyframes slideLeft {
    to {
      transform: translateX(calc(-50% - 20px));
    }
  }

  .small-audio-title {
    color: color('black');
    box-shadow: 0px 1px 0px var(--podcast-ruler-color);
    margin-bottom: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 0px 16px;

    .plain-title {
      font-family: $graebenbach;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }

    @media (min-width: $breakpoint-s) {
      display: none;
    }
  }

  .small-timestamp {
    font-family: $graebenbach;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;

    @media (min-width: $breakpoint-xs) {
      display: none;
    }
  }

  &__VolumeContainer {
    width: 48px;
    margin-right: 8px;

    @media (max-width: $breakpoint-m) {
      display: none;
    }

    &__SlidingPart {
      width: 252px;
      @include l-bracket-right(var(--podcast-ruler-color));

      background: linear-gradient(
        to left,
        color('off-white'),
        color('off-white') 81%,
        var(--blur-gradient)
      );

      .volume-slider {
        margin-right: 15px;
        margin-left: 2px;
      }

      .volume-opener {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        flex: 63px 0 0;
      }
    }
  }

  &__ShareContainer {
    width: 48px;

    &__SlidingPart {
      width: 368px;

      @media (max-width: $breakpoint-s) {
        transform: unset !important;
        padding-left: unset !important;
      }

      @include l-bracket-right(var(--podcast-ruler-color));

      background: linear-gradient(
        to left,
        color('off-white'),
        color('off-white') 87%,
        var(--blur-gradient)
      );

      .share-opener {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        flex: 47px 0 0;

        svg {
          transition: transform 0.3s ease-in-out;
        }
      }

      &:hover {
        @media (min-width: $breakpoint-s) {
          .share-opener svg {
            transform: rotate(45deg);
          }
        }
      }

      .share-options {
        display: flex;
        align-items: flex-start;
        padding: 3px 0px 0px;

        list-style-type: none;
        justify-content: space-between;

        position: absolute;
        width: 256px;
        height: 27px;
        left: 96px;
        top: 8px;

        .share-option {
          font-family: $graebenbach;
          cursor: pointer;
          letter-spacing: 0px;
          text-align: left;
          line-height: 24px;
          font-size: 18px;
          width: unset;
          transform: unset;
          background-size: 0% 1px;

          &:hover {
            background-size: 100% 1px;
          }
        }
      }
    }
  }

  &__PodcastInfoContainer {
    display: flex;
    flex: 1 1;
    justify-content: space-between;

    padding-bottom: 34px;
    margin-bottom: -7px;

    margin-top: 8px;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      top: -8px;
      background: var(--podcast-ruler-color);
      height: 1px;
    }

    @media (min-width: $breakpoint-s) {
      box-shadow: inset -1px 0px 0px var(--podcast-ruler-color);
    }

    .podcast-info-image {
      position: relative;
      width: 128px;
      height: 128px;

      margin-right: 16px;

      background: #c4c4c4;
      border-radius: 8px;
    }

    .podcast-info-block {
      margin-right: 24px;
      margin-top: 4px;

      .podcast-title {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: var(--podcast-title-color);
      }

      .episode-name {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
      }

      .episode-description {
        font-size: 18px;
        line-height: 24px;

        margin-top: 8px;
        margin-bottom: 4px;
        color: color('gray-darker');
      }

      .episode-date {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: color('gray-darker');
      }
    }

    .podcast-info-block {
      flex: 1;
    }
  }
}
