.HolidaysHero {
  &__container {
    @include media('md-down') {
      border-right: 0;
      background-image: none;
    }
  }

  &__title-container {
    @media (max-width: 1079px) {
      background-image: none !important;
    }

    @extend %dotted-border-right-black;
  }

  &__up-next-button {
    padding-top: 0.6rem;
    width: 9rem;
  }

  &__title {
    @include media('md-down') {
      font-size: 3.75rem;
      line-height: 3.75rem;
    }
  }

  &__description {
    max-height: 36rem;
    overflow: hidden;
    padding-bottom: 0;
    margin-bottom: 0;

    @include media('sm-down') {
      margin-bottom: 1rem;
    }

    @include media('md-down') {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &__paragraph {
    > div > p {
      margin-bottom: 1rem;

      > a,
      > strong > a,
      > em > a {
        @extend %linked-border-bottom-red;
        color: inherit;
        text-decoration: none;
      }
    }

    p {
      a {
        @extend %linked-border-bottom-red;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__readMore {
    position: sticky;
    z-index: 10;
    bottom: 0;
    text-align: center;
    padding-bottom: 0.4rem;

    @include media('md-up') {
      padding-bottom: 0.9rem;
    }
    @include media('lg-up') {
      padding-bottom: 2.25rem;
    }
  }

  // &__gradient {
  //   position: absolute;
  //   background: color('beige');
  //   padding-top: 11.2rem;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(239, 233, 220, 0) 0%,
  //     rgba(239, 233, 220, 1) 45%
  //   );
  //
  //   @include media('md-up') {
  //     padding-top: 12rem;
  //   }

  // @include media('lg-up') {
  //   padding-top: 6rem;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(239, 233, 220, 0) 0%,
  //     rgba(239, 233, 220, 1) 45%
  //   );
  // }

  &__gradient {
    background: color('beige');
    background: linear-gradient(
      180deg,
      rgba(239, 233, 220, 0) 0%,
      rgba(239, 233, 220, 1) 66%
    );

    padding-top: 13rem;

    @include media('md-up') {
      padding-bottom: 4.5rem;
    }
  }
}
