.BlockContactUs {
  max-width: $inner-content-max-width;

  &__title {
    font-size: 3rem;
    line-height: 3rem;

    @include media('xl-up') {
      font-size: 3.75rem;
      line-height: 3.75rem;
    }
  }

  &__text {
    font-size: 1.875rem;
    line-height: 2.25rem;

    @include media('lg-up') {
      font-size: 2.25rem;
      line-height: 3rem;
    }
  }
}
