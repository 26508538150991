.HomeView {
  position: relative;

  & #highlightOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 680;
    transition: all 1s;
    opacity: 0;
    pointer-events: none;
  }

  .highlight {
    position: relative;
    background: #ffffff;
    padding: 16px;
    border-radius: 40px;
  }

  .calloutHidden {
    display: none;
  }

  .calloutVisible {
    display: block;
  }
}
