.LegacyParagraph {
  @include media('md-up') {
    margin-left: $article-reference-text-spacing-md;
    margin-right: $article-reference-text-spacing-md;
  }

  @include media('lg-up') {
    margin-left: $article-reference-text-spacing-lg;
    margin-right: $article-reference-text-spacing-lg;
  }

  @include media('xl-up') {
    margin-left: $article-reference-text-spacing-xl;
    margin-right: $article-reference-text-spacing-xl;
  }
}

.LegacyParagraph:last-child {
  margin-bottom: 0;
}
