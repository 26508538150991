.LayoutJ {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  @include media('md-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, auto));
  }

  &__article1-container {
    grid-column: 1/2;
    grid-row: 1/2;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @include media('md-up') {
      grid-column: 1/4;
      grid-row: 1/2;
    }

    @include media('lg-up') {
      grid-column: 2/5;
      grid-row: 1/2;
    }
  }

  &__article2-container {
    grid-column: 1/2;
    grid-row: 2/3;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    @include media('md-up') {
      grid-column: 1/3;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 2/4;
      grid-row: 2/3;
    }
  }

  &__article3-container {
    grid-column: 1/2;
    grid-row: 3/4;

    @include media('md-up') {
      grid-column: 3/4;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 4/5;
      grid-row: 2/3;
    }
  }

  &__article4-container {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  &__article3-article4-container,
  &__article5-article6-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.875rem;

    border-bottom: $border-black;
    padding-bottom: 1rem;

    &::after {
      content: '';
      border-right: $border-black;
      position: absolute;
      left: 50%;
      top: 0.9375rem;
      height: calc(100% - 1rem - 0.9375rem);
    }

    @include media('sm-up') {
      border-bottom: none;
      &::after {
        display: none;
      }
    }
  }

  &__article3-article4-container {
    grid-column: 1/2;
    grid-row: 3/4;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  &__article5-article6-container {
    grid-column: 1/2;
    grid-row: 4/5;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }

  &__article3-article4-article5-article6-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;

    grid-column: 1/3;

    > div:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
    > div:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
    > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2/3;
    }
  }

  &__article4-article5-article6-container {
    @include media('md-up') {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: repeat(1, auto);
      grid-gap: 1.5rem;
      row-gap: 1.5rem;

      grid-column: 1/4;
      grid-row: 3/4;
    }

    @include media('lg-up') {
      display: flex;
      grid-column: 1/2;
      grid-row: 1/3;
      grid-gap: 0;
    }
  }
}
