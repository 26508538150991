$explore-button-height: 3rem;
$desktop-space-height: 2.3125rem;
$mobile-space-height: 1.875rem;
$text-width: 8.2rem;

.ExploreScrollButton {
  width: 4.5rem;
  padding: 0 0.9375rem;
  bottom: $mobile-space-height;

  @include media('md-up') {
    bottom: $desktop-space-height;
  }

  &--collapsed {
    transform: translate3d(0, 5rem, 0);

    @include media('md-up') {
      transform: translate3d(0, 5.5rem, 0);
    }
  }

  &--side-nav-is-active {
    transform: translate3d(
      $side-nav-mobile-width,
      calc(100vh - #{$explore-button-height} - #{$mobile-space-height}),
      0
    );

    @include media('md-up') {
      transform: translate3d(
        $side-nav-desktop-width,
        calc(100vh - #{$explore-button-height} - #{$desktop-space-height}),
        0
      );
    }
  }

  &__text {
    padding-left: 0.65rem;
  }

  &__textContainer {
    transition: $transition-duration-short;
    overflow: hidden;
  }

  &--move-up {
    opacity: 1;
    pointer-events: all;
    transform: translate3d(0, -15rem, 0);

    @include media('sm-up') {
      transform: translate3d(0, -5rem, 0);
    }
  }

  &__button {
    height: $explore-button-height;
    max-height: $explore-button-height;
    padding: 0 1.625rem;
    border-radius: 1.875rem;
    box-shadow: 0px 0px 2rem 0rem rgba(34, 34, 34, 0.15);

    &:focus {
      outline: none;
    }

    &:hover {
      .ExploreScrollButton__icon {
        // margin-right: $text-width;
      }

      .ExploreScrollButton__text {
        // @extend .transition-short;
        // opacity: 1;
        // // transition-delay: $transition-duration-shorter;
        // pointer-events: all;
      }
    }
  }

  &__text {
    @extend .transition-shorter;
    font-size: 1rem;
    pointer-events: none;
  }

  &__prev-next-button {
    padding: 0 0.9375rem;
    bottom: $mobile-space-height;
    font-size: 1.5rem;
    height: $explore-button-height;
    width: $explore-button-height;
    border-radius: 50%;
    box-shadow: 0px 0px 2rem 0rem rgba(34, 34, 34, 0.15);
    margin: 0 0.9375rem;

    @include media('md-up') {
      bottom: $desktop-space-height;
    }

    &--collapsed {
      transform: translate3d(0, 5rem, 0);
    }

    &--side-nav-is-active {
      transform: translate3d(
        $side-nav-mobile-width,
        calc(100vh - #{$explore-button-height} - #{$mobile-space-height}),
        0
      );

      @include media('md-up') {
        transform: translate3d(
          $side-nav-desktop-width,
          calc(100vh - #{$explore-button-height} - #{$desktop-space-height}),
          0
        );
      }
    }

    &--move-up {
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, -15rem, 0);

      @include media('sm-up') {
        transform: translate3d(0, -5rem, 0);
      }
    }
  }

  &__prev-next-button--inactive {
    opacity: 0;
    pointer-events: none;
  }
}
