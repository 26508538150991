.LayoutK {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  .TextStoryTeaserWidget--medium,
  .ImageTextStoryTeaserWidget--small {
    border-bottom: $border-black;
  }

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, auto);
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  @include media('lg-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, auto));
  }

  &__article1-container {
    grid-column: 1/2;
    grid-row: 1/2;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  &__article2-container {
    grid-column: 1/2;
    grid-row: 2/3;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  &__article3-container {
    grid-column: 1/2;
    grid-row: 3/4;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 1/2;
    }
  }

  &__article4-container {
    grid-column: 1/2;
    grid-row: 4/5;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 4/5;
      grid-row: 1/2;
    }
  }
}
