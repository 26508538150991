.InsetImgSlideshow {
  margin: 0.625rem 0 0.8125rem;
  padding-bottom: 2.8125rem;

  @include media('sm-up') {
    padding-bottom: 3rem;
    margin-bottom: 0;
  }

  @include media('md-up') {
    padding-bottom: 7.125rem;
  }

  &--left,
  &--right {
    width: 100%;

    @include media('sm-up') {
      width: calc(100% / 12 * 6 - (1.5rem / 2));
    }

    @media (min-width: 1440px) {
      width: calc(100% / 12 * 5 - (1.5rem / 2));
    }
  }

  &--right {
    @include media('sm-up') {
      float: right;
      border-left: 1px solid color('black');
      margin-left: 1.5rem;
      padding-left: 1.5rem;
    }

    @media (min-width: 1440px) {
      margin-right: calc(100% / 12);
    }
  }

  &--right-black {
    @include media('sm-up') {
      border-left: 1px solid color('gray-darker');
    }
  }

  &--left {
    @include media('sm-up') {
      float: left;
      border-right: 1px solid color('black');
      margin-right: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (min-width: 1440px) {
      margin-left: calc(100% / 12);
    }
  }

  &--left-black {
    @include media('sm-up') {
      border-right: 1px solid color('gray-darker');
    }
  }

  &--center {
    @include media('sm-up') {
      border-top: 1px solid color('black');
      margin: 1.5rem 0;
      padding-top: 1.5rem;
    }

    @media (min-width: 1440px) {
      width: calc(100% / 12 * 10);
      margin-left: calc(100% / 12);
      margin-right: calc(100% / 12);
    }
  }

  &--center-black {
    @include media('sm-up') {
      border-top: 1px solid color('gray-darker');
    }
  }

  &__image {
    max-height: 43.5rem;
    max-width: $article-content-width;
  }

  &__caption-container {
    line-height: 1rem;
  }

  &__caption {
    @media (min-width: 1080px) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__carousel-container {
    touch-action: pan-x;
  }

  &__image-container {
    opacity: 0.25;
    transition: opacity $transition-duration-long;

    @include media('sm-up') {
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
  }

  &--multi-image {
    .flickity-prev-next-button {
      &.previous {
        left: 0;
        cursor: url('/assets/images/carousel-left-arrow.svg'), w-resize;
      }

      &.next {
        right: 0;
        cursor: url('/assets/images/carousel-right-arrow.svg'), e-resize;
      }
    }
  }

  .is-selected {
    opacity: 1;
  }

  .flickity-enabled:focus {
    outline: none;
  }

  .flickity-viewport {
    position: relative;
  }

  .flickity-page-dots {
    float: right;
    list-style-type: none;
    display: inline-flex;
    justify-content: flex-end;
    float: right;
  }

  .dot {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background: color('transparent');
    border-radius: 50%;
    cursor: pointer;
    border: $border-black;
    transform: translateX(0);
    padding: 0;

    @include media('sm-up') {
      width: 12px;
      height: 12px;
      margin: 0 2.5px;
    }
  }

  .dot.is-selected {
    background: color('black');
  }

  .flickity-prev-next-button {
    position: absolute;
    width: 10%;
    height: 100%;
    top: 0;
    opacity: 0;
  }
}

.InsetImgSlideshow--black {
  .dot {
    border: $border-gray-lighter;
  }

  .dot.is-selected {
    background: color('gray-lighter');
  }
}
