.ArticleEndNote {
  @extend %dotted-border-black;
  padding-top: 0.5rem;
  margin-top: 2.25rem;

  a {
    @extend %linked-border-bottom-black;
    color: color('black');
    text-decoration: none;
  }

  + .AuthorBioBlock {
    margin-top: 3.25rem;
  }

  @include media('md-up') {
    padding-top: 0.75rem;
    margin-top: 3rem;

    + .AuthorBioBlock {
      margin-top: 4rem;
    }
  }

  @include media('lg-up') {
    padding-top: 0.55rem;
    margin-top: 3.85rem;

    + .AuthorBioBlock {
      margin-top: 5.25rem;
    }
  }

  @include media('xl-up') {
    padding-top: 1rem;
    margin-top: 3.6rem;
  }
}
