.SectionNavHoliday {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  padding-top: 0.9375rem;

  @include media('md-up') {
    padding-top: 1.5rem;
  }

  &__holiday1-container {
    padding-bottom: 2.0625rem;

    @media (min-width: 1440px) {
      padding-bottom: 2.6875rem;
    }
  }

  &__up-next {
    font-weight: 600;
    font-size: 0.7rem;
    letter-spacing: 0.035rem;
    line-height: 0.476875rem;
    padding: 0.4375rem 0.4375rem 0.3125rem 0.4375rem;
    min-height: 1.25rem;
    min-width: 4.25rem;

    @media (min-width: 1440px) {
      font-size: 0.875rem;
      letter-spacing: 0.0625rem;
      line-height: 0.75rem;
      min-width: 5.25rem;
      min-height: 1.5rem;
    }
  }

  &__holiday1-title {
    font-size: 1.75rem;
    letter-spacing: -0.01rem;
    line-height: 1.875rem;
    margin-top: 1.75rem;
    margin-bottom: 1.25rem;

    @include media('md-up') {
      font-size: 1.875rem;
      letter-spacing: -0.010625rem;
      line-height: 6rem;
      margin-top: 1.5625rem;
      margin-bottom: 1.375rem;
    }

    @media (min-width: 1440px) {
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.016875rem;
      margin-top: 1.25rem;
      margin-bottom: 1.3125rem;
    }
  }

  &__holiday1-date {
    color: color('gray-darker');
    font-family: $graebenbach;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('md-up') {
      font-size: 1.25rem;
    }
  }

  &__holiday1-description {
    max-height: 15.3125rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 1.5rem;

    @include media('md-up') {
      font-size: 1.25rem;
      margin-top: 2.25rem;
    }

    @media (min-width: 1440px) {
      margin-top: 1.9375rem;
    }

    p {
      margin-bottom: 1.875rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(239, 233, 220, 0) 0%,
        rgba(239, 233, 220, 1) 66%
      );
    }
  }

  &__holiday-title {
    font-size: 1.875rem;
    line-height: 2rem;
  }

  a {
    color: inherit;
  }

  &__holiday-container {
    padding-top: 1.0625rem;
    padding-bottom: 1.5625rem;

    @media (min-width: 1440px) {
      padding-bottom: 2.3125rem;
    }
  }
}
