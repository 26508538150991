.EncyclopediaLetterResults {
  &__container {
    border-right: none;

    @include media('md-up') {
      border-right: $border-black;
    }
  }

  &__term-title {
    &--active {
      font-size: 1.875rem;
    }
  }
}
