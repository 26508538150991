.TextWidget {
  &__text {
    > div {
      > p {
        padding-bottom: 1rem;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    &--quote {
      > p {
        &::before {
          content: '“';
        }

        &::after {
          content: '”';
        }
      }

      > div {
        > p {
          &:first-of-type {
            &::before {
              content: '“';
            }
          }

          &:last-of-type {
            &::after {
              content: '”';
            }
          }
        }
      }
    }
  }
}
