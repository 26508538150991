.CollectionTitle {
  position: relative;

  &__tag {
    color: color('red');
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @include media('sm-up') {
      padding-bottom: 1.5rem;
    }

    @include media('sm-up') {
      flex-direction: row;
    }

    &__left-col {
      padding-bottom: 1.5rem;
      padding-right: 1.5rem;

      @include media('sm-up') {
        width: 50%;
      }

      &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.02em;

        @include media('xl-up') {
          font-size: 48px;
          line-height: 48px;
        }
      }
    }
    &__right-col {
      padding-bottom: 1.5rem;

      @include media('sm-up') {
        width: 50%;
      }

      &__slug {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
