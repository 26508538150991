.CollectionEditorNote {
  margin-top: 1rem;
  margin-bottom: 2rem;

  position: relative;

  &::after {
    @extend %dotted-border-black;
    content: '';
    height: 1px;
    position: absolute;
    bottom: -1rem;
    width: 100%;
  }

  + .article-content-container {
    margin-top: 2rem;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 1rem 1.5rem 1rem;
    background-color: color('collection-background-grey');

    .tag {
      color: color('red');
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding-bottom: 0.5rem;
    }
  }

  &__half-width {
    @include media('lg-up') {

      &.CollectionEditorNote__left {
        float: left;
      }

      &.CollectionEditorNote__right {
        float: right;
        margin-left: 1.5rem;
      }

      &.CollectionEditorNote__full-width {
        width: 83.33%;
      }
    }
  }
}
