.CollectionPagePinnedArticles {
  padding-bottom: 1rem;

  @include media('sm-up') {
    padding: 0 1.5rem 1.5rem 0;
    margin-top: 1.5rem;
    border-right: $border-black;

    margin-right: auto;
    margin-left: auto;
  }

  .ImageTextStoryTeaserWidget__container {
    align-items: center;
    position: relative;

    &__image {
      @include media('md-up') {
        width: 50%;
      }
    }

    &__content {
      @include media('md-up') {
        padding: 1.5rem;
        width: 50%;
      }
    }

    @include media('md-up') {
      display: flex;
    }
  }

  .teaser-widget-container {
    padding: 0;
    margin: 0;
    border: none;
  }

  &__container {
    position: relative;

    display: grid;
    grid-auto-columns: 67%;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, minmax(67%, 1fr));
    grid-template-rows: auto;
    column-gap: 1rem;

    margin-top: 1rem;
    margin-left: -1rem;
    margin-right: -1rem;

    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;

    overflow-x: scroll;
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    &::after {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top;
      background-size: 4px 1px;
      background-repeat: repeat-x;

      content: '';
      height: 1px;
      position: absolute;
      top: 0;
      left: 1rem;
      width: calc(201% - 2rem);
    }

    @include media('sm-up') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 1.5rem;
      margin-top: 1.5rem;
      border-top: $border-black-dotted;
      margin-left: 0;
      margin-right: 0;
      padding-right: 0;
      padding-left: 0;

      &::after {
        content: unset;
      }
    }

    &__article2-container {
      position: relative;
      border-right: $border-black-dotted;
      padding-right: 1rem;

      @include media('sm-up') {
        padding-bottom: 1.5rem;
        padding-right: 1.5rem;
      }

      @include media('lg-up') {
        padding-bottom: 0;
      }
    }

    &__article3-container {
      position: relative;
      border-right: $border-black-dotted;
      padding-right: 1rem;

      @include media('sm-up') {
        grid-column: 2/3;
        grid-row: 1/2;
        padding-bottom: 1.5rem;
        padding-top: 0;
        border-top: none;
        padding-right: 1.5rem;
      }

      @include media('lg-up') {
        padding-bottom: 0;
      }
    }

    &__article4-container {
      @include media('lg-up') {
        padding-top: 0;
        border-top: none;
      }
    }

    > div {
      padding-bottom: 3rem;
    }
    .text-left {
      // overrides teasers as per the collection design
      text-align: center;
    }
  }
}
