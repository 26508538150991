.EncyclopediaHeader {
  &__sticker {
    width: 13rem;
    display: block;
    margin: 0 auto;

    @include media('md-up') {
      position: absolute;
      width: 8rem;
      top: 1.5rem;
      right: 5rem;
    }

    @include media('lg-up') {
      width: 10rem;
      top: 1rem;
      right: 6rem;
    }

    &:hover {
      transform: rotate(10deg);
    }
  }

  a {
    @extend %linked-border-bottom-black;
    color: inherit;
    text-decoration: none;
  }

  &__description {
    padding-top: 1.1875rem;
    padding-bottom: 2.6875rem;

    @include media('md-up') {
      padding-top: 1.375rem;
    }

    @include media('lg-up') {
      padding-bottom: 4.1875rem;
    }
  }
}
