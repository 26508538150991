.RecipeView {
  padding-top: 35px;

  &__body {
    border-top: 1px solid;
  }

  &__mainColumn--split {
    @include media('md-up') {
      display: grid;
      grid-template-columns: 40% 1fr;
      grid-gap: 25px;
    }
  }

  &__mainColumn {
    margin: 0 auto;

    @include media('xl-up') {
      max-width: calc(100% - (5.25rem * 2));
    }
  }
}

.RecipeHeader {
  .SectionLogotype img {
    width: 78px;
  }

  @include media('md-up') {
    padding-bottom: 41px;
    margin-bottom: 2px;
  }
  @include media('lg-up') {
    padding-bottom: 41px;
    margin-bottom: 2px;
  }

  @media screen and (min-width: 1440px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__title {
    padding-top: 15px;
    font-size: 3rem;
    line-height: 3rem;

    @include media('md-up') {
      padding-top: 38px;
      font-size: 3rem;
      line-height: 3rem;
    }

    @include media('lg-up') {
      font-size: 60px;
      padding-top: 38px;
      font-size: 3rem;
      line-height: 3rem;
    }
    @media screen and (min-width: 1440px) {
      padding-top: 30px;
      font-size: 72px;
      line-height: 4.5rem;
    }
  }
  &__logotype {
    padding-top: 45px;

    @include media('md-up') {
      padding-top: 78px;
    }

    @include media('lg-up') {
      padding-top: 78px;
    }
    @media screen and (min-width: 1440px) {
      padding-top: 90px;
    }
  }

  &__bottom {
    padding-top: 25px;
    padding-bottom: 24px;

    @include media('md-up') {
      padding-top: 40px;
      padding-bottom: 0;
      display: flex;
      justify-content: center;

      > :nth-child(n + 2) {
        margin-left: 42px;
      }
    }

    @include media('lg-up') {
      padding-top: 40px;
    }
    @media screen and (min-width: 1440px) {
      padding-top: 24px;
      margin-bottom: 15px;
    }
  }

  &__byline {
    .Hero__author {
      margin-top: 0;
    }

    @include media('md-up') {
      padding-top: 0;
    }
  }

  &__publishDate {
    display: none;
    .Hero__date {
      margin-top: 0;
    }
    padding-top: 38px;

    @include media('md-up') {
      margin-top: -4px;
      padding-top: 0;
    }
  }
}

.RecipeIntro {
  margin: 0 auto;

  .BodyText {
    p {
      font-family: $bradford;
      font-size: 20px;
      font-weight: 300;
      @media screen and (min-width: 1440px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
      }
    }
  }

  &__main {
    border-top: 1px solid;
    padding-top: 15px;
    @include media('md-up') {
      padding-top: 24px;
    }
  }

  &__image {
    padding-bottom: 45px;
    border-bottom: 1px solid;

    img {
      height: auto;
    }

    @include media('md-up') {
      padding-bottom: 12px;
      border-bottom: none;
      flex-basis: 60%;
      order: 2;
    }
  }

  &__body {
    margin-top: 16px;

    @include media('md-up') {
      margin-top: 0px;
      flex-basis: 40%;
      padding-right: 24px;
      order: 1;
      &:only-child {
        padding-right: 0;
        flex-basis: initial;
      }
    }

    &:only-child {
      grid-column: span 2;
      max-width: 850px;
      margin: 0 auto;
    }
  }

  &__share-print {
    padding-top: 61px;
    padding-bottom: 7px;
    border-bottom: 1px solid;

    > :nth-child(n + 2) {
      margin-left: 30px;
    }

    @include media('md-up') {
      padding-top: 91px;
      padding-bottom: 3px;
    }

    @include media('lg-up') {
      padding-top: 103px;
    }

    @media screen and (min-width: 1440px) {
      padding-top: 86px;
    }
  }

  &__RelatedArticles {
    p {
      font-size: 20px;
    }
    p + p {
      margin-top: 20px;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 26px;
    }
  }
}

.RecipeIngredients,
.RecipePreparation {
  padding-top: 40px;

  h2 {
    font-family: $itc-cushing;
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 1em;
    letter-spacing: 0.0575em;

    @include media('md-up') {
      font-size: 18px;
      margin-bottom: 27px;
    }
  }
  h3,
  &__title {
    font-family: $graebenbach;
    font-size: 16px;
    margin-bottom: 1em;
    letter-spacing: 0.0575em;

    & + .RecipePreparation__subtitle {
      margin-top: -1.8em;
    }
  }

  &__subtitle {
    margin-bottom: 2em;
    font-family: $graebenbach;
    font-size: 16px;
  }
  ul,
  ol {
    list-style: none;
  }

  h3 {
    margin-bottom: 1.25em;
  }
}

.RecipeIngredients {
  /* Dotted Border */
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  padding-top: 42px;
  padding-bottom: 55px;

  @include media('md-up') {
    padding-top: 44px;
    margin-bottom: 26px;
    background-image: none;
  }
  @include media('lg-up') {
    padding-top: 44px;
  }

  &__heading {
    margin-bottom: 1.2em;

    &h3 {
      margin-bottom: 1.25em;
    }

    @include media('md-up') {
      padding-left: 75px;
      margin-bottom: 26px;
    }
  }

  &__group {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @include media('md-up') {
      margin-bottom: 35px;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 50px;
    }
  }

  &__list-item {
    @include media('md-up') {
      font-size: 16px;
      line-height: 1.25rem;
    }
    & + & {
      margin-top: 8px;
      @include media('md-up') {
        margin-top: 10px;
      }
    }
    @include media('lg-up') {
      line-height: 1.25rem;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 16px;
      font-size: 18px;
    }
  }

  &__list-item-amount {
    flex: 0 0 75px;
    margin-right: 15px;

    @include media('md-up') {
      flex: 0 0 60px;
    }
  }

  &__yield-time-container {
    margin-top: 50px;

    > * + * {
      margin-top: 8px;
    }
    @include media('md-up') {
      margin-top: 40px;
    }
    @include media('lg-up') {
      margin-top: 36px;
    }
  }

  &__yield-time {
    padding-left: 90px;

    @include media('md-up') {
      font-size: 16px;
      padding-left: 75px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 18px;
    }
  }
}

.RecipePreparation {
  padding-bottom: 10px;

  @include media('md-up') {
    padding-top: 44px;
    h3 {
      margin-bottom: 35px;
    }
  }

  &__group {
    & + & {
      margin-top: 65px;
    }
    & + .RecipePreparation__group--noHeader {
      margin-top: 0;
    }
  }
  &__title {
    @include media('md-up') {
      margin-bottom: 24px;
    }

    @media screen and (min-width: 1440px) {
      margin-bottom: 26px;
    }
  }
  &__note p,
  &__list-item,
  &__list-item p {
    font-family: $graebenbach;
    font-size: 18px;
    font-weight: 300;

    @include media('md-up') {
      font-size: 18px;
      line-height: 24px;
    }

    @include media('lg-up') {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    @media screen and (min-width: 1440px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  &__step-name {
    font-weight: 700;
    margin-bottom: 0.2em;
  }
}

.RelatedRecipes {
  padding-top: 105px;
}
