.fiction-page {
  color: color('fiction-blue');
  background-color: color('fiction-pink');

  p,
  h2,
  a,
  span,
  button,
  div {
    color: color('fiction-blue');
    border-color: color('fiction-blue');
  }

  div.border-bottom-transparent {
    border-bottom: 1px solid transparent;
  }

  div.Nav,
  div.ArticleNav {
    color: color('fiction-blue');
    background-color: color('fiction-pink');
  }

  div ::before,
  div ::after {
    background-image: unset;
  }

  .ArticleNav__share-button-container {
    background: color('fiction-pink');

    .ShareButton__text--print,
    &::before {
      background: color('fiction-pink');
    }

    .ArticleNav__share-button::after {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        color('fiction-pink') 30%,
        color('fiction-pink') 100%
      );
    }
  }

  div.RecentArticlesBySection__divider {
    background-color: color('fiction-blue');
  }

  .color-black {
    color: color('fiction-blue');
  }

  .bg-color-red {
    background-color: color('fiction-blue');
  }

  a div.bg-color-transparent {
    background-color: transparent;
  }

  .BlockContent a,
  .linked-border-bottom-red,
  .linked-border-bottom-lg-on-hover-red,
  .linked-border-bottom-md-on-hover-red,
  .linked-border-bottom-sm-on-hover-red {
    color: color('fiction-blue');
    background-image: linear-gradient(
      color('fiction-blue'),
      color('fiction-blue')
    );
  }

  .AuthorButtons__button-text:hover {
    text-decoration-color: color('fiction-blue');
  }

  .fiction-tag-container {
    background-color: color('fiction-blue');

    .fiction-tag-text {
      color: color('fiction-pink');
    }
  }

  blockquote {
    color: #4b6899;
  }

  .ExploreScrollButton__text {
    color: color('off-white');
  }

  .bg-color-black {
    background-color: color('fiction-blue');
  }
}
