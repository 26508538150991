.FeaturedStoryTeaserWidget {
  &--x-small,
  &--small,
  &--medium {
    padding-bottom: 2.6875rem;

    @include media('sm-up') {
      padding-bottom: 4.3125rem;
    }

    @include media('lg-up') {
      padding-bottom: 5.25rem;
    }

    .FeaturedStoryTeaserWidget__image {
      vertical-align: middle;

      &.fiction-teaser-img {
        margin-top: 16px;

        @include media('sm-up') {
          margin-top: 24px;
        }
      }

      @include media('sm-up') {
        margin-top: 0;
      }
    }

    .FeaturedStoryTeaserWidget__section-button {
      padding-top: 1.125rem;

      @include media('sm-up') {
        padding-top: 1.5625rem;
      }

      @include media('md-up') {
        padding-top: 2.1875rem;
      }
    }

    .FeaturedStoryTeaserWidget__title-container {
      margin-top: 1.25rem;

      @include media('sm-up') {
        margin-top: 2rem;
      }

      @include media('md-up') {
        margin-top: 1.9375rem;
      }

      @include media('lg-up') {
        margin-top: 2.125rem;
      }
    }

    .FeaturedStoryTeaserWidget__title {
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;
      background-position: left 97%;

      @include media('sm-up') {
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.015625rem;
        background-position: left 98%;
      }

      @include media('lg-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
        background-position: left 93%;
      }
    }

    .FeaturedStoryTeaserWidget__dek-container {
      margin-top: 1.125rem;
      padding: 0;

      @include media('sm-up') {
        margin-top: 1.375rem;
      }

      @include media('md-up') {
        margin-top: 1.375rem;
        padding: 0 1.5rem;
      }

      @include media('lg-up') {
        margin-top: 1.1875rem;
      }
    }

    .FeaturedStoryTeaserWidget__dek {
      font-size: 1.125rem;

      @include media('lg-up') {
        font-size: 1.25rem;
      }
    }

    .FeaturedStoryTeaserWidget__authors {
      font-weight: 700;
      padding: 1.5625rem 0.9375rem 0;
      line-height: 1.125rem;

      .AuthorButtons__button-text {
        line-height: 1.125rem;
      }

      @include media('sm-up') {
        padding: 1rem 2.25rem 0 3rem;
      }

      @include media('md-up') {
        padding: 1rem 1.5rem 0;
      }

      @include media('lg-up') {
        padding-top: 1.0625rem;
      }
    }

    .FeaturedStoryTeaserWidget__intro-container {
      margin-top: 2.5rem;
      padding: 0 0.9375rem;

      @include media('sm-up') {
        margin-top: 2.75rem;
        padding: 0 2.25rem 0 3rem;
      }

      @include media('md-up') {
        margin-top: 2.6875rem;
        padding: 0 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__body-container {
      padding-top: 0.75rem;

      @include media('sm-up') {
        padding-top: 1.125rem;
      }

      @include media('md-up') {
        padding-top: 1.1875rem;
      }

      @include media('xl-up') {
        padding-top: 0.75rem;
      }
    }

    .FeaturedStoryTeaserWidget__continue-reading {
      margin-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 1.6875rem;
      }

      @include media('md-up') {
        padding-top: 1.5625rem;
      }

      @include media('lg-up') {
        padding-top: 1.3125rem;
      }
    }
  }

  &--medium,
  &--small {
    padding-bottom: 3rem;
    padding-right: 0px;

    @include media('sm-up') {
      padding-right: 1.5rem;
      padding-bottom: 4.5rem;
    }

    .FeaturedStoryTeaserWidget__section-button {
      padding-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 2rem;
      }
    }

    .FeaturedStoryTeaserWidget__title-container {
      margin-top: 1.5rem;
    }

    .FeaturedStoryTeaserWidget__dek-container {
      margin-top: 1rem;

      .FeaturedStoryTeaserWidget__dek {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__authors {
      padding-top: 1rem;
    }

    .FeaturedStoryTeaserWidget__intro-container {
      margin-top: 1.5rem;
      padding-right: 0.75rem;
      padding-left: 0.75rem;

      @include media('sm-up') {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        margin-top: 3rem;
      }
    }
  }

  &--xx-large,
  &--x-large {
    padding-bottom: 2.6875rem;

    @include media('sm-up') {
      padding-bottom: 4.3125rem;
    }

    @include media('md-up') {
      padding-bottom: 5.25rem;
    }

    .FeaturedStoryTeaserWidget__image {
      margin-top: 0.9375rem;

      @include media('sm-up') {
        margin-top: 0;
      }
    }

    .FeaturedStoryTeaserWidget__section-button {
      padding-top: 1.125rem;

      @include media('sm-up') {
        padding-top: 1.5625rem;
      }

      @include media('md-up') {
        padding-top: 2.1875rem;
      }
    }

    .FeaturedStoryTeaserWidget__title-container {
      margin-top: 1.25rem;

      @include media('sm-up') {
        margin-top: 2rem;
      }

      @include media('md-up') {
        margin-top: 2.125rem;
      }

      @include media('xl-up') {
        margin-top: 1.375rem;
      }
    }

    .FeaturedStoryTeaserWidget__title {
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;

      @include media('sm-up') {
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.015625rem;
      }

      @include media('md-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
        background-position: left 93%;
      }

      @include media('xl-up') {
        font-size: 4.5rem;
        line-height: 4.5rem;
        letter-spacing: -0.046875rem;
        background-position: left 91%;
      }
    }

    .FeaturedStoryTeaserWidget__dek-container {
      margin-top: 1.125rem;
      padding: 0 0.9375rem;

      @include media('sm-up') {
        margin-top: 1.375rem;
        padding: 0 2.25rem 0 3rem;
      }

      @include media('md-up') {
        margin-top: 0.75rem;
        padding: 0 1.5rem;
      }

      @include media('xl-up') {
        margin-top: 1.125rem;
      }
    }

    .FeaturedStoryTeaserWidget__dek {
      font-size: 1.125rem;
      line-height: 1.5rem;

      @include media('xl-up') {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__authors {
      padding: 1.5625rem 0.9375rem 0;

      @include media('sm-up') {
        padding: 1rem 2.25rem 0 3rem;
      }

      @include media('md-up') {
        padding: 1rem 1.5rem;
      }

      @include media('lg-up') {
        padding-top: 1.0625rem;
      }
    }

    .FeaturedStoryTeaserWidget__intro-container {
      margin-top: 2.5rem;
      padding: 0 0.9375rem;

      @include media('sm-up') {
        margin-top: 2.75rem;
        padding: 0 2.25rem 0 3rem;
      }

      @include media('md-up') {
        margin-top: 2.75rem;
        padding: 0 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__body-container {
      padding-top: 0.75rem;

      @include media('sm-up') {
        padding-top: 1.125rem;
      }

      @include media('md-up') {
        padding-top: 1.125rem;
      }

      @include media('xl-up') {
        padding-top: 0.75rem;
      }
    }

    .FeaturedStoryTeaserWidget__continue-reading {
      margin-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 1.6875rem;
      }

      @include media('md-up') {
        padding-top: 1.3125rem;
      }
    }
  }

  &--large,
  &--large-without-image {
    padding-bottom: 3rem;

    @include media('sm-up') {
      padding-bottom: 4.5rem;
    }

    .FeaturedStoryTeaserWidget__image {
      vertical-align: middle;
      margin-top: 0.9375rem;

      @include media('sm-up') {
        margin-top: 0;
      }
    }

    .FeaturedStoryTeaserWidget__section-button {
      padding-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 2rem;
      }
    }

    .FeaturedStoryTeaserWidget__title-container {
      margin-top: 1.5rem;
    }

    .FeaturedStoryTeaserWidget__title {
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;

      @include media('sm-up') {
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.015625rem;
      }

      @include media('md-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
        background-position: left 93%;
      }

      @include media('xl-up') {
        font-size: 4.5rem;
        line-height: 4.5rem;
        letter-spacing: -0.046875rem;
        background-position: left 91%;
      }
    }

    .FeaturedStoryTeaserWidget__dek-container {
      margin-top: 1rem;
      padding: 0 0.9375rem;

      @include media('sm-up') {
        padding: 0 2.25rem 0 3rem;
      }

      @include media('md-up') {
        padding: 0 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__dek {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .FeaturedStoryTeaserWidget__authors {
      font-weight: 700;
      padding: 1rem;
      .AuthorButtons__button-text,
      .byline {
        line-height: 1.125rem;
      }
    }

    .FeaturedStoryTeaserWidget__intro-container {
      margin-top: 0.5rem;
      padding: 0 0.9375rem;

      @include media('sm-up') {
        margin-top: 1rem;
        padding: 0 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__body-container {
      padding-top: 0.75rem;

      @include media('sm-up') {
        padding-top: 1.125rem;
      }

      @include media('md-up') {
        padding-top: 1.125rem;
      }

      @include media('xl-up') {
        padding-top: 0.75rem;
      }
    }

    .FeaturedStoryTeaserWidget__continue-reading {
      margin-top: 1.5rem;

      @include media('sm-up') {
        padding-top: 1.6875rem;
      }

      @include media('md-up') {
        padding-top: 1.3125rem;
      }
    }
  }

  &--large-without-image {
    padding-top: 0.5rem;

    @include media('sm-up') {
      padding-top: 0;
    }
  }

  &__body-container {
    > p:nth-child(1)::first-letter {
      @include dropcaps();
      font-family: $itc-cushing;
      padding-top: 1.7rem;
    }

    &:hover {
      .FeaturedStoryTeaserWidget__continue-reading {
        text-decoration-color: color('transparent');
      }
    }
  }

  &__body-container--two-column {
    @include media('sm-up') {
      overflow: hidden;
      column-count: 2;
      column-width: auto;
      column-gap: 3rem;

      &.fiction-teaser-border::after {
        background-image: linear-gradient(
          color('fiction-pink') 24%,
          rgba(255, 255, 255, 0) 0%
        );
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        margin-top: 1rem;
        height: 100%;
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }
    }
  }

  &__body-container--three-column {
    @include media('sm-up') {
      column-count: 2;
      column-gap: 3rem;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        margin-top: 1rem;
        height: 100%;
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }
    }

    @include media('lg-up') {
      overflow: hidden;
      column-count: 3;
      column-gap: 3rem;
      column-width: auto;
    }
  }

  &__body-container--three-column::before {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(33.333% - 0.5rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--three-column {
    @include media('lg-up') {
      &.fiction-teaser-border::after {
        background-image: linear-gradient(
          color('fiction-pink') 24%,
          rgba(255, 255, 255, 0) 0%
        );
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(66.666% + 0.5rem);
        width: 1px;
        margin-top: 1rem;
        height: 100%;
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }
    }
  }

  &__body-container--four-column {
    @include media('lg-up') {
      overflow: hidden;
      column-count: 4;
      column-gap: 3rem;
      column-width: auto;
    }
  }

  &__body-container--four-column::before {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(25% - 0.75rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--four-column {
    @include media('lg-up') {
      &.fiction-teaser-border::after {
        background-image: linear-gradient(
          color('fiction-pink') 24%,
          rgba(255, 255, 255, 0) 0%
        );
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(50% - 0.25rem);
        width: 1px;
        margin-top: 1rem;
        height: 100%;
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }
    }
  }

  &__continue-reading {
    @extend .transition-short;
    text-decoration: underline;
    text-decoration-color: color('black');
  }

  &--split-layout {
    @media (max-width: 767px) {
      padding-bottom: 0.625rem;
    }

    .FeaturedStoryTeaserWidget__image {
      @media (max-width: 767px) {
        margin-top: 0.9375rem;
      }
    }

    .FeaturedStoryTeaserWidget__section-button {
      @media (max-width: 767px) {
        padding-top: 1.25rem;
      }
    }

    .FeaturedStoryTeaserWidget__title-container {
      @media (max-width: 767px) {
        margin-top: 1.4375rem;
      }
    }

    .FeaturedStoryTeaserWidget__title {
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .FeaturedStoryTeaserWidget__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .FeaturedStoryTeaserWidget__authors {
      @media (max-width: 767px) {
        padding-top: 1.375rem;
      }
    }
  }
}
