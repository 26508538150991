.collections-6-stories-widget__article1-container {
  .teaser-widget-container {
    padding: 0;
    margin: 0;
    border: none;
  }
}

.collections-6-stories-widget {
  @include media('lg-up') {
    margin-top: 1.5rem;
    column-gap: 0.75rem;
  }

  &__container {
    padding: 1rem;

    @include media('sm-up') {
      padding: 1.5rem;
    }
  }

  @include media('lg-up') {
    display: grid;
  }

  .teaser-widget-container {
    padding: 0;
    margin: 0;
    border: none;
  }

  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, auto));
  }

  &__article1-container {
    grid-column: 1/2;
    grid-row: 1/2;
    padding-top: 1.5rem;
    border-top: $border-black-dotted;
    padding-bottom: 4rem;

    @include media('sm-up') {
      padding-bottom: 6rem;
    }

    @include media('lg-up') {
      margin-top: 0.75rem;
    }
  }

  &__article2-container {
    grid-column: 1/2;
    grid-row: 2/3;
    border-bottom: $border-black-dotted;
    padding-bottom: 4rem;
    position: relative;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 2/3;
      padding-bottom: 6rem;
      padding-top: 1.5rem;
    }

    @include media('lg-up') {
      padding-right: 0.75rem;

      &::after {
        content: '';
        border-right: $border-black-dotted;
        position: absolute;
        right: 0%;
        top: 1.5rem;
        height: calc(100% - 3rem);
      }
    }

    @include media('lg-up') {
      grid-column: 3/4;
      grid-row: 2/3;
      border-bottom: none;
      padding-bottom: 3rem;

      &::after {
        content: '';
        border-right: $border-black-dotted;
        position: absolute;
        right: 0%;
        top: 1.5rem;
        height: calc(100% - 1.5rem);
      }
    }
  }

  &__article3-container {
    grid-column: 1/2;
    grid-row: 3/4;
    position: relative;

    @include media('md-up') {
      grid-column: 3/4;
      grid-row: 2/3;
      padding-top: 1.5rem;
      padding-left: 0.75rem;

      &::after {
        border-bottom: $border-black-dotted;
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% + 0.75rem);
        height: calc(100% - 3rem);
      }
    }

    @include media('lg-up') {
      grid-column: 4/5;
      grid-row: 2/3;
      border-bottom: none;
      padding-left: 0;
      padding-bottom: 3rem;

      &::after {
        border-bottom: none;
      }
    }
  }

  &__article4-container {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  &__article6-container {
    display: none;
    padding-bottom: 3rem;
    border-bottom: $border-black-dotted;

    @include media('lg-up') {
      display: block;
      grid-column: 3/5;
      grid-row: 1/1;
    }
  }

  &__article3-article4-container {
    > div {
      padding-bottom: 4rem;
      border-bottom: $border-black-dotted;
      padding-top: 1rem;
    }
  }

  &__article5-article6-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.875rem;

    border-bottom: $border-black-dotted;
    padding-bottom: 1rem;
    margin-top: 1rem;
    padding-bottom: 4rem;

    @include media('xs-down') {
      grid-gap: 1.5rem;
    }

    &::after {
      content: '';
      border-right: $border-black-dotted;
      position: absolute;
      left: 50%;
      height: calc(100% - 1rem);
    }

    @include media('lg-up') {
      border-bottom: none;
      &::after {
        display: none;
      }
    }
  }

  &__article3-article4-container {
    grid-column: 1/2;
    grid-row: 3/4;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  &__article5-article6-container {
    grid-column: 1/2;
    grid-row: 5/6;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 3/4;
    }
  }

  &__article3-article4-article5-article6-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.5rem;
    grid-column: 1/3;
    margin-bottom: 1.5rem;

    > div:nth-child(1) {
      grid-column: 1/2;
      grid-row: 1/2;
      margin-top: 1.5rem;
      padding-bottom: 3rem;

      &::after {
        content: '';
        border-right: $border-black-dotted;
        position: absolute;
        left: 50%;
        height: calc(100% - 1.5rem);
      }
    }
    > div:nth-child(2) {
      grid-column: 2/3;
      grid-row: 1/2;
      margin-top: 1.5rem;
      padding-bottom: 3rem;
    }
    > div:nth-child(3) {
      grid-column: 1/2;
      grid-row: 2/3;
      padding-top: 1.5rem;
      padding-bottom: 3rem;
      border-top: $border-black-dotted;
    }
    > div:nth-child(4) {
      grid-column: 2/3;
      grid-row: 2/3;
      padding-bottom: 3rem;
      margin-top: 1.5rem;
      border-top: $border-black-dotted;
      padding-top: 1.5rem;
    }
  }

  &__article4-article5-article6-container {
    @include media('lg-up') {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: reapeat(1, auto);

      grid-column: 1/4;
      grid-row: 3/4;
      padding-top: 1.5rem;

      > div:nth-child(1) {
        padding-right: 0.75rem;
        padding-bottom: 3rem;
      }
      > div:nth-child(2) {
        border-left: $border-black-dotted;
        padding-left: 0.75rem;
        padding-bottom: 3rem;

        @include media('lg-up') {
          border-top: $border-black-dotted;
          padding-left: 0;
          margin-right: 0.75rem;
          border-left: 0;
        }
      }
      > div:nth-child(3) {
        border-left: $border-black-dotted;
        padding-left: 0.75rem;
        padding-bottom: 3rem;

        @include media('lg-up') {
          border-top: $border-black-dotted;
          padding-left: 0;
          margin-right: 0.75rem;
          border-left: 0;
        }
      }
    }

    @include media('lg-up') {
      display: flex;
      grid-column: 1/3;
      grid-row: 1/3;
      border-right: $border-black-dotted;
      padding-top: 0;

      > div:nth-child(2) {
        padding-top: 1.5rem;
      }
      > div:nth-child(3) {
        padding-top: 1.5rem;
      }
    }
  }

  &__article5-article6-container {
    border-bottom: none;
  }
}
