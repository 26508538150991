.HolidayHeader {
  color: color('beige');
  margin-top: -33px;
  z-index: 2;
  position: relative;

  @include media('sm-up') {
    margin-top: -40px;
  }

  @include media('md-up') {
    margin-top: -45px;
  }

  @include media('lg-up') {
    margin-top: -62px;
  }

  @include media('xl-up') {
    margin-top: -82px;
  }

  &__bottom {
    background-color: color('bronze');
    padding: 30px 10px 22px;
    position: relative;
    margin-bottom: 7px;

    @include media('sm-up') {
      padding: 20px 10px 14px;
    }

    @include media('lg-up') {
      padding: 26px 10px 22px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      left: 0;
      background-color: color('bronze');
      top: calc(100% + 2.5px);
      @include media('lg-up') {
        height: 4px;
        top: calc(100% + 3.5px);
      }
    }
  }
  &__illustration {
    margin-bottom: 4px;

    @include media('sm-up') {
      margin-bottom: 24px;
    }
    img {
      max-width: 100%;
      height: auto;
    }

    .HolidayHeader__illustration-image + .HolidayHeader__illustration-image {
      display: none;
    }

    @include media('md-up') {
      .HolidayHeader__illustration-image:first-child {
        display: none;
      }
      .HolidayHeader__illustration-image:only-child,
      .HolidayHeader__illustration-image + .HolidayHeader__illustration-image {
        display: block;
      }
    }
  }

  .Hero__credit {
    text-align: right;
    margin-top: 10px;
  }
  svg {
    height: 100%;
  }

  &__crest-container {
    display: grid;
    height: 67px;
    grid-template-columns: 1fr 119px 119px 1fr;
    @include media('sm-up') {
      grid-template-columns: 1fr 119px auto 119px 1fr;
    }

    @include media('lg-up') {
      grid-template-columns: 1fr 171px auto 171px 1fr;
      height: 96px;
    }
  }

  &__crest-border {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &__border-left {
    background-image: url('../../views/HolidayView/svg/holiday-border-left.svg');
    background-repeat: repeat-x;
  }

  &__crest-left {
    background-image: url('../../views/HolidayView/svg/holiday-crest-left.svg');
  }

  &__crest-center {
    background-image: url('../../views/HolidayView/svg/holiday-crest-center.svg');
    background-repeat: repeat-x;
  }

  &__crest-right {
    background-image: url('../../views/HolidayView/svg/holiday-crest-right.svg');
  }

  &__border-right {
    background-image: url('../../views/HolidayView/svg/holiday-border-right.svg');
    background-repeat: repeat-x;
  }

  &__crest-center-container {
    display: none;
    @include media('sm-up') {
      display: flex;
    }

    position: relative;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  &__crest-center {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title--mobile {
    font-size: 36px;
    line-height: 1.12em;
    text-align: center;
    margin: -60px auto 12px;
    max-width: 230px;

    @include media('md-up') {
      max-width: 260px;
    }

    @include media('sm-up') {
      display: none;
    }
  }

  &__title {
    position: relative;
    z-index: 10;
    margin-left: -2em;
    margin-right: -2em;
    margin-bottom: -0.2rem;
    font-size: 36px;
    line-height: 1.12em;

    @include media('sm-up') {
      margin-left: -1.5em;
      margin-right: -1.5em;
    }

    @include media('lg-up') {
      font-size: 48px;
    }
  }

  &__crest-border {
    img {
      height: 100%;
    }
  }

  .dot {
    margin: 0 0.5em;
  }

  .third-dot {
    display: none;
    @include media('md-up') {
      display: block;
    }
  }

  &__holidayInfo {
    flex-wrap: wrap;
    font-size: 16px;

    @include media('md-up') {
      font-size: 1.125rem;
      flex-wrap: auto;
    }
  }

  .HolidayCountdown {
    flex-basis: 100%;

    @include media('md-up') {
      flex-basis: auto;
    }
  }
}
