.RecipeFilter {
  position: relative;
  pointer-events: none;

  &__header {
    width: 100%;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: none;

    @include media('md-up') {
      height: 50px;
    }
  }

  &__openButton {
    background: none;
    border: none;
    cursor: pointer;
    pointer-events: initial;
    font-size: 18px;
    padding: 0 7px 0 0;

    &:after {
      content: '';
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
      margin-left: 0.2em;
      border-right: 1.5px solid;
      border-bottom: 1.5px solid;
      transition: 0.3s;
      transform-origin: 70% 70%;
      transform: rotate(45deg) translateY(-3px);
    }
  }

  &--open {
    .RecipeFilter__openButton:after {
      transform: rotate(-135deg) translateY(2px);
    }
  }

  &__menu {
    pointer-events: initial;
    padding-top: 30px;
    padding-bottom: 60px;
    display: flex;
  }

  &__filter {
    width: 300px;

    & + & {
      margin-left: 20px;
    }
  }

  &__filterLabel {
    font-size: 14px;
    padding-bottom: 6px;
    border-bottom: 1px solid;
    letter-spacing: 0.1em;
  }

  .FilterButton {
    background-color: transparent;
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: 18px;
    height: 48px;
    @extend %dotted-border-bottom-black;

    &--active {
      color: color('red');

      .FilterCheckbox {
        display: block;
      }
    }
  }

  &__filterLabel,
  .FilterButton {
    padding-left: 40px;
    padding-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .FilterCheckbox {
    display: none;
    position: absolute;
    left: 15px;
    top: 45%;
    transform: translateY(-50%) rotate(45deg);
    width: 8px;
    height: 15px;
    border-right: 1.5px solid;
    border-bottom: 1.5px solid;

    @include media('lg-up') {
      border-right: 2px solid;
      border-bottom: 2px solid;
    }
  }
}
