@mixin narrow-font-xl-lg() {
  font-size: 1.75rem;
  line-height: 1.875rem;
}

@mixin narrow-font-m-s-xs() {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

// Layout A > {Regular, Regular + Image} > M breakpoint
.LayoutA .non-main-teaser {
  .TextStoryTeaserWidget--medium .TextStoryTeaserWidget__title,
  .ImageTextStoryTeaserWidget--small .ImageTextStoryTeaserWidget__title-button {
    @media (min-width: map-get($breakpoints, 'md')) and (max-width: map-get($breakpoints, 'lg')) {
      @include narrow-font-m-s-xs();
    }
  }
}

// Layout B > {Regular, Regular + Image} > XS breakpoint
.LayoutB .non-main-teaser,
// Layout C > {Regular, Regular + Image} > XS breakpoint
.LayoutC .non-main-teaser,
// Layout I > {Regular, Regular + Image} > XS breakpoint
.LayoutI .non-main-teaser {
  .TextStoryTeaserWidget--medium .TextStoryTeaserWidget__title,
  .ImageTextStoryTeaserWidget--small .ImageTextStoryTeaserWidget__title-button {
    @media (max-width: map-get($breakpoints, 'sm')) {
      @include narrow-font-m-s-xs();
    }
  }
}

// Layout G > {Regular, Regular + Image} > XL, L, and XS breakpoint
.LayoutG .non-main-teaser {
  .TextStoryTeaserWidget--medium .TextStoryTeaserWidget__title,
  .ImageTextStoryTeaserWidget--small .ImageTextStoryTeaserWidget__title-button {
    @media (max-width: map-get($breakpoints, 'sm')) {
      @include narrow-font-m-s-xs();
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
      @include narrow-font-xl-lg();
    }
  }
}

// Layout H > {Regular, Regular + Image} > XL, L, M and XS breakpoint
// but using XL, L and XS breakpoint.
.LayoutH .non-main-teaser {
  .TextStoryTeaserWidget--medium .TextStoryTeaserWidget__title,
  .ImageTextStoryTeaserWidget--small .ImageTextStoryTeaserWidget__title-button {
    @media (max-width: map-get($breakpoints, 'sm')) {
      @include narrow-font-m-s-xs();
    }

    @media (min-width: map-get($breakpoints, 'md')) and (max-width: map-get($breakpoints, 'lg')) {
      @include narrow-font-m-s-xs();
    }

    @media (min-width: map-get($breakpoints, 'lg')) {
      @include narrow-font-xl-lg();
    }
  }
}

// Layout L > {Regular, Regular + Image} > S breakpoint
.LayoutL .layout-story-container {
  .TextStoryTeaserWidget--medium .TextStoryTeaserWidget__title,
  .ImageTextStoryTeaserWidget--small .ImageTextStoryTeaserWidget__title-button {
    @media (min-width: map-get($breakpoints, 'sm')) and (max-width: map-get($breakpoints, 'md')) {
      @include narrow-font-m-s-xs();
    }
  }
}
