.ColumnsPageGridItems {
  &__container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    row-gap: 1rem;

    @include media('xs-down') {
      padding-top: 1rem;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__row {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding-bottom: 1rem;
      border-bottom: $border-black;

      .ImageTextStoryTeaserWidget {
        padding-top: 0;
      }

      > div:nth-child(1) {
        padding-right: 0.5rem;
        border-right: $border-black;
      }
      > div:nth-child(2) {
        padding-left: 0.5rem;
      }
    }

    .odd-col {
      @include media('xs-down') {
        border-top: $border-black;
        padding-left: 0.5rem;
      }
    }

    .even-col {
      @include media('xs-down') {
        padding-right: 0.5rem;
        position: relative;
        border-top: $border-black;

        &::after {
          content: '';
          border-right: $border-black;
          position: absolute;
          right: 0rem;
          top: 1rem;
          height: calc(100% - 1rem);
        }
      }
    }

    @include media('sm-up') {
      grid-gap: 1.5rem;
      padding-top: 1.5rem;
    }

    @include media('md-up') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media('lg-up') {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  &__container.first-row {
    @include media('lg-up') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
