.EmbedMedia {
  &.large,
  &.medium {
    border-bottom: $border-black;
    border-top: $border-black;
    margin-bottom: 1rem;
    padding: 0.875rem 0 3.75rem;

    @include media('md-up') {
      margin-bottom: 1.875rem;
      padding: 1.5rem 0 7.5rem;
    }
  }

  &.small-left,
  &.small-right {
    border-bottom: $border-black;
    padding-bottom: 8rem;
  }

  &.small-left {
    border-right: $border-black;
    padding-right: 1.5rem;
    margin-right: 1.5rem;
  }

  &.small-right {
    border-left: $border-black;
    padding-left: 1.5rem;
    margin-left: 1.5rem;
  }

  &--twitter {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__media {
    overflow: hidden;

    & > div {
      width: 100%;
    }
    .twitter-tweet {
      margin: auto;
    }

    & > iframe {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;

      &.instagram-media {
        position: relative;
      }

      body {
        overflow: hidden;
      }
    }

    &--active {
      overflow: hidden;
      -ms-overflow-style: none; /* Edge */
      scrollbar-width: none; /* Firefox */

      /* Chrome, Safari */
      &::-webkit-scrollbar {
        display: none;
      }

      & > iframe {
        width: 102%;

        &:not(.instagram-media) {
          height: 100%;
          overflow: hidden;
          -ms-overflow-style: none; /* Edge */
          scrollbar-width: none; /* Firefox */

          /* Chrome, Safari */
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}
