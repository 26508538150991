.ArticleImgCarousel {
  opacity: 0;
  transition: all $transition-duration-medium $transition-easing;
  visibility: hidden;

  &--active {
    opacity: 1;
    visibility: visible;
  }

  $carousel-arrow-width: 30px;

  &__carousel {
    height: 95vh;
    position: relative;
    width: 80vw;

    &__cell {
      height: 95vh;
    }

    &__content-wrapper {
      // Give the previous/next arrows some space
      max-width: calc(100% - (#{$carousel-arrow-width} * 3));
    }

    &__image {
      display: block;
      max-height: 90vh;
      max-width: 100%;
      width: auto;

      &__caption {
        color: color('white');
      }

      &__caption-container {
        // Keep line lengths reasonable for human eyeballs
        max-width: 70ch;
      }
    }

    // Styles for elements injected by Flickity
    .flickity {
      &-page-dots {
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
      }

      &-prev-next-button {
        background: transparent;
        bottom: 0;
        height: $carousel-arrow-width;
        margin: auto;
        position: absolute;
        top: 0;
        width: $carousel-arrow-width;

        &.previous {
          left: 0;
        }

        &.next {
          right: 0;
        }
      }

      &-button {
        background: transparent;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;

          .flickity-button-icon {
            fill: color('gray-darker');
          }
        }
      }

      &-button-icon {
        fill: white;
        width: auto;
      }

      &-viewport {
        height: 100%;
        position: relative;
        width: 100%;
      }
    }

    .dot {
      background: color('transparent');
      border-radius: 50%;
      border: 1px solid white;
      cursor: pointer;
      display: inline-block;
      height: 10px;
      margin: 1.5rem 2px 0;
      width: 10px;

      &.is-selected {
        background: color('white');
      }
    }
  }
}
