//------FONTS------//

$bradford: 'BradfordLL', serif;
$graebenbach: 'Graebenbach', sans-serif;
$itc-cushing: 'ItcCushing', serif;

.bradford {
  font-family: $bradford;
}

.graebenbach {
  font-family: $graebenbach;
}

.itc-cushing {
  font-family: $itc-cushing;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

.super {
  margin-top: -1.5rem;
}
//------FONT WEIGHT------//

.font-800 {
  font-weight: 800;
}

// bold
.font-700 {
  font-weight: 700;
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

// normal
.font-400 {
  font-weight: 400;
}

.font-300 {
  font-weight: 300;
}

.font-200 {
  font-weight: 200;
}

//------LETTER SPACING------//

.letter-spacing-md {
  letter-spacing: 0.0625rem;
}

.letter-spacing-sm {
  letter-spacing: 0.05rem;
}

//------LINE HEIGHT------//

.line-height-xl {
  line-height: 2.5rem;
}

.line-height-lg {
  line-height: 2.25rem;
}

.line-height-md {
  line-height: 2rem;
}

.line-height-sm {
  line-height: 1.5rem;
}

.line-height-xs {
  line-height: 1.25rem;
}

.line-height-xxs {
  line-height: 1rem;
}

//------GLOBAL TYPOGRAPHY------//

.text-global-title-xl {
  font-size: 3.75rem;
  line-height: 3rem;
  letter-spacing: -0.03125rem;
}

.text-global-title-sm {
  font-size: 1.5rem;
  line-height: 3rem;
}

.text-global-body-xl,
%text-global-body-xl {
  font-size: 2.25rem;
  line-height: 3rem;
}

.text-global-details-xxs {
  font-size: 0.875rem;
  letter-spacing: 0.075rem;
}

.text-global-header,
%text-global-header {
  font-size: 3rem;
  line-height: 3rem;

  @include media('lg') {
    font-size: 3.75rem;
  }
}

//------ARTICLE TYPOGRAPHY------//

// HEADER

.text-article-header-lg {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.03125rem;

  @include media('lg-up') {
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 3.75rem;
    letter-spacing: -0.03125rem;
  }
}

.text-article-header-md {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-article-header-sm {
  font-size: 1.25rem;
  line-height: 2.25rem;
}

// BODY

.text-article-body-lg {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

.article-content-container {
  p a,
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    @extend %linked-border-bottom-black;
    color: inherit;
    text-decoration: none;
  }

  > div > div > p sub {
    line-height: 0.8rem;
    font-size: 0.8rem;
    vertical-align: sub;
    text-decoration: none;
  }

  > div > div > p sup {
    line-height: 0.8rem;
    font-size: 0.8rem;
    vertical-align: super;
    text-decoration: none;
  }

  .ImageCreditLink a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(color('red'), color('red'));
    background-repeat: no-repeat;
    background-size: 0% 1.75px;
    transition: background-size $transition-duration-short;
    background-position: left 100%;

    &:hover {
      background-size: 100% 1.75px;
    }
  }
}

.text-article-body-md {
  font-size: 1.25rem;
  line-height: 1.875rem;

  @include media('md-up') {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  @include media('xl-up') {
    font-size: 1.75rem;
    line-height: 2.625rem;
  }

  h2 {
    @extend .text-article-header-md;
    font-family: $itc-cushing;
    font-weight: 600;
    margin-top: 5rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    @extend .text-article-header-sm;
    font-family: $itc-cushing;
    font-weight: 600;
    margin-top: 2.25rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }

  li {
    font-family: $bradford;
    font-weight: 300;
    transform: translateX(2rem);
    width: calc(100% - 2rem);
    margin-bottom: 1.25rem;

    @include media('lg-up') {
      margin-bottom: 1.5rem;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
    font-weight: 300;
    a {
      @extend %linked-border-bottom-black;
      color: inherit;
      text-decoration: none;

      @include media('xl-up') {
        background-position: right 96% !important;
      }
    }
  }

  a.InPageAnchor {
    position: absolute;
    visibility: hidden;
    margin-top: -80px;
  }

  .NewsletterWidget__text p {
    margin-bottom: 0;
  }

  blockquote {
    color: color('gray-darker');
    margin-bottom: 1.5rem;
    width: calc(100% - #{$block-quote-spacing-mobile});
    margin-left: $block-quote-spacing-mobile;

    &.Blockquote--italic {
      font-style: italic;
    }

    a {
      @extend %linked-border-bottom-black;
      color: inherit;
      text-decoration: none;
    }

    @include media('md-up') {
      width: calc(100% - #{$block-quote-spacing-desktop});
      margin-left: $block-quote-spacing-desktop;
    }
  }
}

// DETAILS

.text-article-details-lg {
  font-size: 1.875rem;
  line-height: 2rem;
}

.text-article-details-md {
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
}

.text-article-details-sm,
%text-article-details-sm {
  font-size: 1.125rem;
  letter-spacing: -0.003125rem;
  line-height: 1.5rem;
}

.text-article-details-xs,
%text-article-details-xs {
  font-size: 1rem;
  line-height: 1.25rem;
}

.text-article-details-xxs,
%text-article-details-xxs {
  font-size: 0.875rem;
  letter-spacing: 0.03125rem;
}

.text-article-details-xxxs {
  font-size: 0.75rem;
}

// DROPCAPS
@mixin dropcaps {
  float: left;
  font-family: $itc-cushing;
  font-size: 6rem;
  line-height: 2.6rem;
  padding-bottom: 2rem;
  padding-right: 2.625rem;
  padding-top: 2.2rem;

  @include media('lg-up') {
    font-size: 7.25rem;
    line-height: 3.75rem;
    padding-right: 5rem;
  }
}

.text-article-dropcaps {
  > p:nth-child(1)::first-letter {
    @include dropcaps;
    font-family: $itc-cushing;
  }

  > p:first-of-type::first-line {
    font-variant: small-caps;
  }
}

.text-article-dropcaps-body-view {
  > p:nth-child(1)::first-letter {
    @include dropcaps;
    font-family: $itc-cushing;

    @include media('md-up') {
      padding-top: 2.3rem;
    }
  }

  @include media('md-up') {
    > p:first-of-type::first-line {
      font-variant: small-caps;
    }
  }
}

.text-layout-dropcaps {
  &::first-letter {
    float: left;
    font-family: $itc-cushing;
    font-weight: 300;
    padding: 2.25rem 1rem 1.9375rem 0;
    font-size: 5.625rem;

    @include media('md-up') {
      padding: 2.25rem 2.5625rem 3.5rem 0;
      font-size: 5.25rem;
    }
  }
}

//------SECTION TYPOGRAPHY------//

// HEADER

.text-section-header-xl {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.015625rem;

  @include media('md-up') {
    letter-spacing: -0.0375rem;
  }

  @include media('lg-up') {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
}

.text-section-header-lg,
%text-section-header-lg {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.0375rem;

  @include media('lg-up') {
    font-size: 3.75rem;
    line-height: 3.75rem;
    letter-spacing: -0.03125rem;
  }
}

.text-section-header-md {
  font-size: 2.25rem;
}

.text-section-header-sm {
  font-size: 1.875rem;
  line-height: 2rem;

  @include media('md-up') {
    font-size: 1.75rem;
    line-height: 1.875rem;
  }

  @include media('lg-up') {
    font-size: 1.875rem;
    line-height: 2rem;
  }
}

.text-section-header-xs {
  font-size: 1.75rem;
  line-height: 1.875rem;
}

// TITLE

.text-section-title-xxxl {
  font-size: 6rem;

  @include media('lg-up') {
    font-size: 7.5rem;
  }
}

.text-section-title-xxl {
  font-size: 6rem;
  line-height: 7.5rem;
  letter-spacing: -0.133125rem;
}

.text-section-title-xl {
  font-size: 4.5rem;
  line-height: 4.5rem;
  letter-spacing: -0.125rem;
}

.text-section-title-lg {
  font-size: 2.25rem;
}

.text-section-title-md {
  font-size: 1.875rem;
  line-height: 2rem;
}

.text-section-title-sm {
  font-size: 1.5rem;
  line-height: 1.25rem;
  letter-spacing: 0.0625rem;
}

.text-section-title-xs {
  font-size: 1.25rem;
  letter-spacing: 0.0625rem;
}

.text-section-title-xxs {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

// BODY

.text-section-body-lg {
  font-size: 1.5rem;
  line-height: 1.75rem;

  @include media('lg-up') {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.text-section-body-md {
  font-size: 1.5rem;
}

.text-section-body-sm {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.text-section-body-xs,
%text-section-body-xs {
  font-size: 1rem;
  line-height: 1.25rem;
}

// DETAILS

.text-section-details-sm {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.text-section-details-xs {
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
}

.text-section-details-xxs {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  letter-spacing: 0.058125rem;
}

.widget-portable-text {
  > div {
    > p {
      margin-bottom: 1rem;
    }

    > p:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    @extend %linked-border-bottom-red;
    color: inherit;
    text-decoration: none;
  }
}

.portable-text {
  a {
    @extend %linked-border-bottom-black;
    color: color('black');
    text-decoration: none;
  }
}

// PODCAST
.podcast-title-xlg {
  font-size: 4.5rem;
  letter-spacing: -0.046875rem;
  line-height: 4.5rem;
}

.podcast-title-lg {
  font-size: 3rem;

  @include media('md-up') {
    font-size: 3.75rem;
  }
}

.podcast-title-md {
  font-size: 3rem;
  line-height: 3rem;
}

.podcast-title-sm {
  font-size: 1.875rem;
  line-height: 2rem;
}

.podcast-description-md,
%podcast-description-md {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.podcast-episode-body {
  font-size: 1.25rem;
  line-height: 1.875rem;

  > div > div * a {
    @extend %linked-border-bottom-sm-white-dark;
    text-decoration: none;
    color: inherit;
  }

  @include media('lg-up') {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
}

.podcast-detail-lg {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.podcast-detail-md {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.0625rem;
}

.podcast-detail-sm {
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  line-height: 1.5rem;
}

//section nav font
.section-text-section-title-xxxl {
  font-size: 6rem;
}

.section-text-section-header-lg {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.0375rem;
}

.section-text-section-header-xl {
  font-size: 3rem;
  letter-spacing: -0.015625rem;
}

.section-text-section-header-lg {
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.0375rem;
}

.default-letter-spacing {
  letter-spacing: 0;
}
