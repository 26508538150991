.LayoutHero4 {
  &__banner-container {
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &__image {
    @include media('md-up') {
      height: 100%;
    }
  }

  &__story-container {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(3, minmax(19.875rem, 1fr));
    width: calc(100% + 1.5rem);
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    .fiction-view {
      padding: 1rem;
    }

    @include media('md-up') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__middle-story {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -1.5rem;
      width: 1px;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -1.5rem;
      width: 1px;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }
}
