.SectionsCarousel {
  transition: all $transition-duration-medium $transition-easing;

  &__sidebar-container {
    min-width: 100vw;
    max-width: 100vw;
  }

  &__buttons-container {
    @include media('sm-down') {
      width: 100%;
    }
  }

  &__mock-bar {
    width: $bar-hover-width;

    &--right {
      transform: translate3d(calc(100vw - #{$bar-hover-width}), 0, 0);
    }
  }

  &__section-button {
    @extend .transition;
    width: $bar-width;

    &--right {
      transform: translate3d(calc(100vw - #{$bar-width}), 0, 0);
      box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translate3d(
          calc(100vw - #{$bar-width} - #{$bar-hover-width}),
          0,
          0
        );
      }
    }

    &--left {
      box-shadow: 10px 10px 20px -10px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translate3d($bar-hover-width, 0, 0);
      }
    }
  }

  &__section-button-image {
    height: 100%;
    max-height: 1rem;

    &--left {
      transform: rotate(270deg);
    }

    &--right {
      transform: rotate(90deg);
    }
  }

  &__section-button-image--first {
    transform: rotate(0deg);
    width: 1.5rem;
    height: 1.5rem;
    max-height: 1.5rem;
  }
}
