.LayoutH {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(3, auto);
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  @include media('md-up') {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: repeat(2, auto);
  }

  @include media('lg-up') {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-template-rows: repeat(2, minmax(0, auto));
  }

  &__article1-container {
    grid-column: 1/3;
    grid-row: 1/2;

    @include media('sm-up') {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    @include media('md-up') {
      grid-column: 1/5;
      grid-row: 1/2;
    }

    @include media('lg-up') {
      grid-column: 3/7;
      grid-row: 1/3;
    }
  }

  &__article2-container {
    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    @include media('md-up') {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  &__article3-container {
    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    @include media('md-up') {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  &__article4-container {
    grid-column: 1/3;
    grid-row: 3/4;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 2/4;
      border-bottom: initial;
    }

    @include media('md-up') {
      grid-column: 3/5;
      grid-row: 2/3;
    }

    @include media('lg-up') {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }

  &__article2-article3-container {
    grid-column: 1/3;
    grid-row: 2/3;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto;
    grid-gap: 1.875rem;

    border-bottom: $border-black;
    padding-bottom: 1rem;

    &::after {
      content: '';
      border-right: $border-black;
      position: absolute;
      left: 50%;
      top: 0.9375rem;
      height: calc(100% - 1rem - 0.9375rem);
    }
    @include media('sm-up') {
      display: none;
    }
  }
}
