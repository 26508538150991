.fiction-tag-container {
  width: fit-content;
  background-color: color('fiction-pink');
  padding: 7px 8px 4px 8px;

  .fiction-tag-text {
    display: block;
    color: color('fiction-blue');
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;

    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
