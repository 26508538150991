$transition-duration-shorter: 0.125s;
$transition-duration-short: 0.3s;
$transition-duration-medium: 0.5s;
$transition-duration-long: 1s;

$transition-easing: ease-in-out;
$transition: $transition-duration-short $transition-easing;

.transition-shorter {
  transition: all $transition-duration-shorter $transition-easing;
}

.transition-short,
%transition-short {
  transition: all $transition-duration-short $transition-easing;
}

.transition,
%transition {
  transition: all $transition-duration-medium $transition-easing;
}

.transition-long {
  transition: all $transition-duration-long $transition-easing;
}

$transition-animate-disappear-width: animate-disappear-width
  $transition-duration-short;

@keyframes animate-disappear-width {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

$transition-animate-appear-width: animate-appear-width
  $transition-duration-short;

@keyframes animate-appear-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

$transition-animate-appear-from-bottom: animate-appear-from-bottom
  $transition-duration-medium;

@keyframes animate-appear-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

$transition-animate-disappear-to-bottom: animate-disappear-to-bottom
  $transition-duration-medium;

@keyframes animate-disappear-to-bottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(1.5rem);
  }
}

$transition-animate-appear-from-top: animate-appear-from-top 0.75s;

@keyframes animate-appear-from-top {
  0% {
    opacity: 0;
    transform: translateY(1.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

$transition-animate-disappear-to-top: animate-disappear-to-top
  $transition-duration-medium;

@keyframes animate-disappear-to-top {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
}

$transition-fade-in: fade-in $transition-duration-medium $transition-easing;

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

$line-placement: 85%;

@keyframes pseudo-border {
  0% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  10% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.1) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  20% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.2) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  30% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.3) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  40% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.4) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  50% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.5) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  60% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.6) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  70% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.7) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  80% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.8) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  90% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 0.9) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
  100% {
    background: linear-gradient(
      to bottom,
      transparent $line-placement,
      rgba(221, 52, 24, 1) calc(#{$line-placement} + 1px),
      transparent calc(#{$line-placement} + 2px),
      transparent 100%
    );
  }
}
