.AuthorBioBlock {
  &__container {
    border: $border-thick-red;
    padding: 1.125rem;

    > p {
      > a,
      > em > a,
      > strong > a {
        @extend %linked-border-bottom-red;
        color: inherit;
        text-decoration: none;
      }
    }

    @include media('sm-down') {
      font-size: 1.125rem;
    }

    @include media('md-up') {
      padding: 1.25rem 1.5rem;
    }
  }
}
