.RecipesView {
  padding-top: 35px;
}

.RecipesHeader {
  padding-top: 36px;
  padding-bottom: 30px;

  @include media('lg-up') {
    padding-top: 67px;
  }

  .SectionLogotype {
    max-width: 145px;
    margin: 1px auto 0;
    img {
      max-width: 100%;
    }

    @include media('md-up') {
      max-width: 260px;
    }

    @include media('lg-up') {
      max-width: 348px;
    }
    @media screen and (min-width: 1440px) {
      max-width: 400px;
    }
  }

  &__banner {
    margin-top: 18px;
    padding-top: 20px;
    border-top: 1px solid;
    position: relative;

    @include media('md-up') {
      margin-top: 24px;
      padding-top: 30px;
    }
    @include media('lg-up') {
      margin-top: 24px;
      padding-top: 30px;
    }
    @media screen and (min-width: 1440px) {
      margin-top: 48px;
    }
  }

  &__banner--transparentBanner {
    @include media('md-up') {
      h1 {
        position: absolute;
        top: 38px;
        left: 0;
        width: 100%;
      }

      .Hero__image {
        margin-top: -6.1rem;
      }
    }
  }

  .Hero__caption,
  .Hero__credit {
    text-align: right;
  }

  &__intro {
    max-width: 800px;
    margin: 50px auto;
    font-size: 20px;
  }
}
/* Holidays */
.RecipesView__season,
.RecipesView__holiday {
  &__title {
    font-size: 20px;
    padding-bottom: 24px;

    @include media('md-up') {
      padding-bottom: 18px;
    }
  }

  & + & {
    margin-top: 20px;
  }

  .RecipesView__headerButtons {
    height: 50px;
    display: flex;
    align-items: center;

    .RecipesView__button {
      font-size: 18px;
      text-decoration: none;
      color: currentColor;
    }
  }

  .RecipeFilter {
    margin-top: -50px;
  }
}
