.HolidayDescription {
  max-height: 375px;
  margin-right: 1.5rem;

  border-bottom: 1px solid color('black');

  @include media('sm-down') {
    width: 100%;
    margin-right: 0;
  }

  @include media('sm-up') {
    margin-bottom: 1.5rem;
  }

  @include media('md-up') {
    max-height: 450px;
  }

  @include media('lg-up') {
    max-height: initial;
    margin-left: 0;
    margin-bottom: 0;
    width: 33%;
    border-right: $border-black;
    border-bottom: $border-black;
  }

  &__title {
    margin-bottom: 1.3rem;

    @include media('sm-up') {
      margin-bottom: 1.7rem;
    }
  }

  &__inner {
    @include media('lg-up') {
      max-height: 400px;
    }
  }

  .Button {
    font-size: 20px;
  }

  &__paragraph {
    font-size: 20px;
    line-height: 1.2em;

    a {
      @extend %linked-border-bottom-red;
      color: inherit;
      text-decoration: none;
    }

    > div > p {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__readMore {
    position: sticky;
    z-index: 10;
    bottom: 0;
    text-align: center;
    padding-bottom: 0.4rem;

    @include media('md-up') {
      padding-bottom: 0.9rem;
    }
    @include media('lg-up') {
      padding-bottom: 2.25rem;
    }
  }

  &__gradient {
    position: absolute;
    background: color('beige');
    padding-top: 11.2rem;
    background: linear-gradient(
      180deg,
      rgba(239, 233, 220, 0) 0%,
      rgba(239, 233, 220, 1) 45%
    );

    @include media('md-up') {
      padding-top: 12rem;
    }

    @include media('lg-up') {
      padding-top: 6rem;
      background: linear-gradient(
        180deg,
        rgba(239, 233, 220, 0) 0%,
        rgba(239, 233, 220, 1) 45%
      );
    }
  }
}

/* TO-DO: [DEPRECATED] Once Holidays v2 is launched this can be removed */
.V1HolidayDescription {
  @include media('md-up') {
    overflow: scroll;
  }

  @include media('lg-up') {
    border-right: $border-black;
    border-bottom: $border-black;
  }

  &__paragraph {
    > div > p {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 3rem;
      }

      > a,
      > strong > a,
      > em > a {
        @extend %linked-border-bottom-red;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__gradient {
    padding-top: 5rem;
    background: color('beige');
    background: linear-gradient(
      180deg,
      rgba(239, 233, 220, 0) 0%,
      rgba(239, 233, 220, 1) 66%
    );
  }
}
