.TopBar {
  &__container {
    padding: 2.375rem 0 1rem;
    position: relative;

    @include media('sm-up') {
      padding: 1.9375rem 0 1.5rem;
    }

    @include media('md-up') {
      padding: 1.75rem 0 1.5rem;
    }

    @include media('lg-up') {
      padding: 2.625rem 0 2.3125rem;
    }

    @include media('xl-up') {
      padding: 3.4375rem 0 3rem;
    }
  }

  &__section-logo {
    width: auto;
    max-width: 100%;
    height: 1.875rem;

    @include media('sm-up') {
      height: 3.375rem;
    }

    @include media('md-up') {
      height: 4.5rem;
    }

    @include media('md') {
      height: 5.25rem;
    }

    @include media('xl-up') {
      height: 6rem;
    }
  }

  &__section--holidays {
    padding-top: 1.45rem;
    .TopBar__section-logo {
      height: 41px;
    }
    @include media('md-up') {
      padding-top: 3rem;
      .TopBar__section-logo {
        height: 50px;
        @include media('md-up') {
          height: 4.5rem;
        }

        @include media('lg-up') {
          height: 5.25rem;
        }

        @include media('xl-up') {
          height: 6rem;
        }
      }
    }
  }

  &__section--food-recipes-link {
    position: absolute;
    left: 0;
    top: 75%;

    @include media('sm-up') {
      left: 20px;
    }

    @include media('md-up') {
      top: 80%;
    }

    @include media('lg-up') {
      top: 50%;
    }
  }

  &__section--archive {
    position: absolute;
    right: 0;
    top: 75%;

    @include media('sm-up') {
      right: 20px;
    }

    @include media('md-up') {
      top: 80%;
    }

    @include media('lg-up') {
      top: 50%;
    }
  }

  &__logo-icon {
    height: 1.3125rem;
  }
}
