.Teaser {
  display: inline-block;
  padding: 0 0 41px;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;

  @include media('md-up') {
    width: auto;
    padding: 0 15px 0;
    min-height: 335px;
  }

  @media screen and (min-width: 540px) {
    border: 1px solid;
    border-width: 0 1px 1px 0;
    padding: 0 24px 16px 0;
  }

  @media screen and (min-width: 900px) {
    border: 1px solid;
    border-width: 0 1px 1px 0;
    padding: 0 24px 16px 0;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 24px 44px 0;
  }

  &__title {
    font-size: 1.475rem;
    line-height: 1.2em;

    @include media('xl-up') {
      font-size: 28px;
      line-height: 1.07em;
    }

    &:first-child {
      @include media('md-up') {
        padding-top: 14px;
      }
    }
  }

  &__byline {
    margin-top: 17px;
    line-height: 1.2em;

    @include media('xl-up') {
      margin-top: 18px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.RecipeTeaser {
  &__image {
    margin-bottom: 15px;

    @include media('md-up') {
      margin-bottom: 14px;
    }

    @media screen and (min-width: 1440px) {
      margin-bottom: 24px;
    }

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.FinalTeaser {
  border: 1px dotted;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;

  &__button {
    flex-grow: 1;
  }
}
