.FactStoryTeaserWidget {
  padding-bottom: 1.5625rem;

  @include media('sm-up') {
    padding-bottom: 1.875rem;
  }

  @include media('lg-up') {
    padding-bottom: 2.625rem;
  }

  &__fact {
    padding-top: 0.75rem;
    font-size: 6rem;
    letter-spacing: -0.133125rem;
    line-height: 7.5rem;

    @include media('sm-up') {
      font-size: 4.5rem;
      letter-spacing: -0.1rem;
      line-height: 4.5rem;
      padding-top: 1.375rem;
    }

    @include media('md-up') {
      font-size: 5.25rem;
      letter-spacing: -0.116875rem;
      line-height: 7.5rem;
      padding-top: 0.4375rem;
    }

    @include media('xl-up') {
      font-size: 6rem;
      letter-spacing: -0.133125rem;
      padding-top: 0.9375rem;
    }
  }

  &__subtitle {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      padding-top: 1.1875rem;
    }

    @include media('md-up') {
      padding-top: 0;
    }
  }

  &--split-layout {
    @media (max-width: 767px) {
      padding-bottom: 0.5625rem;
    }

    .FactStoryTeaserWidget__fact {
      @media (max-width: 767px) {
        font-size: 3.75rem;
        line-height: 3rem;
        letter-spacing: -0.083125rem;
        padding-top: 1.875rem;
      }
    }

    .FactStoryTeaserWidget__subtitle {
      @media (max-width: 767px) {
        font-size: 1.125rem;
        line-height: 1.5rem;
        padding-top: 1.4375rem;
      }
    }
  }
}
