$searchbar-heading-mb: 1.875rem;
$searchbar-mobile-pt: 4rem;
$searchbar-height: 2.5rem;

.SearchBar {
  height: calc(
    $nav-mobile-height + $searchbar-mobile-height + $searchbar-heading-mb +
      $searchbar-mobile-pt
  );
  padding-top: $searchbar-mobile-pt;

  @include media('sm-up') {
    min-height: calc($nav-desktop-height + $searchbar-desktop-height);
    padding-top: 0;
  }

  @include media('lg-up') {
    min-height: calc(
      $nav-desktop-height + $searchbar-desktop-height + $searchbar-heading-mb
    );
  }

  &__content-container {
    top: $nav-mobile-height;

    @include media('md-up') {
      top: $nav-desktop-height;
    }
  }

  &__heading {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: $searchbar-heading-mb;

    @include media('lg-up') {
      font-size: 3.75rem;
      margin-top: 0.5rem;
    }
  }

  &__search-container {
    position: relative;

    padding-left: $content-padding-x-sm;
    padding-right: $content-padding-x-sm;

    @include media('sm-up') {
      padding-left: $content-padding-x-custom-sm;
      padding-right: $content-padding-x-custom-sm;
    }

    @include media('md-up') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__searchbox {
    margin: 0 auto;

    @include media('md-up') {
      padding-left: $content-padding-x-lg;
      padding-right: $content-padding-x-lg;
    }

    @include media('lg-up') {
      max-width: 108rem;
    }
  }

  form {
    background-color: transparent;
    position: relative;
  }

  form::before {
    display: none;
  }

  form input {
    font-family: $graebenbach;
    font-weight: 400;
    box-shadow: none;
    border-radius: none;
    border: none;
    border-right: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
    padding-left: 0;
    background-color: transparent;
    font-size: 1.125rem;
    line-height: 1.5rem;

    &::placeholder {
      color: var(--black);
    }

    &:focus {
      border-color: color('gray');
    }
  }

  &__hits {
    height: auto;
    max-height: 400px;

    ol {
      height: 100%;
      max-height: 400px;
      margin: 0 auto;
      overflow-x: scroll;
      -ms-overflow-style: none; /* Edge */
      scrollbar-width: none; /* Firefox */

      /* Chrome, Safari */
      &::-webkit-scrollbar {
        display: none;
      }

      @include media('md-up') {
        padding-left: $content-padding-x-lg;
        padding-right: $content-padding-x-lg;
      }

      @include media('lg-up') {
        max-width: 108rem;
      }
    }

    li {
      box-shadow: none;
      padding: 0.5rem 0;

      @include media('md-up') {
        padding: 0.75rem 0;
      }
    }

    &.li-color-gray-lighter {
      li {
        background: var(--gray-lighter);
      }
    }
  }

  &__search-icon {
    width: 18px;
    height: 18px;
  }

  &__result-item {
    a {
      text-decoration-color: var(--black);
    }
  }

  &__down-button {
    display: none;
    cursor: initial;

    @include media('md-up') {
      display: flex;
      padding: 0.75rem 0;
    }
  }
}

.transition-open-search {
  animation: $transition-animate-appear-from-bottom;
  animation-fill-mode: forwards;
  pointer-events: all;
}

.transition-close-search {
  animation: $transition-animate-disappear-to-top;
  animation-fill-mode: forwards;
  pointer-events: none;
}
