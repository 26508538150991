$content-padding-x-sm: 0.9375rem;
$content-padding-x-md: 4.4375rem;
$content-padding-x-lg: 5.25rem;
$content-padding-x-xl: 11.25rem;
$content-padding-x-sm-double: 1.875rem;
$content-padding-x-md-double: 9rem;
$content-padding-x-lg-double: 10.5rem;
$content-padding-x-custom-sm: 3rem;

$inner-content-max-width: 108rem;
$site-content-max-width: 79.5rem;
$article-content-width: 66rem;
$article-reference-text-spacing-xl: 6.75rem;
$article-reference-text-spacing-lg: 4.875rem;
$article-reference-text-spacing-md: 3.375rem;
$nav-desktop-height: 4.5rem;
$nav-mobile-height: 3.4375rem;
$nav-section-desktop-height: 12.5rem;
$nav-section-mobile-height: 8.75rem;
$side-nav-mobile-width: 20.625rem;
$side-nav-desktop-width: 27rem;
$footer-mobile-height: 15rem;
$footer-desktop-height: 5.25rem;
$podcast-title-width: 25.5rem;
$searchbar-desktop-height: 12rem;
$searchbar-mobile-height: 6rem;

$section-nav-width-sm: 40vw;
$section-nav-width-md: 37.5vw;
$section-nav-width-lg: 30vw;
$section-nav-width-xl: 22.5vw;
$section-nav-min-width: 16.875rem;

$block-quote-spacing-mobile: 1.5rem;
$block-quote-spacing-desktop: 3rem;

$story-teaser-split-layout-margin: 1rem 0 2.5rem;
$story-teaser-split-layout-padding: 0.75rem;

$bar-width: 3.25rem;
$bar-hover-width: 0.5rem;

$list-spacing-desktop: 3rem;
