.RecentArticlesBySection {
  margin-bottom: 5.625rem;

  @include media('sm-up') {
    margin-bottom: 2.75rem;
  }

  @include media('md-up') {
    margin-bottom: 3.85rem;
  }

  @include media('xl-up') {
    margin-bottom: 5.25rem;
  }

  &__divider {
    background-color: color('black');
    height: 24px;
    margin-bottom: 1.4rem;
  }

  &__section-container {
    @include media('sm-up') {
      display: grid;
      grid-column-gap: 1.1rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: auto;
    }
    @include media('md-up') {
      grid-column-gap: 1.5rem;
    }

    @include media('lg') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &__section {
    border-bottom: $border-black;
    @include media('md-up') {
      border-right: $border-black;
    }
  }

  &__logo-container {
    @include media('md-up') {
      background-image: none;
      border-bottom: $border-black;
      padding-top: 2.5rem;
      padding-bottom: 1.125rem;
    }
  }

  &__section-logo {
    height: 1rem;

    @include media('md-up') {
      height: 1.5rem;
    }
  }

  &__article {
    padding-bottom: 1.25rem;
    padding-top: 1.125rem;
    background-position: top;
    background-repeat: repeat-x;
    background-size: 4px 1px;
    background-image: linear-gradient(
      to right,
      color('black') 24%,
      transparent 0%
    );

    @include media('lg') {
      padding-top: 0.75rem;
    }

    &:first-of-type {
      border-top: none;

      @include media('md-up') {
        background-image: none;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &__title {
      font-size: 1.5rem;
      line-height: 1.75rem;

      @include media('lg') {
        margin-bottom: 0.25rem;
      }
    }

    &__authors {
      margin-top: 1rem;
    }
  }

  // to prevent them from overlapping the fixed header
  .AuthorButtons__button-text {
    position: static;
  }
}
