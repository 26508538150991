.DonationPopup {
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.5);
  max-height: 85vh;
  overflow-y: scroll;

  @include media('md-up') {
    max-height: auto;
  }

  &--inactive {
    transform: translate3d(0, 100vh, 0);
  }

  &__container {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    @include media('sm-up') {
      padding-right: 4.5rem;
      padding-left: 4.5rem;
    }

    @include media('md-up') {
      padding-left: 5.25rem;
      padding-right: 5.25rem;
    }

    @include media('xl-up') {
      padding-right: 11.25rem;
      padding-left: 11.25rem;
    }
  }

  &__info-text {
    font-size: 1.125rem;
    line-height: 1.5rem;

    a {
      @extend %linked-border-bottom-black;
      color: color('black');
      text-decoration: none;
    }

    @include media('lg') {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  &__description--off-white {
    a {
      background-image: linear-gradient(color('off-white'), color('off-white'));
      background-position: right 92%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: background-size $transition-duration-short;
      color: color('off-white');
      text-decoration: none;

      &:hover {
        background-size: 0% 1px;
      }
    }
  }

  &--one-button {
    .DonationPopup__container {
      padding-bottom: 2.5rem;

      @include media('md-up') {
        padding-bottom: 2.6875rem;
      }

      @include media('lg-up') {
        padding-bottom: 2.625rem;
      }

      @include media('lg') {
        padding-bottom: 3.5625rem;
      }
    }

    .DonationPopup__info-container {
      @include media('sm-up') {
        max-width: 37.5rem;
      }

      @include media('md') {
        max-width: 45.75rem;
      }

      @include media('xl-up') {
        max-width: 55.875rem;
      }
    }

    .DonationPopup__title {
      font-size: 2rem;
      line-height: 2.25rem;
      margin-top: 2.375rem;

      @include media('md-up') {
        line-height: 3rem;
        margin-top: 2.3125rem;
      }

      @include media('lg') {
        font-size: 2.25rem;
        margin-top: 3rem;
      }
    }

    .DonationPopup__description {
      margin-top: 1.25rem;

      @include media('md-up') {
        margin-top: 0.3125rem;
      }

      @include media('lg') {
        margin-top: 0.1875rem;
      }
    }

    .DonationPopup__support-button-container {
      margin-top: 2.4375rem;

      @include media('md-up') {
        margin-top: 2.625rem;
      }
      @include media('lg-up') {
        margin-top: 3rem;
      }

      @include media('lg') {
        margin-top: 3.75rem;
      }
    }

    .DonationPopup__support-button {
      font-size: 1rem;
      line-height: 1.3125rem;
      padding: 1.1875rem 1.9375rem;
      padding-right: calc(1.9375rem + 0.690625rem + 0.25rem);

      @include media('md-up') {
        padding: 1.1875rem 2.1875rem;
        padding-right: calc(2.1875rem + 0.690625rem + 0.25rem);
      }

      @include media('lg') {
        font-size: 1.125rem;
        line-height: 1.4375rem;
        padding: 1.0625rem 2.5625rem;
        padding-right: calc(2.5625rem + 0.77625rem + 0.25rem);
      }

      &:hover {
        .DonationPopup__support-button-arrow {
          transform: translateX(0.25rem);
        }
      }
    }

    .DonationPopup__dismiss-text-button {
      font-size: 1rem;
      line-height: 1.3125rem;
      margin-top: 1.8125rem;

      @include media('md-up') {
        margin-top: 1.25rem;
      }

      @include media('lg') {
        margin-top: 1.125rem;
      }

      &:hover {
        .DonationPopup__dismiss-text-button-text {
          background-size: 0% 1px;
        }
      }
    }

    .DonationPopup__dismiss-text-button-text {
      background-position: right 92%;
      background-repeat: no-repeat;
      background-size: 100% 1px;
      transition: background-size $transition-duration-short;
    }

    .DonationPopup__dismiss-text-button-text--gray-darker {
      background-image: linear-gradient(
        color('gray-darker'),
        color('gray-darker')
      );
    }

    .DonationPopup__dismiss-text-button-text--off-white {
      background-image: linear-gradient(color('off-white'), color('off-white'));
    }

    .DonationPopup__dismiss-text-button-text--gray-lighter {
      background-image: linear-gradient(
        color('gray-lighter'),
        color('gray-lighter')
      );
    }
  }

  &--multi-button {
    .DonationPopup__container {
      padding-bottom: 2.8125rem;

      @include media('md-up') {
        padding-bottom: 5.25rem;
      }

      @include media('lg') {
        padding-bottom: 6rem;
      }
    }

    .DonationPopup__info-container {
      @include media('md-up') {
        max-width: 47.9375rem;
      }

      @include media('xl-up') {
        max-width: 56.8125rem;
      }
    }

    .DonationPopup__title {
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin-top: 4.0625rem;

      @include media('xs-up') {
        font-size: 3rem;
        line-height: 3rem;
      }

      @include media('md-up') {
        margin-top: 3.4375rem;
      }

      @include media('lg') {
        margin-top: 4.1875rem;
      }
    }

    .DonationPopup__description {
      margin-top: 1.1875rem;

      @include media('md-up') {
        margin-top: 1.4375rem;
      }

      @include media('lg') {
        margin-top: 2rem;
      }
    }

    .DonationPopup__support-text {
      margin-top: 1.5rem;

      @include media('lg') {
        margin-top: 1.75rem;
      }
    }

    .DonationPopup__close-icon {
      width: 1.25rem;
      margin: 0.8125rem;

      @include media('md-up') {
        margin: 1.5rem;
      }

      @include media('lg') {
        margin: 2.25rem;
      }
    }

    .DonationPopup__donation-amount-button-container {
      margin-top: 1.5625rem;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(auto, auto);
      grid-gap: 1.5rem;

      @include media('md-up') {
        margin-top: 1.9375rem;
        display: flex;
        align-items: center;
      }

      @include media('lg') {
        margin-top: 1.8125rem;
      }
    }

    .DonationPopup__donation-amount-button {
      width: 4.9375rem;
      height: 3rem;
      font-size: 1rem;

      @include media('md-up') {
        width: 5rem;
      }

      @include media('lg') {
        font-size: 1.125rem;
      }
    }

    .DonationPopup__donation-amount-button--custom {
      @include media('md-up') {
        width: 6.375rem;
      }
    }
  }
}
