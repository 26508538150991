.SectionNav {
  left: 0;
  max-height: 100vh;
  height: 100vh;

  &__bg {
    min-width: 100vw;
    min-height: 100vh;
    transition-delay: 0.5s;
  }

  &__bg--inactive {
    transition-delay: 0s;
  }

  &__section-container {
    padding: 0 0.9375rem;

    @include media('md-up') {
      padding: 0 1.5rem;
    }
  }

  &__menu-button {
    margin: 0.9375rem;
    transition-delay: 0.4s;

    @include media('md-up') {
      margin: 1.5rem;
    }
  }

  &__menu-inactive {
    transition-delay: 0s;
  }

  &__close-button {
    @include media('xs-down') {
      width: 1.125rem;
    }
  }

  &__tablet-logo {
    width: 7rem;

    @include media('md-up') {
      width: 9.875rem;
    }
  }

  &__more-stories-container {
    height: 15rem;
    background: linear-gradient(
      180deg,
      rgba(226, 218, 203, 0) 0%,
      rgba(226, 218, 203, 1) 66%
    );
    transform: translateY(15rem);
    transition: all 0.2s $transition-easing;
    padding: 2.5rem 0.9375rem;

    @include media('md-up') {
      padding: 2.5rem 1.5rem;
    }
  }

  &__more-stories-container-text {
    text-decoration: underline;
    text-decoration-color: color('red');
  }

  &__section-container-bg {
    z-index: 3;
    background-color: rgba(102, 68, 0, 0.07);
  }

  &__section-container {
    min-width: $section-nav-min-width;
    width: $section-nav-width-sm;

    @include media('md-up') {
      width: $section-nav-width-md;
    }

    @include media('lg-up') {
      width: $section-nav-width-lg;
    }

    @media (min-width: 1440px) {
      width: $section-nav-width-xl;
    }

    &:hover {
      .SectionNav__section-container-bg {
        box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.4);
        opacity: 1;
        z-index: 4;
      }

      .SectionNav__more-stories-container {
        transform: translateY(0);
      }

      .SectionNavHeader__container {
        transform: translateX(-1rem);
      }

      .SectionNavHeader__arrow {
        opacity: 1;
        transform: translateX(2rem);
      }

      .SectionNavHoliday__holiday1-description {
        &::before {
          opacity: 1;
        }
      }
    }

    &--hover-active {
      .SectionNav__more-stories-container {
        position: fixed;
        bottom: 0;
        transform: translateY(0);
        z-index: 4;

        background: linear-gradient(
          180deg,
          rgba(239, 233, 220, 0) 0%,
          rgba(239, 233, 220, 1) 66%
        );
      }

      .SectionNavHoliday__holiday1-description {
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__section-container--active {
    box-shadow: -10px -10px 20px -10px rgba(0, 0, 0, 0.4);
  }
}
