.LegacyRecipe {
  border: 1px solid color('gray-darkest');
  padding: 0 $content-padding-x-sm;

  @include media('md-up') {
    border-top: $border-black;
    border-bottom: $border-black;
    border-left: none;
    border-right: none;
  }

  &__content {
    max-width: 39rem;

    p {
      margin: 1.5rem 0;
    }
  }

  &__arrow-icon {
    transform: rotate(-45deg);
  }

  &__print-button:hover {
    .LegacyRecipe__arrow-icon {
      transform: rotate(0);
    }
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
