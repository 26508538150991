.SectionNavA {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 3rem;
  grid-row-gap: 0.9375rem;

  padding-top: 0.9375rem;

  @include media('md-up') {
    grid-row-gap: 1.5rem;
    padding-top: 1.5rem;
  }

  &__article {
    grid-column: 1/3;

    padding-bottom: 0.9375rem;
    border-bottom: $border-black;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('md-up') {
      padding-bottom: 1.8125rem;
    }

    @media (min-width: 1440px) {
      padding-bottom: 2.5rem;
    }

    .SectionNavA__image {
      margin-bottom: 1.0625rem;

      @include media('md-up') {
        margin-bottom: 0.75rem;
      }

      @include media('lg-up') {
        margin-bottom: 0.8125rem;
      }

      @media (min-width: 1440px) {
        margin-bottom: 1.25rem;
      }
    }

    .SectionNavA__title {
      font-weight: 600;
      text-align: center;
      font-size: 1.75rem;
      line-height: 1.875rem;

      @include media('md-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }

      @media (min-width: 1440px) {
        margin-bottom: 1.3125rem;
        font-weight: 300;
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: -0.016875rem;
      }
    }

    .SectionNavA__brief {
      text-align: center;
      margin-bottom: 0.625rem;

      font-size: 1.125rem;
      line-height: 1.5rem;

      @include media('md-up') {
        margin-bottom: 1.3125rem;
        font-size: 1.25rem;
      }

      @media (min-width: 1440px) {
        margin-bottom: 1.0625rem;
      }
    }
  }

  &__article2 {
    @media (min-width: 1440px) {
      grid-column: 1/2;
      grid-row: 2/3;

      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: calc(100% - 1.5rem);
        border-right: $border-black;
        right: -1.5rem;
        top: 0;
        bottom: 0;
      }
    }
  }

  &__article3 {
    @media (min-width: 1440px) {
      grid-column: 2/3;
      grid-row: 2/3;

      position: relative;

      &::after {
        content: '';
        position: absolute;
        border-bottom: $border-black;
        right: 0;
        bottom: 0;
        width: calc(100% * 2 + 3rem);
      }
    }
  }

  &__article2,
  &__article3 {
    @media (min-width: 1440px) {
      border-bottom: none;
      padding-bottom: 1.875rem;

      .SectionNavA__image {
        margin-bottom: 1rem;
      }

      .SectionNavA__title {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-bottom: 0;
        text-align: left;
        align-self: start;
      }

      .SectionNavA__brief {
        text-align: left;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;

        @include media('md-up') {
          font-size: 1.25rem;
        }
      }

      .SectionNavA__authors {
        text-align: left;
      }
    }
  }

  &__article-rest {
    grid-column: 1/3;

    padding-bottom: 2.5rem;
    border-bottom: $border-black;

    display: flex;
    flex-direction: column;
    align-items: center;

    .SectionNavA__image {
      margin-bottom: 1.5rem;
    }

    .SectionNavA__title {
      font-weight: 600;
      text-align: center;
      font-size: 1.75rem;
      line-height: 1.875rem;

      @include media('md-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }

      @media (min-width: 1440px) {
        font-size: 1.875rem;
        line-height: 2rem;
        margin-bottom: 0.75rem;
      }
    }

    .SectionNavA__brief {
      text-align: center;
      margin-bottom: 0.625rem;

      font-size: 1.125rem;
      line-height: 1.5rem;

      @include media('md-up') {
        margin-bottom: 1.3125rem;
        font-size: 1.25rem;
      }

      @media (min-width: 1440px) {
        margin-bottom: 0.3125rem;
      }
    }
  }
}
