.AboutTeamMember {
  //TO-DO: when about page has nav and footer, substract nav and footer height.
  min-height: 100vh;

  &__image-container {
    max-width: 15.5rem;

    @include media('md-up') {
      min-width: 12rem;
    }

    @include media('lg-up') {
      min-width: 13.75rem;
    }

    @include media('lg') {
      min-width: 15.5rem;
    }

    @include media('xl-up') {
      min-width: 19.5rem;
    }
  }

  &__image-background {
    padding-top: 100%;
    height: 0;
  }

  &__text {
    > div > p > a {
      @extend %linked-border-bottom-black;
      color: inherit;
      text-decoration: none;
    }

    @include media('md-down') {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
}
