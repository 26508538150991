.Hero {
  &__image {
    text-align: left;
    max-width: $inner-content-max-width;

    @media print {
      & > div:first-child > div:first-child {
        padding-top: initial !important;
      }

      img {
        position: relative;
        max-height: 75vh;
        object-fit: contain;
      }
    }
  }

  &--full-image {
    @include media('lg-up') {
      border-bottom: none;
    }
  }

  &__header-container {
    @media ('print') {
      width: 100% !important;
      display: block;
      padding-right: 0;
      border-right: 0;
      & > div {
        width: 100% !important;
      }
    }
    &--half-width {
      @include media('lg-up') {
        float: left;
        width: 49%;
        border-right: $border-black;
      }
    }

    &--full-width {
      @include media('lg-up') {
        width: 100%;
        border-right: $border-black;
      }
    }

    &--centered-text {
      @include media('lg-up') {
        width: 100%;
      }
    }
  }

  &__section-icon-container {
    margin-top: 1.25rem;

    @include media('sm-up') {
      margin-top: 3.5625rem;
    }

    @include media('md-up') {
      margin-top: 2.8125rem;
    }

    @include media('xl-up') {
      margin-top: 2rem;
    }
  }

  &__section-icon-container--vertical {
    @include media('lg-up') {
      margin-top: 0;
    }
  }

  &__headline {
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: -0.03125rem;
    margin-top: 1.25rem;

    @include media('sm-up') {
      margin-top: 2rem;
    }

    @include media('md') {
      font-size: 3.75rem;
      line-height: 3.75rem;
      margin-top: 1.375rem;
    }

    @include media('xl-up') {
      font-size: 4.5rem;
      line-height: 4.5rem;
      margin-top: 2.125rem;
    }
  }

  &__headline--vertical {
    @include media('sm-up') {
      margin-top: 2rem;
    }

    @include media('md') {
      margin-top: 1.375rem;
    }

    @include media('xl-up') {
      margin-top: 2.1875rem;
    }
  }

  &__dek {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 0.75rem;

    @include media('sm-up') {
      margin-top: 0.5625rem;
    }

    @include media('md-up') {
      margin-top: 1.3125rem;
    }

    @include media('md') {
      margin-top: 1.1875rem;
    }

    @include media('xl-up') {
      margin-top: 1.875rem;
    }
  }

  &__dek--vertical {
    @include media('md') {
      margin-top: 1.3125rem;
    }

    @include media('xl-up') {
      margin-top: 2rem;
    }
  }

  &__info-container {
    padding-bottom: 3rem;

    @include media('md-up') {
      min-height: 28.5rem;
    }

    @include media('xl-up') {
      min-height: 32.9375rem;
    }
  }

  &__info-container--vertical {
    @include media('lg-up') {
      padding-bottom: 0;
    }
  }

  &__section-button,
  &__author-text {
    background-position: right 100% !important;
  }

  &__section-icon {
    padding: 0 0.1875rem;
    max-height: 1rem;
    width: auto;
  }

  &__author {
    margin-top: 1.0625rem;

    @include media('lg-up') {
      margin-top: 1.3125rem;
    }

    @include media('xl-up') {
      margin-top: 1.0625rem;
    }
  }

  &__author--vertical {
    @include media('lg-up') {
      margin-top: 1.0625rem;
    }
  }

  &__author-text {
    font-size: 1rem;
    line-height: 1.125rem;
  }

  &__date {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.0625rem;
    margin-top: 2.875rem;
  }

  &__date--vertical {
    @include media('lg-up') {
      margin-top: 5.125rem;
    }

    @include media('lg') {
      margin-top: 6.625rem;
    }
  }

  &__caption {
    line-height: 1.25rem;
    letter-spacing: -0.025rem;

    @include media('lg-up') {
      font-size: 1.125rem;
      line-height: 1.5rem;
      letter-spacing: -0.03125rem;
    }
  }

  &__credit {
    letter-spacing: 0.0625rem;

    @include media('lg-up') {
      line-height: 1.5rem;
    }
  }

  // Overrides for no image, centered-text hero layout
  &--centered-text {
    .Hero {
      &__section-icon-container {
        @include media('sm-up') {
          margin-top: 1rem;
        }

        @include media('md-up') {
          margin-top: 2.5rem;
        }

        @include media('xl-up') {
          margin-top: 3.25rem;
        }
      }

      &__headline {
        margin-top: 1rem;

        @include media('md-up') {
          margin-top: 1.75rem;
        }

        @include media('lg') {
          margin-top: 1.15rem;
        }
      }

      &__dek {
        @include media('sm-up') {
          margin-top: 0.3rem;
        }

        @include media('md-up') {
          margin-top: 1rem;
        }

        @include media('xl-up') {
          margin-top: 0.75rem;
        }
      }

      &__date {
        margin-top: 2.25rem;

        @include media('lg-up') {
          margin-top: 3rem;
        }
      }

      &__author {
        @include media('lg-up') {
          margin-top: 1rem;
        }
      }

      &__info-container {
        min-height: auto;
        padding-bottom: 1.45rem;

        @include media('lg') {
          padding-bottom: 2.5rem;
        }
      }
    }
  }
}
