.DropcapsIllustration {
  width: 7.5rem;
  line-height: 5.5rem;
  padding-bottom: 1.25rem;
  padding-right: 2.25rem;
  margin-top: 0.5rem;
  margin-left: 0 !important;

  @include media('md-up') {
    width: 10rem;
    line-height: 9rem;
    padding-right: 4rem;
    margin: 0.75rem 0 1.75rem $article-reference-text-spacing-xl;
  }
}
