.LayoutE {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  @include media('sm-up') {
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  @include media('md-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, auto);
  }

  //right-to-left alignment
  &__article1-container--right-to-left {
    grid-column: 1/2;
    grid-row: 1/2;
    border-bottom: $border-black;

    @include media('sm-up') {
      border-bottom: none;
    }

    @include media('md-up') {
      grid-column: 2/4;
      grid-row: 1/3;
    }
  }

  &__widget-container--right-to-left {
    grid-column: 1/2;
    grid-row: 2/3;
    border-bottom: $border-black;

    @include media('sm-up') {
      padding: 0 3rem 3rem 3rem;
    }

    @include media('md-up') {
      border-right: $border-black;
      padding: 0 3rem 3rem 0;
      grid-column: 1/2;
      grid-row: 1/3;
    }
  }

  //left-to-right alignment
  &__article1-container--left-to-right {
    grid-column: 1/2;
    grid-row: 1/2;
    border-bottom: $border-black;

    @include media('sm-up') {
      border-bottom: none;
    }

    @include media('md-up') {
      grid-column: 1/3;
      grid-row: 1/3;
    }
  }

  &__widget-container--left-to-right {
    grid-column: 1/2;
    grid-row: 2/3;
    border-bottom: $border-black;

    @include media('sm-up') {
      padding: 0 3rem 3rem 3rem;
    }

    @include media('md-up') {
      border-right: none;
      padding: 0 0 3rem 1.5rem;
      grid-column: 3/4;
      grid-row: 1/3;
    }
  }
}
