.TextField {
  label {
    display: absolute;
    font-family: $graebenbach;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
  }

  input,
  textarea {
    @extend %text-section-body-xs;
    border: none;
    padding: 0.5rem 0;
    background-color: transparent;
    width: 100%;
    font-family: $graebenbach;
    font-weight: 300;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  &__info-message {
    font-family: $graebenbach;
    font-size: 0.75rem;
    margin-top: 0.3rem;
  }

  &--style-red {
    input,
    textarea {
      color: color('red');

      &::placeholder {
        color: color('red');
        font-family: $graebenbach;
        font-weight: 300;
        font-size: 1rem;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: color('red') !important;
    }

    .TextField__label--active {
      display: block;
      position: absolute;
      color: color('red');
      margin-top: -1rem;
    }
  }

  &--style-black {
    input,
    textarea {
      transition: $transition-duration-short;
      background-color: color('transparent');
      color: color('gray');

      &::placeholder {
        transition: $transition-duration-short;
        color: color('gray');
        font-family: $graebenbach;
        font-weight: 300;
        font-size: 1rem;
      }

      &:hover {
        transition: $transition-duration-short;
        background-color: color('transparent');

        &::placeholder {
          transition: $transition-duration-short;
          color: color('gray-lighter');
        }
      }

      &:focus {
        transition: $transition-duration-short;
        background-color: color('transparent');
        color: color('gray-lighter');

        &::placeholder {
          transition: $transition-duration-short;
          color: color('gray-lighter');
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: color('gray') !important;
    }

    .TextField__label--active {
      display: block;
      position: absolute;
      color: color('gray');
      margin-top: -1rem;
    }
  }

  &--style-white {
    input,
    textarea {
      color: color('black');

      &::placeholder {
        color: color('black');
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-text-fill-color: color('black') !important;
    }

    .TextField__label--active {
      display: block;
      position: absolute;
      color: color('gray-dark');
      margin-top: -1rem;
    }
  }

  &--style-red--error {
    border-bottom: solid 1px color('red');

    .TextField__error-message {
      color: color('red');
    }

    .TextField__error-mark {
      background-color: color('red');
    }
  }

  &--style-black--error {
    border-bottom: solid 1px color('gray');

    .TextField__error-message {
      color: color('gray');
    }

    .TextField__error-mark {
      background-color: color('gray');
    }
  }
}
