.DownloadAppWidget {
  background-color: #e73f22;
  color: var(--off-white);
  padding: 1.5rem 1rem;

  &__description {
    line-height: 25px;
  }

  &__button {
    width: fit-content;
    background-color: var(--off-white);
    color: #e73f22;
    padding: 11.5px 25px;
    border: none;
    border-radius: 40px;
    margin-top: 1rem;
    overflow-wrap: break-word;
    white-space: normal;
  }

  &__button-text {
    font-size: 18.78px;
    line-height: 25px;
    white-space: normal;
  }
}
