.AuthorWidget {
  &__icon {
    display: block;
    height: 6.75rem;
    margin: 0 auto 1.375rem;
    text-align: center;
    width: 8.125rem;
  }

  &__title {
    margin-bottom: 0.75rem;
  }
  &__item {
    background-position: top;
    line-height: 1.125;
    padding-left: 1.25rem;
    position: relative;

    &::before {
      border-radius: 100%;
      content: '';
      height: 0.625rem;
      left: 0;
      position: absolute;
      top: 1.25rem;
      width: 0.625rem;
    }

    .variant-red & {
      &::before {
        background-color: color('red');
      }
    }

    .variant-black & {
      &::before {
        background-color: color('black');
      }
    }

    &__author {
      font-size: 1rem;
    }

    &__title {
      font-size: 0.875rem;
    }
  }

  &__button {
    background-color: transparent;
    font-size: 0.875rem;
  }
}
