.Button {
  white-space: nowrap;

  &--style-no-style {
    text-decoration: none;
    border: none;
    padding: 0;
  }

  &--wrap {
    white-space: normal;
  }
}
