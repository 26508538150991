.DonateButton {
  &__container {
    padding: 2.75rem 3.75rem;
    margin-bottom: 1.5rem;
  }

  &__donation-amount-button {
    padding: 0.625rem 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }

  &__headline-container {
    padding-bottom: 1.1875rem;
  }

  &__headline {
    font-size: 3.75rem;
    line-height: 3.75rem;
    text-align: center;
  }

  &__subheadline-container {
    text-align: center;
    font-size: 1.25rem;
    max-width: 630px;

    > div > p {
      line-height: 24px;
      font-weight: 400;
    }

    > div > p:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  &--border-red {
    border: 2px solid var(--red);
  }
}


