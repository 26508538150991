.Nav {
  height: $nav-mobile-height;

  @include media('md-up') {
    min-height: $nav-desktop-height;
  }

  &--side-nav-is-active {
    transform: translate3d($side-nav-mobile-width, 0, 0);

    @include media('md-up') {
      transform: translate3d($side-nav-desktop-width, 0, 0);
    }
  }

  &--style-border-on-desktop {
    border-bottom: 1px solid color('black');
  }

  &--style-border-on-desktop-dark-theme {
    border-bottom: 1px solid color('gray-darker');
  }

  &__simple-logo-icon-button {
    &--style-hide-on-scroll-up {
      opacity: 0;
      pointer-events: none;

      @include media('md-up') {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__simple-logo-icon {
    width: 1.5rem;

    @include media('md-up') {
      width: 1.875rem;
    }
  }

  &__menu-icon-button {
    &--style-hide-on-scroll-up {
      opacity: 0;
      pointer-events: none;

      @include media('md-up') {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__menu-icon {
    width: 1.125rem;

    @include media('md-up') {
      width: 1.4375rem;
    }
  }

  &__logo-icon {
    width: 7rem;

    @include media('md-up') {
      width: 9.875rem;
    }
  }

  &__icon--scroll {
    opacity: 0;
    pointer-events: none;
  }

  &__icon--scroll-active {
    opacity: 1;
    animation: $transition-animate-appear-from-bottom;
    animation-fill-mode: forwards;
    pointer-events: all;
  }

  &__tablet-app {
    background-color: #e73f22;
    color: var(--off-white);
    padding: 11px 24px;
    border-radius: 40px;

    span {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
