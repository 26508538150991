.HolidayStories {
  &__container {
    width: 100%;

    @media (max-width: 767px) {
      width: calc(100% + #{$content-padding-x-sm} * 2);
      margin-left: -$content-padding-x-sm;
      margin-right: -$content-padding-x-sm;
      border-right: 0;
      border-bottom: 0;
    }

    @media (max-width: 1079px) {
      border-right: 0;
    }

    @include media('lg-up') {
      margin-top: 1.5rem;
    }
  }

  &__header-container {
    @media (max-width: 767px) {
      margin: 0 $content-padding-x-sm;
    }

    @media (max-width: 1079px) {
      border-bottom: 0;
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &__title,
  &__link {
    padding-top: 1.8125rem;
    padding-bottom: 0.9375rem;

    @include media('md-up') {
      padding-top: 2.75rem;
      padding-bottom: 1.5rem;
    }

    @include media('lg-up') {
      padding-top: 1.1875rem;
      padding-bottom: 1.5625rem;
    }
  }

  &__teaser-outer-container {
    @media (max-width: 767px) {
      padding-right: $content-padding-x-sm;
    }

    @include media('lg-up') {
      margin: 1.5625rem 0;
    }
  }

  &__teaser-container {
    @media (max-width: 1079px) {
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
      min-width: 19.5625rem;
    }

    @include media('xs-down') {
      width: 19.6825rem;
    }

    &:last-of-type {
      background-image: none;

      @include media('xs-down') {
        padding-left: $content-padding-x-sm;
      }

      @media (max-width: 1079px) {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
      }
    }
  }

  &__teaser-container-two-column {
    padding: 0 $content-padding-x-sm;

    @include media('sm-up') {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }

    @include media('lg-up') {
      padding: 0;
      margin: 0;
    }
  }

  &__article-two-teaser-container {
    @media (max-width: 767px) {
      border: none;
    }
  }

  &__article-two-teaser-container,
  &__article-three-teaser-container {
    @include media('lg-up') {
      margin-top: 3.75rem;
      padding-top: 1.5rem;
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      ) !important;
      background-position: top;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }
  }

  &__article-three-teaser-container {
    @include media('lg-up') {
      padding-bottom: 1.5rem;
    }
  }

  &__one-column-teaser-container {
    @media (max-width: 575px) {
      padding-left: 0 !important;
    }

    @media (max-width: 767px) {
      padding-left: 0.75rem;
    }

    @media (max-width: 1079px) {
      padding-right: 0.75rem;
    }

    @include media('lg-up') {
      background-image: none;
      padding-left: 0;
    }
  }

  &__teaser {
    border: 0;
    margin: 0 !important;

    @media (min-width: 1080px) {
      padding: 0 !important;
    }
  }

  &__teasers {
    @media (max-width: 575px) {
      padding: 0 $content-padding-x-sm;
      margin-top: $content-padding-x-sm;
      margin-bottom: 1.875rem;
    }

    @media (max-width: 1079px) {
      margin-top: 0.75rem;
      margin-bottom: 1.5625rem;
    }
  }

  &__teasers-mobile {
    overflow-x: scroll;
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 $content-padding-x-sm;
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 768px) {
      width: calc(100% + (#{$content-padding-x-md} * 2));
      margin-left: -$content-padding-x-md;
      padding: 0 $content-padding-x-md;
    }

    &__container {
      width: auto;
      margin-top: 1rem;

      @media (min-width: 768px) {
        margin-right: $content-padding-x-md;
      }
    }

    .HolidayStories__teaser {
      width: 45vw;
      min-width: 19.5625rem;
      flex-shrink: 0;
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
      padding: 0 1rem;
      height: auto;

      @media (min-width: 768px) {
        width: 42.5vw;
      }

      &.ImageTextStoryTeaserWidget .ImageTextStoryTeaserWidget__image {
        @media (max-width: 767px) {
          margin-top: 0;
        }
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        background: none;
        padding-right: $content-padding-x-sm;
        width: calc(45vw + #{$content-padding-x-sm});

        @media (min-width: 768px) {
          padding-right: $content-padding-x-md;
          width: calc(42.5vw + #{$content-padding-x-md});
        }
      }
    }
  }

  &__featured-article {
    padding-right: $content-padding-x-sm;

    @media (max-width: 767px) {
      padding-left: 0.75rem;
    }

    @media (max-width: 1079px) {
      padding-right: 0.75rem;
    }

    @include media('lg-up') {
      margin: 1.5625rem 0;
    }
  }

  &__border {
    margin-left: $content-padding-x-sm;
    width: calc(100% - #{$content-padding-x-sm} * 2);
  }
}
