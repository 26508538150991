.LayoutHolidayHero {
  &__banner--red {
    border: solid 2px color('red');
    background-color: color('transparent');
  }

  &__banner--black {
    border: solid 2px color('black');
    background-color: color('black');
  }

  &__banner-detail-text {
    font-size: 1rem;

    @include media('lg-up') {
      font-size: 1.25rem;
    }
  }

  &__countdown {
    text-align: left;
  }

  &__banner-title-text {
    font-size: 1.25rem;
    letter-spacing: 0.041875rem;

    @include media('lg-up') {
      font-size: 1.875rem;
      letter-spacing: 0.0625rem;
    }
  }

  &__container {
    background-image: linear-gradient(
      to right,
      color('black') 24%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    @include media('sm-up') {
      border-right: $border-black;
      background-image: none;
      padding-top: 0;
    }
  }

  &__article1-title {
    background-position: left 97%;

    @include media('xl-up') {
      background-position: left 91%;
    }
  }

  &__article1-container {
    background-image: linear-gradient(
      to right,
      color('black') 24%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;

    @include media('lg-up') {
      background-image: linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 3px;
      background-repeat: repeat-y;
    }
  }

  &__articles-container--4articles {
    position: relative;

    @include media('sm-up') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      &::after {
        content: '';
        position: absolute;
        background-image: linear-gradient(
          to right,
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        ) !important;
        background-position: bottom;
        background-size: 4px 1px;
        background-repeat: repeat-x;
        width: 100%;
        height: 1px;
        top: calc(50% + 0.5rem);
      }
    }

    @include media('lg-up') {
      &::after {
        display: none;
      }
    }
  }

  &__articles-container {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
    width: calc(100% + 0.9375rem * 2);
    padding-bottom: 1rem;
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    @include media('sm-up') {
      padding-bottom: 0;
    }

    @include media('lg-up') {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: auto;
      margin-left: 0;
      margin-right: 0;
      width: 25%;
    }
  }

  &__simple-article {
    background-image: linear-gradient(
      black 33%,
      rgba(255, 255, 255, 0) 0%
    ) !important;
    background-position: right;
    background-size: 1px 3px;
    background-repeat: repeat-y;
    min-width: 11rem;

    @include media('sm-up') {
      min-width: auto;
    }

    @include media('lg-up') {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      ) !important;
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
      width: auto;
    }
  }

  &__simple-article--no-line {
    @include media('sm-up') {
      background-image: none !important;
    }

    @include media('lg-up') {
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      ) !important;
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
      width: auto;
    }
  }

  &__story-article-title {
    @include media('md-down') {
      font-size: 1.3125rem;
      line-height: 1.5rem;
    }
  }

  &__simple-article-description {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('lg-up') {
      font-size: 1.25rem;
      letter-spacing: -0.00625rem;
      line-height: 1.5rem;
    }
  }

  &__articles-container {
    .LayoutHolidayHero__simple-article:last-child {
      background-image: none !important;
    }
  }

  &__articles-container a:first-child {
    .LayoutHolidayHero__simple-article {
      @include media('lg-up') {
        padding-top: 1rem;
      }
    }
  }

  &__banner-detail-button {
    font-size: 1.125rem;
  }
}
