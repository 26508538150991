$tabHeight: 40px;
$tabHeightMd: 50px;
$tabEdgeWidth: 20px;
$tabEdgeWidthMd: 26px;

.Tabs {
  &__buttons {
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 10px;
    margin-left: -0.9375rem;
    padding: 0 0.9375rem;
    width: calc(100% + (0.9375rem * 2));
    -ms-overflow-style: none; /* Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome, Safari */
    &::-webkit-scrollbar {
      display: none;
    }

    @include media('sm-up') {
      margin-bottom: 10px;
    }

    @include media('md-up') {
      margin-left: -4.4375rem;
      margin-bottom: 4px;
      padding: 0 4.4375rem;
      width: calc(100% + (4.4375rem * 2));
    }
  }

  &__buttonsContainer {
    position: relative;
    white-space: nowrap;
    display: inline-block;
    min-width: 100%;

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 20;
      border-bottom: 1px solid;
    }
  }

  &__button {
    cursor: pointer;
  }

  &__content {
    @include media('sm-up') {
      padding-top: 15px;
    }
    @include media('md-up') {
      padding-top: 20px;
    }
  }
}

.Tabs__button {
  &--rounded {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid color('black');
    transform: translateY(1px);
    background: transparent;
    white-space: normal;
    color: color('gray-darker');
    transition: color $transition-duration-short;
    position: relative;
    vertical-align: text-bottom;
    height: 4.75rem;
    padding: 12px 60px;
    height: 110px;
    margin-left: -1.5rem;
    width: 300px;

    @include media('lg-up') {
      height: 116px;
      width: 298px;
      padding: 18px 60px;
      margin-left: -2rem;
    }

    @include media('xl-up') {
      padding: 18px 60px 20px;
      height: 134px;
      width: 368px;

      .Tabs__button--inner {
        min-height: 48px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    &.Tabs__button--active {
      color: black;

      .Tabs__roundedOutline {
        color: black;
      }
    }

    .Tabs__roundedOutline {
      width: 103%;
      height: 100%;
      position: absolute;
      left: -1.5%;
      top: 0;
      z-index: -1;
      color: color('gray');

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 260%;
        top: 0;
        left: 0;
        border: 1px solid currentColor;
        background: color('beige');
        border-radius: 50%;
      }

      @include media('xl-up') {
        left: -5px;
      }
    }

    .Tabs__buttonText {
      font-size: 20px;
      letter-spacing: 1px;
      font-family: $itc-cushing;
      font-weight: 800;
      text-transform: uppercase;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      color: color('black');
    }

    &--active {
      border-color: color('black');
      border-bottom-color: color('beige');
      color: color('black');
      z-index: 30 !important;
    }
  }

  &--folder {
    padding: 0;
    border-bottom-width: 0px;
    margin-bottom: -1px;
    margin-left: -20px;
    height: calc(#{$tabHeight} + 1px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: transparent;

    .Tabs__buttonText {
      position: relative;
      z-index: 10;
      font-size: 18px;
      padding: 0 calc(#{$tabEdgeWidth} + 27px);
      font: $graebenbach;
      font-weight: 800;

      @include media('md-up') {
        padding: 0 calc(#{$tabEdgeWidthMd} + 27px);
      }
    }
    @include media('md-up') {
      min-width: 220px;
      height: calc(#{$tabHeightMd} + 1px);
    }

    &:first-child {
      margin-left: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: $tabEdgeWidth;
      height: calc(100% + 2px);
      width: calc(100% - (#{$tabEdgeWidth} * 2));
      border-top: 1px solid currentColor;
      background-color: #efe9dc;
      z-index: 4;

      @include media('md-up') {
        left: $tabEdgeWidthMd;
        width: calc(100% - (#{$tabEdgeWidthMd} * 2));
      }
    }

    .Tabs__buttonFolder--left {
      position: absolute;
      left: $tabEdgeWidth;
      top: 1px;

      @include media('md-up') {
        left: $tabEdgeWidthMd;
      }

      &:before,
      &:after {
        content: '';
        width: 0;
        height: 0;
        top: -2px;
        position: absolute;
        border-style: solid;
      }

      &:before {
        right: -1px;
        border-width: 0 0 calc(#{$tabHeight} + 3px) calc(#{$tabEdgeWidth} + 2px);
        border-color: transparent transparent #efe9dc transparent;
        z-index: 2;

        @include media('md-up') {
          border-width: 0 0 calc(#{$tabHeightMd} + 3px)
            calc(#{$tabEdgeWidthMd} + 2px);
        }
      }

      &:after {
        right: 0px;
        border-width: 0 0 calc(#{$tabHeight} + 1px) calc(#{$tabEdgeWidth} + 1px);
        border-color: transparent transparent currentColor transparent;
        z-index: 1;

        @include media('md-up') {
          border-width: 0 0 calc(#{$tabHeightMd} + 1px)
            calc(#{$tabEdgeWidthMd} + 1px);
        }
      }
    }
    .Tabs__buttonFolder--right {
      position: absolute;
      right: 0;
      top: 1px;

      &:before,
      &:after {
        content: '';
        width: 0;
        height: 0;
        top: -2px;
        position: absolute;
        border-style: solid;
      }

      &:before {
        right: -2px;
        border-color: transparent transparent transparent #efe9dc;
        z-index: 2;
        border-width: calc(#{$tabHeight} + 5px) 0 0 calc(#{$tabEdgeWidth} + 3px);
        @include media('md-up') {
          border-width: calc(#{$tabHeightMd} + 5px) 0 0
            calc(#{$tabEdgeWidthMd} + 3px);
        }
      }

      &:after {
        right: -1px;
        border-color: transparent transparent transparent currentColor;
        z-index: 1;
        border-width: calc(#{$tabHeight} + 1px) 0 0 calc(#{$tabEdgeWidth} + 1px);
        @include media('md-up') {
          border-width: calc(#{$tabHeightMd} + 1px) 0 0
            calc(#{$tabEdgeWidthMd} + 1px);
        }
      }
    }
  }
}
