.SectionTopNav {
  &__logo {
    animation: $transition-animate-disappear-to-top;
    animation-fill-mode: forwards;
    pointer-events: none;
    top: 1.5rem;
  }

  &__logo--active {
    animation: $transition-animate-appear-from-bottom;
    pointer-events: all;
    animation-fill-mode: forwards;
  }

  &__section-info {
    animation: $transition-animate-disappear-to-bottom;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  &__section-info--active {
    animation: $transition-animate-appear-from-top;
    animation-fill-mode: forwards;
    pointer-events: all;
    top: 1.5rem;
  }

  &__section-logo {
    height: 0.8125rem;

    @include media('md-up') {
      opacity: 1;
    }
  }

  &__logo-icon {
    height: 1.05625rem;

    @include media('md-up') {
      height: 1.49125rem;
    }
  }
}
