.ImageTextIntroStoryTeaserWidget {
  &__dek {
    padding-top: 0.675rem;
  }

  &--medium,
  &--small {
    padding-left: 0;
  }

  // &--small {
  //   padding-bottom: 1.625rem;

  //   @include media('sm-up') {
  //     padding-bottom: 2.75rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__section-button {
  //     padding-top: 2.125rem;

  //     @include media('sm-up') {
  //       padding-top: 0.875rem;
  //     }
  //   }

  //   .ImageTextIntroStoryTeaserWidget__title {
  //     font-size: 1.875rem;
  //     line-height: 2rem;
  //     padding-top: 1.1875rem;

  //     @include media('sm-up') {
  //       font-size: 1.75rem;
  //       line-height: 1.875rem;
  //       padding-top: 1.375rem;
  //     }

  //     @include media('xl-up') {
  //       font-size: 1.875rem;
  //       line-height: 2rem;
  //       padding-top: 1.3125rem;
  //     }
  //   }

  //   .ImageTextIntroStoryTeaserWidget__authors {
  //     padding-top: 1rem;

  //     @include media('sm-up') {
  //       padding-top: 1.125rem;
  //     }

  //     @include media('xl-up') {
  //       padding-top: 0.8125rem;
  //     }
  //   }
  // }

  &--medium {
    padding-bottom: 3rem;
    padding-top: 1rem;

    @include media('sm-up') {
      padding-top: 0rem;
    }

    .ImageTextStoryTeaserWidget__image {
      vertical-align: middle;
      margin-bottom: 0.5rem;
    }

    .ImageTextIntroStoryTeaserWidget__section-button {
      padding-top: 1.5rem;
    }

    .ImageTextIntroStoryTeaserWidget__container {
      text-align: left;

      @include media('sm-up') {
        text-align: center;
      }
    }

    .ImageTextIntroStoryTeaserWidget__title {
      padding-top: 1rem;
      font-size: 1.875rem;
      line-height: 2rem;

      @include media('sm-up') {
        font-size: 1.75rem;
        line-height: 1.875rem;
      }

      @include media('lg-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__dek {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-top: 0.5rem;
      text-align: left;

      @include media('sm-up') {
        text-align: center;
      }
    }

    .ImageTextIntroStoryTeaserWidget__authors {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.0625rem;
      padding-top: 1rem;
      padding-bottom: 0rem;

      @include media('sm-up') {
        padding-bottom: 1.5rem;
      }
    }
  }

  // &--large {
  //   padding-bottom: 3rem;

  //   @include media('sm-up') {
  //     padding-bottom: 4.5rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__section-button {
  //     padding-top: 2rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__title {
  //     font-size: 3rem;
  //     line-height: 3rem;
  //     padding-top: 1.5rem;
  //     letter-spacing: -0.015625rem;

  //     @include media('lg-up') {
  //       font-size: 3.75rem;
  //       line-height: 3.75rem;
  //       letter-spacing: -0.046875rem;
  //     }
  //   }

  //   .ImageTextIntroStoryTeaserWidget__dek {
  //     padding-top: 1rem;
  //     font-size: 1.25rem;
  //     line-height: 1.5rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__authors {
  //     font-weight: 700;
  //     padding-top: 1rem;
  //     line-height: 1.125rem;

  //     .AuthorButtons__button-text {
  //       line-height: 1.125rem;
  //     }
  //   }
  // }

  // &--x-large {
  //   padding-top: 0.5rem;
  //   padding-bottom: 3rem;

  //   @include media('sm-up') {
  //     padding-top: 0;
  //     padding-bottom: 4.5rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__section-button {
  //     padding-top: 1.5rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__title {
  //     padding-top: 1.5rem;
  //     font-size: 3rem;
  //     line-height: 3rem;
  //     letter-spacing: -0.015625rem;

  //     @include media('md-up') {
  //       font-size: 3.75rem;
  //       line-height: 3.75rem;
  //       letter-spacing: -0.046875rem;
  //     }

  //     @include media('xl-up') {
  //       font-size: 4.5rem;
  //       line-height: 4.5rem;
  //       letter-spacing: -0.046875rem;
  //     }
  //   }

  //   .ImageTextIntroStoryTeaserWidget__dek {
  //     padding-top: 1rem;
  //     font-size: 1.25rem;
  //     line-height: 1.5rem;
  //   }

  //   .ImageTextIntroStoryTeaserWidget__authors {
  //     padding-top: 1rem;
  //     font-weight: 700;
  //     .AuthorButtons__button-text,
  //     .byline {
  //       line-height: 1.125rem;
  //     }

  //     @include media('sm-up') {
  //       padding-top: 1rem;
  //     }
  //   }
  // }

  &--split-layout {
    .ImageTextIntroStoryTeaserWidget__title {
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  &__body {
    padding-top: 1.188rem;
  }
  &__intro-container {
    display: none;

    @include media('sm-up') {
      display: block;
    }
  }

  &__body-container {
    display: none;

    @include media('sm-up') {
      text-align: left;
      display: block;

      > p:nth-child(1)::first-letter {
        @include dropcaps();
        padding-top: 0rem;
        font-size: 5.25rem !important;
        line-height: 5.25rem !important;
        padding-right: 2.5rem !important;

        @include media('sm-up') {
          padding-bottom: 5.125rem;
        }

        @include media('md-up') {
          padding-bottom: 6.625rem;
        }

        @include media('xl-up') {
          padding-bottom: 3.625rem;
        }
      }

      &:hover {
        .ImageTextIntroStoryTeaserWidget__continue-reading {
          text-decoration-color: color('transparent');
        }
      }
    }
  }

  &__body-container--two-column {
    @include media('lg-up') {
      overflow: hidden;
      column-count: 2;
      column-gap: 3rem;
      column-width: auto;
    }
  }

  &__body-container--two-column::after {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--three-column {
    @include media('lg-up') {
      overflow: hidden;
      column-count: 3;
      column-gap: 3rem;
      column-width: auto;
    }
  }

  &__body-container--three-column::before {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(33.333% - 0.5rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--three-column::after {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(66.666% + 0.5rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--four-column {
    @include media('lg-up') {
      overflow: hidden;
      column-count: 4;
      column-gap: 3rem;
      column-width: auto;
    }
  }

  &__body-container--four-column::before {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(25% - 0.75rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__body-container--four-column::after {
    @include media('lg-up') {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: calc(50% - 0.25rem);
      width: 1px;
      margin-top: 1rem;
      height: 100%;
      background-image: linear-gradient(
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: left;
      background-size: 1px 4px;
      background-repeat: repeat-y;
    }
  }

  &__continue-reading {
    @extend .transition-short;
    text-decoration: underline;
    text-decoration-color: color('black');
  }

  &--split-layout {
    @media (max-width: 767px) {
      padding-bottom: 3rem;
    }

    .ImageTextIntroStoryTeaserWidget__image {
      @media (max-width: 767px) {
        margin-top: 0.9375rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__title-container {
      @media (max-width: 767px) {
        margin-top: 1.4375rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__title {
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .ImageTextIntroStoryTeaserWidget__authors {
      @include media('sm-up') {
        padding-top: 1rem;
      }
    }
  }
}
