.PodcastHeader {
  @media (max-width: 374px) {
    padding-bottom: 1.875rem;
    margin-bottom: $content-padding-x-sm;
  }

  padding-bottom: 1.4375rem;
  margin-bottom: 0.9375rem;

  @include media('sm-up') {
    padding-bottom: 2.5rem;
    margin-bottom: 1.5rem;
  }

  @include media('lg') {
    padding-bottom: 4.25rem;
  }

  @include media('xl-up') {
    padding-bottom: 5.75rem;
  }
}
