.Accordion {
  margin-bottom: 1.75rem;
  padding: 0.45rem 2rem 0 2rem;

  @include media('md-up') {
    padding: 0.45rem 5rem 0 5rem;
  }

  &__content {
    a {
      @extend %linked-border-bottom-black;
      color: color('black');
      text-decoration: none;
    }

    @include media('lg-up') {
      + .article-content-container {
        margin-top: 2.75rem;
      }
    }

    @include media('xl-up') {
      + .article-content-container {
        margin-top: 4rem;
      }
    }
  }

  &__read-more {
    display: inline;
    background-color: transparent;

    .underline {
      text-decoration: underline;
    }
  }

  &__button {
    white-space: initial;
    background-color: transparent;
    justify-content: space-between;
    color: #dd3418;
  }

  &__icon {
    font-size: 1.875rem;
  }

  &__title {
    max-width: 90%;
    text-align: start;
  }

  .BlockRichText {
    font-family: $bradford;
    font-size: 1.25rem;
    line-height: 1.875rem;

    p {
      padding: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @include media('md-up') {
      font-size: 1.5rem;
    }

    @include media('lg-up') {
      font-size: 1.75rem;
      line-height: 1.5;
    }
  }

  &__divider .Divider--dotted-rule {
    margin: 2.25rem 2rem;

    @include media('md-up') {
      margin: 2.25rem 5rem;
    }

    @include media('lg-up') {
      margin: 2.25rem 0;
    }
  }

  .transition-open-search {
    animation: $transition-animate-appear-from-bottom;
    animation-fill-mode: forwards;
    pointer-events: all;
    height: auto;
    opacity: 1;
    transition-property: height, opacity;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }

  .transition-close-search {
    animation: $transition-animate-disappear-to-top;
    animation-fill-mode: forwards;
    pointer-events: none;
    height: 0;
    opacity: 0;
    transition-property: height, opacity;
    transition-delay: 0.5s;
    transition-duration: 0.5s;
  }
}
