.PodcastEpisodeGridItems {
  &__title-container {
    @include media('xs-down') {
      margin-bottom: $content-padding-x-sm;
    }

    a {
      color: inherit;
    }
  }

  &__title-container--no-title {
    @include media('md-up') {
      border-bottom: none;
    }
  }

  &__more-episode-title {
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;

    @include media('md-up') {
      font-size: 1.5rem;
      letter-spacing: 0.075rem;
    }
  }

  &__sound-icon {
    width: 1.6rem;
  }

  &__episode-container {
    border-bottom: $border-gray-darker;

    @include media('xs-down') {
      margin-bottom: $content-padding-x-sm;
    }

    @include media('md-up') {
      border-right: $border-gray-darker;
    }

    &:hover {
      .PodcastEpisodeGridItems__episode-image {
        opacity: 0.6;
      }

      .PodcastEpisodeGridItems__sound-icon {
        opacity: 1;
      }
    }
  }

  &__episode-container:last-of-type {
    margin-bottom: 0;
  }

  &__episode-info-container {
    @include media('md-up') {
      margin-top: 0.3125rem;
    }
  }

  &__pagination {
    height: 5rem;

    &__arrow {
      width: 1rem;
      transform: rotate(-90deg);
    }
  }
}
