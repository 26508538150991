.LayoutHero2 {
  &__excerpt-container {
    @include media('sm-up') {
      column-count: 2;
      column-gap: 3rem;
    }

    @include media('md-up') {
      column-count: 3;
      column-gap: 3rem;
    }

    @include media('lg-up') {
      column-count: 4;
      column-gap: 3rem;
    }

    &:hover {
      .LayoutHero2__continue-reading {
        text-decoration-color: color('transparent');
      }
    }

    &.fiction-teaser-border {
      &::before,
      &::after {
        background-image: linear-gradient(
          color('fiction-pink') 24%,
          rgba(255, 255, 255, 0) 0%
        );
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(
        to right,
        color('black') 24%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 4px 1px;
      background-repeat: repeat-x;
    }

    &::after {
      @include media('sm-up') {
        content: '';
        display: block;
        position: absolute;
        top: 2.25rem;
        left: 50%;
        width: 1px;
        height: calc(100% - 2.25rem);
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }

      @include media('md-up') {
        display: none;
      }

      @include media('lg-up') {
        display: none;
        display: block;
      }
    }
  }

  &__excerpt.fiction-teaser-border::before,
  &__excerpt.fiction-teaser-border::after {
    background-image: linear-gradient(
      color('fiction-pink') 24%,
      rgba(255, 255, 255, 0) 0%
    );
  }

  &__excerpt {
    &::before {
      @include media('md-up') {
        content: '';
        display: block;
        position: absolute;
        top: 2.25rem;
        right: calc(33.33% - 0.75rem);
        width: 1px;
        height: calc(100% - 2.25rem);
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }

      @include media('lg-up') {
        right: calc(25% - 0.75rem);
      }
    }

    &::after {
      @include media('md-up') {
        content: '';
        display: block;
        position: absolute;
        top: 2.25rem;
        left: calc(33.33% - 0.75rem);
        width: 1px;
        height: calc(100% - 2.25rem);
        background-image: linear-gradient(
          color('black') 24%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: left;
        background-size: 1px 4px;
        background-repeat: repeat-y;
      }

      @include media('lg-up') {
        left: calc(25% - 0.75rem);
      }
    }
  }

  &__continue-reading {
    @extend .transition-short;
    text-decoration: underline;
    text-decoration-color: color('black');
  }
}
