.TextStoryTeaserWidget {
  &__dek {
    padding-top: 0.675rem;
  }

  &--medium,
  &--small {
    padding-left: 0;
  }

  &--small {
    padding-bottom: 1.625rem;

    @include media('sm-up') {
      padding-bottom: 2.75rem;
    }

    .TextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;
    }

    .TextStoryTeaserWidget__title {
      font-size: 1.875rem;
      line-height: 2rem;
      padding-top: 1.1875rem;

      @include media('sm-up') {
        font-size: 1.75rem;
        line-height: 1.875rem;
        padding-top: 1.375rem;
      }

      @include media('xl-up') {
        font-size: 1.875rem;
        line-height: 2rem;
        padding-top: 1.3125rem;
      }
    }

    .TextStoryTeaserWidget__authors {
      padding-top: 1rem;
      padding-bottom: 2rem;

      @include media('sm-up') {
        padding-top: 1.125rem;
      }

      @include media('xl-up') {
        padding-top: 0.8125rem;
      }
    }
  }

  &--medium {
    padding-bottom: 3rem;
    padding-top: 1rem;

    @include media('sm-up') {
      padding-top: 0rem;
    }

    .TextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;
    }

    .TextStoryTeaserWidget__title {
      padding-top: 1rem;
      font-size: 1.875rem;
      line-height: 2rem;

      @include media('sm-up') {
        font-size: 1.75rem;
        line-height: 1.875rem;
      }

      @include media('lg-up') {
        font-size: 1.875rem;
        line-height: 2rem;
      }
    }

    .TextStoryTeaserWidget__dek {
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-top: 0.5rem;
    }

    .TextStoryTeaserWidget__authors {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.125rem;
      letter-spacing: 0.0625rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
    }
  }

  &--large {
    padding-bottom: 3rem;
    border-bottom: $border-black;

    @include media('sm-up') {
      padding-bottom: 4.5rem;
    }

    .TextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;
    }

    .TextStoryTeaserWidget__title {
      font-size: 3rem;
      line-height: 3rem;
      padding-top: 1.5rem;
      letter-spacing: -0.015625rem;
    }

    .TextStoryTeaserWidget__dek {
      padding-top: 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .TextStoryTeaserWidget__authors {
      font-weight: 700;
      padding-top: 1rem;
      line-height: 1.125rem;
      padding-bottom: 2rem;

      .AuthorButtons__button-text {
        line-height: 1.125rem;
      }
    }
  }

  &--x-large {
    padding-top: 0.5rem;
    padding-bottom: 3rem;

    @include media('sm-up') {
      padding-top: 0;
      padding-bottom: 4.5rem;
    }

    .TextStoryTeaserWidget__section-button {
      padding-top: 1.5rem;
    }

    .TextStoryTeaserWidget__title {
      padding-top: 1.5rem;
      font-size: 3rem;
      line-height: 3rem;
      letter-spacing: -0.015625rem;

      @include media('md-up') {
        font-size: 3.75rem;
        line-height: 3.75rem;
        letter-spacing: -0.046875rem;
      }

      @include media('xl-up') {
        font-size: 4.5rem;
        line-height: 4.5rem;
        letter-spacing: -0.046875rem;
      }
    }

    .TextStoryTeaserWidget__dek {
      padding-top: 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    .TextStoryTeaserWidget__authors {
      padding-top: 1rem;
      padding-bottom: 2rem;
      font-weight: 700;

      .AuthorButtons__button-text,
      .byline {
        line-height: 1.125rem;
      }

      @include media('sm-up') {
        padding-top: 1rem;
      }
    }
  }

  &--split-layout {
    .TextStoryTeaserWidget__title {
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    .TextStoryTeaserWidget__dek {
      @media (max-width: 767px) {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    .TextStoryTeaserWidget__authors {
      @media (max-width: 767px) {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
      }
    }
  }
}
