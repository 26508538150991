.collections-8-stories-widget {
  .collections-4-stories {
    padding: 1rem 1rem 2rem 1rem;

    @include media('sm-up') {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
    }

    &__container {
      @include media('xs-up') {
        display: grid;
      }

      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-template-rows: auto;

      @include media('sm-up') {
        padding-top: 1.5rem;
        column-gap: 1.5rem;
      }

      .teaser-widget-container {
        padding: 0;
        margin: 0;
        border: none;
      }

      @include media('sm-up') {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(3, minmax(0, auto));
      }

      @include media('lg-up') {
        grid-template-columns: repeat(6, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, auto));
        column-gap: 0rem;
      }

      &__article1-container {
        grid-column: 1/2;
        grid-row: 3/4;
        border-bottom: $border-black-dotted;
        padding-bottom: 4rem;

        @include media('lg-up') {
          border-bottom: none;
          padding-left: 0.75rem;
          border-left: $border-black-dotted;
        }

        @include media('sm-up') {
          grid-column: 1/3;
          grid-row: 1/2;
          padding-bottom: 6rem;
          padding-top: 0;
        }

        @include media('lg-up') {
          grid-column: 3/7;
          grid-row: 1/3;
        }
      }

      &__article2-container {
        padding-right: 0.75rem;
        border-bottom: none;
        position: relative;

        @include media('sm-up') {
          grid-column: 1/2;
          grid-row: 2/4;
          padding-top: 0rem;
          padding-bottom: 3rem;
          padding-right: 0rem;
          margin-right: 0rem;
          margin-top: 1.5rem;

          &::after {
            content: '';
            position: absolute;
            right: -0.75rem;
            top: 0rem;
            height: calc(100%);
            border-right: $border-black-dotted;
          }
        }

        @include media('lg-up') {
          grid-column: 1/2;
          grid-row: 1/2;
          padding-top: 0;
          border-bottom: $border-black-dotted;
          border-right: none;
          padding-right: 0rem;
          margin-top: 0;

          &::after {
            border-right: none;
          }
        }
      }

      &__article3-container {
        border-left: none;

        @include media('sm-up') {
          grid-column: 2/2;
          grid-row: 3/4;
          padding-top: 1.5rem;
          padding-right: 0.75rem;
          padding-bottom: 3rem;
          margin-top: 0;
          border-top: $border-black-dotted;
          padding-left: 0;
        }

        @include media('lg-up') {
          grid-column: 2/3;
          grid-row: 1/2;
          margin-top: 0;
          padding-left: 0;
          border-top: none;
        }
      }

      &__article4-container {
        grid-column: 1/2;
        grid-row: 2/3;
        padding-bottom: 3rem;
        border-top: $border-black-dotted;
        padding-top: 1rem;

        @include media('sm-up') {
          border-top: none;
          padding-top: 0;
          margin-top: 1.5rem;
          padding-left: 0;
          border-left: none;
        }

        @include media('sm-up') {
          grid-column: 2/3;
          grid-row: 2/3;
        }

        @include media('lg-up') {
          grid-column: 1/3;
          grid-row: 2/3;
          border-left: none;
          border-top: $border-black-dotted;
          padding-top: 1.5rem;
          padding-left: 0;
          margin-right: 0.75rem;
        }
      }

      &__article2-article3-container {
        grid-column: 1/2;
        grid-row: 1/2;

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: auto;
        padding-bottom: 1rem;
        padding-top: 1rem;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 0.9375rem;
          height: calc(100% - 1rem - 0.9375rem);
        }
        @include media('sm-up') {
          display: none;
        }
        .non-main-teaser {
          padding-bottom: 4rem;
        }
        .col1 {
          padding-right: 0.75rem;
          border-right: $border-black-dotted;
        }
        .col2 {
          padding-left: 0.75rem;
        }
      }
    }
  }

  .collections-4-stories-widget__container {
    margin-top: 1.5rem;
    border-top: $border-black-dotted;

    &__article1-container {
      @include media('md') {
        padding-top: 0;
      }
      @include media('lg-up') {
        padding-top: 0.5rem;
      }
    }
  }

  .collections-4-stories__container {
    @include media('lg-up') {
      grid-template-rows: repeat(3, minmax(0, auto));
      grid-gap: 0.75rem;
    }
  }

  &__container {
    padding: 1rem 1rem 2rem 1rem;

    @include media('sm-up') {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
    }
  }
  .collections-4-stories__container__article1-container {
    @include media('lg-up') {
      grid-column: 1/5;
      border-right: $border-black-dotted;
      grid-row: 1/4;
      padding-left: 0;
      border-left: 0;
      padding-right: 0.75rem;
    }
  }
  .collections-4-stories__container__article2-container {
    @include media('lg-up') {
      grid-column: 5/7;
      border-bottom: $border-black-dotted;
      padding-left: 0;
    }
  }
  .collections-4-stories__container__article3-container {
    @include media('lg-up') {
      grid-column: 5/7;
      grid-row: 2/2;
      border-left: none;
      padding-left: 0;
    }
  }
  .collections-4-stories__container__article4-container {
    @include media('lg-up') {
      grid-column: 5/7;
      grid-row: 3/3;
      margin: 0;
    }
  }
}
