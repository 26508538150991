.ListWidget {
  &__paragraph {
    > p {
      display: inline;
    }

    > div {
      display: inline;

      > p:first-child {
        display: inline;
      }

      > p:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }

  &__items {
    li {
      list-style-type: none;

      &:last-child {
        background-image: none;
      }
    }

    &--bullet-list {
      .ListWidget__unordered-list-item::before {
        content: '';
        background: color('red');
        float: left;
        height: 12px;
        width: 12px;
        min-height: 12px;
        max-height: 12px;
        min-width: 12px;
        max-width: 12px;
        border-radius: 50%;
        margin-right: 0.375rem;
        margin-top: 0.2rem;
      }
    }

    .ImageCreditLink {
      color: gray;
    }
  }

  &__item {
    &:last-child {
      background-image: none;
    }

    > li > p {
      margin: 0 auto;
    }
  }
}
