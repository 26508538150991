.HomeNav {
  border-bottom: $border-black;
  padding-bottom: 0.625rem;

  @include media('sm-up') {
    padding-bottom: 0.4375rem;
  }

  @include media('md') {
    padding-bottom: 1.3125rem;
  }

  @include media('xl-up') {
    padding-bottom: 1.25rem;
  }

  &__logo-button,
  &__holiday-logo-button,
  &__featured-logo-button {
    margin: 4.0625rem 0 0;

    > svg {
      height: 2.625rem;
    }

    @include media('sm-up') {
      margin: 4.5625rem 0 0;

      > svg {
        height: 3.375rem;
      }
    }

    @include media('md-up') {
      margin: 5.0625rem 0 0;

      > svg {
        height: 4.5rem;
      }
    }

    @include media('lg-up') {
      margin: 5.605rem 0 0;

      > svg {
        height: 5.25rem;
      }
    }

    @include media('xl-up') {
      > svg {
        height: 6rem;
      }
    }

    &.tagline-spacing {
      margin: 1.9375rem 0 0;

      @include media('sm-up') {
        margin: 0.9375rem 0 0;
      }

      @include media('md-up') {
        margin: 1.3125rem 0 0;
      }

      @include media('lg-up') {
        margin: 2.25rem 0 0;
      }

      @include media('xl-up') {
        margin: 3.625rem 0 0;
      }
    }
  }

  &__featured-logo-button {
    width: 100%;
  }

  &__logo {
    height: 2.625rem;
    width: auto;

    @include media('xs-up') {
      height: 3.375rem;
    }

    @include media('sm-up') {
      height: 4.5rem;
    }

    @include media('md-up') {
      height: 5.25rem;
    }

    @include media('xl-up') {
      height: 6rem;
    }
  }

  &__detail {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 1.25rem;

    @include media('xs-up') {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-top: 2rem;
    }

    @include media('sm-up') {
      font-size: 1rem;
    }

    @include media('md') {
      margin-top: 1.85rem;
    }

    @include media('xl-up') {
      font-size: 1.125rem;
    }
  }

  &__date-container {
    padding-top: 1.125rem;

    @include media('sm-up') {
      padding-top: 1.25rem;
    }

    @include media('md-up') {
      padding-top: 2rem;
    }

    @include media('md') {
      padding-top: 1.875rem;
    }

    @include media('xl-up') {
      padding-top: 2.75rem;
    }
  }

  &__date,
  &__hebrew-date {
    font-size: 0.8125rem;
    line-height: 1.25rem;

    @include media('md-up') {
      font-size: 0.875rem;
    }

    @include media('xl-up') {
      font-size: 1rem;
    }
  }

  &__date {
    letter-spacing: 0.046875rem;

    @include media('md-up') {
      letter-spacing: 0.0625rem;
    }
  }

  &__dot {
    background-color: color('black');
    width: 4px;
    height: 4px;
  }
}
