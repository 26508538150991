.PageNotFound {
  &__container {
    max-width: 42rem;
  }

  &__title {
    font-size: 3.75rem;
  }

  &__image {
    max-width: 42rem;
  }
}
