.InsetImg {
  margin-top: $content-padding-x-sm;

  @media (min-width: 375px) {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding-bottom: 6rem;
    padding-top: 0;
  }

  &__caption-container {
    line-height: 1rem;
  }

  &__caption {
    @include media('lg') {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &__credit {
    line-height: 1.25rem;

    @include media('lg') {
      letter-spacing: 0.0625rem;
      line-height: 1.5rem;
    }
  }

  &--left,
  &--right {
    width: 100%;

    @media (min-width: 375px), print {
      width: calc(100% / 12 * 6 - (1.5rem / 2));
    }

    @media (min-width: 1440px) {
      width: calc(100% / 12 * 5 - (1.5rem / 2));
    }
  }

  &--right {
    @media (min-width: 375px), print {
      border-left: $border-black;
      float: right;
      margin-left: 1.5rem;
      padding-left: 1.5rem;
    }

    @media (min-width: 1440px) {
      margin-right: calc(100% / 12);
    }
  }

  &--right-black {
    @media (min-width: 375px) {
      border-left: $border-gray-darker;
    }
  }

  &--left {
    @media (min-width: 375px), print {
      border-right: $border-black;
      float: left;
      margin-right: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (min-width: 1440px) {
      margin-left: calc(100% / 12);
    }
  }

  &--left-black {
    @media (min-width: 375px) {
      border-right: $border-gray-darker;
    }
  }

  &--center {
    &.InsetImage--show-rules {
      @media (min-width: 375px) {
        border-bottom: $border-black;
        border-top: $border-black;
        padding-top: 1.5rem;
      }
    }

    @media (min-width: 1440px) {
      margin-left: calc(100% / 12);
      margin-right: calc(100% / 12);
      width: calc(100% / 12 * 10);
    }
  }

  &--center-black {
    &.InsetImage--show-rules {
      @media (min-width: 375px) {
        border-bottom: $border-gray-darker;
        border-top: $border-gray-darker;
      }
    }
  }

  &--full-bleed {
    width: 100vw;
    overflow: hidden;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: auto;
  }

  // prevent inset images from wrapping across page breaks
  @media print {
    page-break-inside: avoid;
  }
}
