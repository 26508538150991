.IndividualHolidayLayout {
  .teaser-widget-container {
    border-bottom: $border-black;
    border-right: $border-black;
    padding: 0 1.5rem 3rem 0;
  }

  &__hero {
    padding-top: 1.75rem;
    border-bottom: 1px solid;
    @include media('sm-up') {
      border-bottom: none;
    }
    @include media('md-up') {
      padding-top: 1.95rem;
    }
  }

  @include media('md-up') {
    padding-bottom: 0;
  }

  &__tag-link {
    font-size: 20px;
    margin-top: 2rem;
    @include media('md-up') {
      margin-top: 2.6rem;
      margin-bottom: 0rem;
    }
  }

  &__tabs {
    margin-top: 2rem;
    @include media('sm-up') {
      margin-top: 3rem;
    }
    @include media('md-up') {
      margin-top: 5rem;
    }
    @include media('lg-up') {
      margin-top: 8rem;
    }
  }

  //Removes top dotted border on the first article in the second column
  &__what-to-do-container {
    > div:nth-child(1) > :nth-child(2) > :nth-child(3) > :first-child() {
      background-image: none;
    }
  }
}

.ContentTab {
  &__column:nth-child(n + 2) {
    .teaser-widget-container {
      height: auto;
      flex-grow: 1;
    }
  }

  @include media('sm-down') {
    padding-bottom: 25px;
    border-bottom: 1px solid;
    .ContentTab__column:first-child {
      border-bottom: 1px solid;
    }
    .ContentTab__column:nth-child(2) {
      .teaser-widget-container:nth-child(1) {
        margin-right: 0;
        padding-right: 0.75rem;
        border-right: 1px solid;
      }
      .teaser-widget-container:nth-child(2) {
        margin-left: 0.75rem;
      }
    }
  }
}

.HolidayContentTab {
  &__allStories {
    margin-top: 4.5rem;
    margin-bottom: 0.5rem;
    @include media('md-up') {
      margin-top: 4rem;
      margin-bottom: 1.1rem;
    }
    @include media('lg-up') {
      margin-top: 4rem;
    }
    @include media('xl-up') {
      margin-top: 4rem;
    }
  }
}
