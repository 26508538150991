.PodcastGridItems {
  &__podcast-container {
    flex-grow: 1;
    width: calc(50% - 0.9375rem);
    padding-bottom: 2.9205rem;
    margin-bottom: $content-padding-x-sm;

    @include media('md-up') {
      border-bottom: $border-gray-darker;
      border-right: $border-gray-darker;
      margin-bottom: 1.5rem;
      width: calc(50% - 0.75rem);
    }
  }

  &__podcast-inner-container {
    flex-direction: column;

    @include media('md-up') {
      flex-direction: row;
    }
  }

  &__podcast-image-container {
    @include media('md-up') {
      min-width: 30%;
      max-width: 30%;
      margin-right: 1.5rem;
    }
  }

  &__podcast-image {
    @include media('md-up') {
      filter: grayscale(1);
      transition: $transition-duration-medium $transition-easing;
    }
  }

  &__podcast-container:hover {
    .PodcastGridItems__podcast-image {
      @include media('md-up') {
        filter: grayscale(0);
      }
    }

    .PodcastGridItems__title {
      background-size: 100% 1px;
    }
  }

  &__podcast-container:nth-child(even) {
    border-right: $border-gray-darker;

    @include media('sm-down') {
      padding-right: 0.9375rem;
    }

    @include media('md-up') {
      margin-right: 0.75rem;
    }
  }

  &__podcast-container:nth-child(odd) {
    @include media('sm-down') {
      padding-left: 0.9375rem;
    }

    @include media('md-up') {
      margin-left: 0.75rem;
    }
  }

  &__podcast-container:first-child {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    border-bottom: $border-gray-darker;

    @include media('md-up') {
      width: 100%;
      padding-right: 1.5rem;
    }

    .PodcastGridItems__title {
      @include media('md-up') {
        &::after {
          bottom: 0.5rem;
        }
      }
    }

    .PodcastGridItems__podcast-image-container {
      @include media('md-up') {
        width: 50%;
      }
    }

    .PodcastGridItems__podcast-info-container {
      padding: 0 $content-padding-x-sm;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include media('md-up') {
        width: 50%;
      }
    }

    .PodcastGridItems__podcast-image-container {
      margin-right: 0;

      @include media('md-up') {
        min-width: 50%;
        max-width: 50%;
      }
    }

    .PodcastGridItems__title {
      font-size: 3rem;
      line-height: 3rem;

      @include media('lg-up') {
        font-size: 3.75rem;
        letter-spacing: -0.046875rem;
        line-height: 3.75rem;
      }

      @include media('xl-up') {
        font-size: 4.5rem;
        letter-spacing: -0.046875rem;
        line-height: 4.5rem;
      }
    }
  }

  &__podcast-container:nth-child(2),
  &__podcast-container:nth-child(3) {
    width: 100%;
    border-bottom: $border-gray-darker;
    border-right: none;
    padding-left: 0;
    padding-right: 0;

    @include media('md-up') {
      width: calc(50% - 0.75rem);
      border-right: $border-gray-darker;
      padding-right: 1.5rem;
    }

    .PodcastGridItems__podcast-inner-container {
      @include media('md-up') {
        flex-direction: column;
      }
    }

    .PodcastGridItems__podcast-image-container {
      min-width: 100%;
      max-width: 100%;
    }

    .PodcastGridItems__podcast-image-container {
      margin-right: 0;
    }

    .PodcastGridItems__podcast-info-container {
      margin-top: 1.5rem;
    }
  }

  &__arrow {
    width: 0.875rem;
  }

  &__title-container {
    margin-bottom: 12px;

    @include media('md-up') {
      margin-bottom: 24px;
    }
  }
}
