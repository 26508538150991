.ArticleSectionGrid {
  padding-top: 9.5rem;

  &__title-container {
    padding-bottom: 2.6875rem;
  }

  &__title {
    letter-spacing: 0.075rem;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  &__sections-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(auto, minmax(0, 1fr));
    grid-gap: 0.9375rem;

    @include media('sm-up') {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: auto;
      grid-gap: 1.5rem;
    }
  }

  &__section-container {
    border-bottom: $border-black;
    border-right: $border-black;
  }

  &__logo-container {
    height: 3.75rem;

    @include media('sm-up') {
      height: 4.5rem;
    }

    @include media('md-up') {
      height: 6rem;
    }

    @include media('lg-up') {
      height: 7.5rem;
    }
  }

  &__section-logo {
    transform: translateX(0);
    transition: 0.35s $transition-easing;
    height: 0.875rem;

    @include media('md-up') {
      height: 1rem;
    }

    @include media('lg-up') {
      height: 1.5rem;
    }

    @include media('xl-up') {
      height: 1.875rem;
    }
  }

  &__arrow {
    line-height: 1rem;
    transform: translateX(0);
    transition: 0.35s $transition-easing;
    font-size: 1.5rem;

    @include media('lg-up') {
      font-size: 2.25rem;
    }
  }

  &__section-container:hover {
    .ArticleSectionGrid__arrow {
      opacity: 1;
      transform: translateX(1rem);

      @include media('lg-up') {
        transform: translateX(2rem);
      }
    }

    .ArticleSectionGrid__section-logo {
      transform: translateX(-0.5rem);

      @include media('lg-up') {
        transform: translateX(-1rem);
      }
    }
  }
}
