.ArticleTagsBlock {
  margin-top: 2.25rem;

  &__border {
    margin-bottom: 1.1875rem;
  }

  &__tag {
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.055rem;

    @include media('lg-up') {
      font-size: 1rem;
      letter-spacing: 0.0625rem;
    }
  }
}
