.DonationPage {
  width: 100%;

  &__header {
    width: 100%;
    height: 424px;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  p {
    a {
      @extend %linked-border-bottom-white;
      color: inherit;
      text-decoration: none;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 64px;
    max-width: 472px;
    margin: 0 auto;

    .DonationPage__content-left,
    .DonationPage__content-right {
      display: flex;
      flex-direction: column;
    }

    // On mobile: Headline(1) → Body(2) → Embed(3) → Footer(4)
    .DonationPage__headline-block {
      order: 1;
    }
    .DonationPage__body-text {
      order: 2;
    }
    .DonationPage__embed-container {
      order: 3;
    }
    .DonationPage__footer-text {
      order: 4;
    }

    @include media('sm-up') {
      max-width: 1728px;
      flex-direction: row;
      gap: 39px;
      align-items: flex-start;
      padding: 36px 84px 84px;

      .DonationPage__content-left {
        order: 1;
        flex: 1 1 50%;
        .DonationPage__headline-block {
          order: 1;
        }
        .DonationPage__body-text {
          order: 2;
        }
        .DonationPage__footer-text {
          order: 3;
          margin-top: auto;
        }
      }

      .DonationPage__content-right {
        order: 2;
        flex: 1 1 50%;
        .DonationPage__embed-container {
          order: 1;
        }

        @include media('sm-up') {
          margin-top: -150px;
        }

        @include media('md-up') {
          margin-top: -215px;
        }
      }
    }
  }

  &__headline {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 35px;
    text-align: left;

    @include media('md-up') {
      font-size: 3rem;
    }
  }

  &__body-text {
    line-height: 36px;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 45px;

    p + p {
      margin-top: 20px;
    }

    @include media('lg-up') {
      font-size: 26px;
    }

    @include media('sm-up') {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  &__embed-container {
    width: 100%;
    max-width: 768px;
    margin-bottom: 30px;

    @include media('sm-up') {
      margin-bottom: 0;
    }
  }

  &__footer-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-transform: uppercase;

    &--desktop {
      display: none;

      @include media('sm-up') {
        display: block;
        margin-top: auto;
      }
    }

    &--mobile {
      display: block;
      margin-top: 0;

      @include media('sm-up') {
        display: none;
      }

      @include media('xs-down') {
        margin-top: 70px;
      }
    }
  }
}
