/* AMP-specific overrides */
amp-img.Img {
  &.opacity-0 {
    opacity: 1;
  }
}

html[amp-version] {
  .ArticleSectionGrid {
    display: none;
  }
}
