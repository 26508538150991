.Divider {
  &--dotted-rule {
    @extend %dotted-border-black;
    height: 1px;
  }

  &--rule {
    border-bottom: $border-black;
  }

  &--short-rule {
    height: 1px;
    margin: 3.25rem 0;
    position: relative;

    @include media('lg-up') {
      margin-bottom: 3.5rem;
      margin-top: 3.75rem;
    }

    &:before {
      background-color: color('black');
      content: '';
      height: 100%;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 3.75rem;
    }
  }

  &--dotted-rule,
  &--rule {
    margin-bottom: 2.25rem;
    margin-top: 4.25rem;

    @include media('md-up') {
      margin-top: 4rem;
    }

    @include media('lg-up') {
      margin-bottom: 2.05rem;
      margin-top: 5.25rem;
    }

    @include media('xl-up') {
      margin-bottom: 1.75rem;
    }
  }

  &--space {
    padding-bottom: 5rem;

    @include media('sm-up') {
      padding-bottom: 4.85rem;
    }

    @include media('md-up') {
      padding-bottom: 5.85rem;
    }

    @include media('xl-up') {
      padding-bottom: 5.7rem;
    }
  }
}
