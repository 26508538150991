.NewsletterForm {
  &__container {
    padding-top: 4.0625rem;
    padding-bottom: 2.8125rem;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    @include media('sm-up') {
      padding-top: 3.4375rem;
      padding-bottom: 5.25rem;
      padding-right: 4.5rem;
      padding-left: 4.5rem;
    }

    @include media('md') {
      padding-bottom: 6rem;
      padding-top: 4.1875rem;
    }

    @include media('md-up') {
      padding-left: 5.25rem;
      padding-right: 5.25rem;
    }

    @include media('xl-up') {
      padding-right: 11.25rem;
      padding-left: 11.25rem;
    }
  }

  &--active {
    box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.5);
    max-height: 85vh;
    overflow-y: scroll;
  }

  &--inactive {
    transform: translate3d(0, 100vh, 0);
  }

  &__close-icon {
    margin: 1.5rem;
  }

  &__description {
    max-width: 26.25rem;
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('md-up') {
      font-size: 1.25rem;
      line-height: 1.625rem;
    }
  }

  &__text-field-container {
    max-width: 35.25rem;
  }

  &__text-field {
    border-right: $border-black;
    max-width: 35.25rem;
    height: 3rem;

    .TextField__info-message {
      font-size: 1rem;
      line-height: 1.625rem;
      color: color('red');
      margin-top: 5rem;
    }
  }

  &__success-msg-container {
    max-width: 34rem;
    margin-top: -4rem;

    &__popup {
      max-width: 34rem;
      margin-top: -8rem;
    }
  }

  &__success-icon {
    max-width: 0.9375rem;
  }

  &__loading-spinner {
    padding-top: 0.7rem;
  }

  &__dot {
    width: 6px;
    height: 6px;
    background-color: color('black');
    display: inline-block;
    animation: dot-bounce 1.4s infinite ease-in-out both;
    margin: 0 0.1rem;
  }

  &__dot1 {
    animation-delay: -0.32s;
  }

  &__dot2 {
    animation-delay: -0.16s;
  }
}

@keyframes dot-bounce {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
