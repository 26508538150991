.Loader {
  &--black {
    .Loader__dot {
      background: color('black');
    }
  }

  &--white {
    .Loader__dot {
      background: color('white');
    }
  }

  &__dot {
    animation: bounce 1.75s $transition-easing forwards infinite;
    border-radius: 50%;
    margin: 0 0.125rem;
    width: 0.375rem;
    height: 0.375rem;

    &:nth-child(2n) {
      animation-delay: 0.25s;
    }

    &:nth-child(3n) {
      animation-delay: 0.5s;
    }
  }

  @keyframes bounce {
    0% {
      transform: translate3d(0, 0, 0);
    }

    25% {
      transform: translate3d(0, -0.25rem, 0);
    }

    50% {
      transform: translate3d(0, 0, 0);
    }
  }
}
