.App__content-container--collections {
  .content-padding-x {
    @include media('sm-up') {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    @include media('md-up') {
      padding-left: 5.25rem;
      padding-right: 5.25rem;
    }
  }
}

.CollectionGridItems {
  gap: 1.5rem;
  padding-bottom: 6rem;

  .teaser-widget-container {
    border-bottom: $border-black;
  }

  .last-item {
    @include media('xs-down') {
      border: none;
    }
  }

  @include media('sm-up') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
  }

  @include media('md-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__container {
    padding-bottom: 2rem;

    @include media('sm-up') {
      padding-bottom: 0rem;
    }
  }

  &__image {
    margin-top: 0.9375rem;
    vertical-align: middle;

    @include media('sm-up') {
      margin-top: 0;
    }
  }

  &__title-button {
    padding-top: 1rem;

    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.25rem;
    letter-spacing: -0.02em;

    @include media('lg-up') {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

  &__description {
    padding-top: 1rem;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      letter-spacing: 0;
    }
  }
}
