.BlockQuote {
  @include media('md-up') {
    margin-left: $article-reference-text-spacing-md;
    margin-right: $article-reference-text-spacing-md;
  }

  @include media('lg-up') {
    margin-left: $article-reference-text-spacing-lg;
    margin-right: $article-reference-text-spacing-lg;
  }

  @include media('xl-up') {
    margin-left: $article-reference-text-spacing-xl;
    margin-right: $article-reference-text-spacing-xl;
  }

  &__text {
    width: calc(100% - #{$block-quote-spacing-mobile});
    transform: translateX($block-quote-spacing-mobile);

    @include media('md-up') {
      width: calc(100% - #{$block-quote-spacing-desktop});
      transform: translateX($block-quote-spacing-desktop);
    }
  }
}
