.NewsletterWidget {
  &--style-full-width {
    padding: 3rem $content-padding-x-sm;

    @include media('sm-up') {
      padding: 3rem $content-padding-x-md;
    }

    @include media('md-up') {
      padding: 3rem $content-padding-x-lg;
    }

    @include media('xl-up') {
      padding: 3rem $content-padding-x-xl;
    }
  }

  &__text-field-container--black {
    border: $border-black;

    &:hover {
      transition: $transition-duration-short;
      background-color: color('gray-darker');
      border: $border-transparent;
    }

    &--focus-state {
      transition: $transition-duration-short;
      color: color('black');
      background-color: color('off-white');
      border: $border-transparent;

      &:hover {
        transition: $transition-duration-short;
        color: color('black');
        background-color: color('off-white');
        border: $border-transparent;
      }
    }
  }

  &__text-field-container--red {
    border: $border-red;
  }

  &__check-icon {
    max-width: 0.9375rem;
  }

  &__success-container--normal {
    max-width: 18.125rem;
  }

  &__success-container--full-width {
    max-width: 18.125rem;
    margin: 0 2.5rem;

    @include media('md-up') {
      max-width: 100%;
      margin: 0;
    }
  }

  &__text {
    @include media('md-up') {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  &__info-message--active {
    opacity: 1;
  }

  &__try-again--red {
    background-image: linear-gradient(color('red'), color('red'));
    background-position: right 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size $transition-duration-short;

    &:hover {
      background-size: 0% 1px;
    }
  }

  &__try-again--white {
    background-image: linear-gradient(color('white'), color('white'));
    background-position: right 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size $transition-duration-short;

    &:hover {
      background-size: 0% 1px;
    }
  }

  &__try-again--beige {
    background-image: linear-gradient(color('beige'), color('beige'));
    background-position: right 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size $transition-duration-short;

    &:hover {
      background-size: 0% 1px;
    }
  }
}
