.BlockTitle {
  max-width: $inner-content-max-width;

  &--sans-serif {
    h1,
    h2,
    p {
      font-size: 1.5rem;
    }
  }

  &--serif {
    &:first-of-type {
      padding-top: 3.5rem;
      padding-bottom: 2.375rem;

      @include media('md-up') {
        padding-bottom: 2.3125rem;
      }

      @include media('lg-up') {
        padding-top: 5.75rem;
        padding-bottom: 2.25rem;
      }

      @include media('lg') {
        padding-top: 6.25rem;
        padding-bottom: 5rem;
      }
    }

    h1,
    h2,
    p {
      @extend %text-global-header;
    }
  }
}
