.ColumnsView {
  &__header {
    margin-top: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;

    @include media('xs-up') {
      padding-top: 1.5rem;
    }

    @include media('md-up') {
      padding-top: 2.5rem;
      padding-bottom: 2rem;
    }

    @include media('lg-up') {
      padding-top: 3rem;
      padding-bottom: 2.5rem;
    }
  }

  &__title {
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 0.5rem;

    @include media('sm-up') {
      padding-bottom: 1rem;
    }

    @include media('lg-up') {
      font-size: 3.75rem;
      line-height: 3.75rem;
    }
  }

  &__description {
    font-size: 1.125rem;
    line-height: 1.5rem;

    @include media('sm-up') {
      width: 50%;
      margin: 0 auto;
    }

    @include media('lg-up') {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}
