$three-grid-item-width: calc(33.3% - (0.75rem * 2));
$two-grid-item-width: calc(50% - (0.75rem * 2));

.Contributor {
  &__name {
    line-height: 3rem;

    @include media('md-up') {
      font-size: 3.75rem;
    }
  }

  &__bio {
    max-width: 39rem;
    margin-top: 1rem;

    @include media('md-up') {
      margin-top: 1.375rem;
    }

    @include media('lg') {
      margin-top: 1.5625rem;
    }
  }
}
