:root {
  --black: #222222;
  --gray-darkest: #444444;
  --gray-darker: #666666;
  --gray-dark: #888888;
  --gray: #999999;
  --gray-light: #9b9b9b;
  --gray-lighter: #cccccc;
  --gray-lightest-opacity-50: rgba(230, 230, 230, 0.5);
  --off-white: #f7f6f2;
  --white-dark: #e6e6e6;
  --white: #ffffff;
  --white-opacity-10: rgba(255, 255, 255, 0.1);
  --beige-light: #f7f7f2;
  --beige-dark: #e2dacb;
  --beige: #efe9dc;
  --red: #dd3418;
  --transparent: rgba(255, 255, 255, 0);
  --bronze: #97773b;
  --fiction-pink: #fdefed;
  --fiction-blue: #10387b;
  --collection-background-grey: #e0dfdc;
  --collection-background-grey-section: #dad4c6;
  --sand: #f1d5b9;
}

$colors: (
  'black': var(--black),
  'gray-darkest': var(--gray-darkest),
  'gray-darker': var(--gray-darker),
  'gray-dark': var(--gray-dark),
  'gray': var(--gray),
  'gray-light': var(--gray-light),
  'gray-lighter': var(--gray-lighter),
  'gray-lightest-opacity-50': var(--gray-lightest-opacity-50),
  'off-white': var(--off-white),
  'white-dark': var(--white-dark),
  'white': var(--white),
  'white-opacity-10': var(--white-opacity-10),
  'beige-light': var(--beige-light),
  'beige-dark': var(--beige-dark),
  'beige': var(--beige),
  'red': var(--red),
  'transparent': var(--transparent),
  'bronze': var(--bronze),
  'fiction-pink': var(--fiction-pink),
  'fiction-blue': var(--fiction-blue),
  'collection-background-grey': var(--collection-background-grey),
  'collection-background-grey-section':
    var(--collection-background-grey-section),
  'sand': var(--sand),
);
