$half-width-quote-desktop-width: 32.3125rem;
$half-width-quote-desktop-height: 41.6875rem;

.PullQuote {
  @media (min-width: 375px) {
    margin-top: 0;
  }

  &--center,
  &--left,
  &--right {
    border-top: $border-black;

    &__text {
      font-size: 2.25rem;
      line-height: 2.625rem;
    }
  }

  &--center {
    &__text {
      font-size: 2.25rem;
      line-height: 2.625rem;

      @media (min-width: 375px) {
        letter-spacing: -0.015625rem;
      }

      @media (min-width: 768px) {
        font-size: 3rem;
        line-height: 3.375rem;
      }

      @media (min-width: 1440px) {
        font-size: 3.75rem;
        line-height: 4.125rem;
      }
    }

    @media (min-width: 375px) {
      padding: 1.75rem 0 2.5rem;
      margin: 3rem 0 1.25rem;
    }

    @media (min-width: 1440px) {
      margin-left: calc(100% / 12);
      margin-right: calc(100% / 12);
    }
  }

  &--left,
  &--right {
    &__text {
      @media (min-width: 1080px) {
        letter-spacing: -0.011875rem;
      }

      @media (min-width: 1440px) {
        font-size: 3rem;
        line-height: 3.375rem;
        letter-spacing: -0.015625rem;
      }
    }

    @media (min-width: 1080px) {
      border-top: none;
      padding-right: 2.8125rem;
      width: calc(100% / 12 * 6 - (1.5rem / 2));
      height: auto;
    }

    @media (min-width: 1440px) {
      width: calc(100% / 12 * 5 - (1.5rem / 2));
    }
  }

  &--left {
    @media (min-width: 1080px) {
      border-right: $border-black;
      float: left;
      margin-right: 1.25rem;
    }

    @media (min-width: 1440px) {
      margin-left: calc(100% / 12);
    }
  }

  &--right {
    @media (min-width: 1440px) {
      border-left: $border-black;
      float: right;
      margin-left: 1.25rem;
      padding-left: 1.25rem;
    }

    @media (min-width: 1440px) {
      margin-right: calc(100% / 12);
    }
  }

  &__text {
    font-family: $itc-cushing;
  }

  &__text::before {
    content: '“';
  }

  &__text::after {
    content: '”';
  }

  &__share-button {
    @media (min-width: 375px) {
      margin-top: 6rem;
    }
  }
}
