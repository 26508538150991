.PodcastEpisode {
  max-width: $inner-content-max-width;

  &__content-container {
    .EmbedMedia.medium {
      padding: 0;
      margin: 0;
    }
    border-top: $border-gray-darker;

    > div > div > p {
      margin-bottom: 1rem;

      > a {
        @extend %linked-border-bottom-sm-white-dark;
        text-decoration: none;
        color: inherit;
      }
    }

    @include media('sm-up') {
      margin-top: 2.5rem;
    }

    @include media('md-up') {
      border-top: none;
    }

    a.InPageAnchor {
      position: absolute;
      visibility: hidden;
      margin-top: -80px;
    }
  }
}
