.Img {
  transition: opacity $transition;
}

.ImageCreditLink {
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
    background-image: linear-gradient(color('red'), color('red'));
    background-repeat: no-repeat;
    background-size: 0% 1.75px;
    transition: background-size $transition-duration-short;
    background-position: left 100%;

    &:hover {
      background-size: 100% 1.75px;
    }
  }
}
