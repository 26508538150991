.LayoutL {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-template-rows: auto;

  @include media('sm-up') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(1, auto);
    grid-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  &__article1-container {
    grid-column: 1/2;
    grid-row: 1/2;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 1/2;
      grid-row: 1/2;
      border-bottom: initial;
    }
  }

  &__article2-container {
    grid-column: 1/2;
    grid-row: 2/3;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 2/3;
      grid-row: 1/2;
      border-bottom: initial;
    }
  }

  &__article3-container {
    grid-column: 1/2;
    grid-row: 3/4;
    border-bottom: $border-black;

    @include media('sm-up') {
      grid-column: 3/4;
      grid-row: 1/2;
      border-bottom: initial;
    }
  }
}
