.collections-10-stories-widget {
  &__container {
    padding: 1rem 1rem 2rem 1rem;

    @include media('sm-up') {
      padding: 1.5rem 1.5rem 3rem 1.5rem;
    }
  }
  .collections-4-stories {
    padding: 0rem 1rem 2rem 1rem;
    border-top: $border-black-dotted;

    @include media('sm-up') {
      padding: 0rem 1.5rem 3rem 1.5rem;
    }
  }
  .collections-4-stories-widget {
    &__container {
      @include media('xs-down') {
        padding-top: 0;
      }
      @include media('sm') {
        padding-top: 0.5rem;
      }
      &__article1-container {
        @include media('md') {
          padding-top: 1rem;
        }
      }
    }
  }
  .collections-6-stories-widget__article5-article6-container {
    @include media('xs-down') {
      grid-gap: 1.5rem;
    }

    ::after {
      height: 100%;
    }
  }
  .collections-6-stories-widget__article3-article4-article5-article6-container {
    @include media('xs-down') {
      grid-gap: 1.5rem;
    }

    ::after {
      height: 100%;
    }
  }
  .collections-6-stories-widget {
    @include media('xs-down') {
      padding-top: 1rem;
    }

    &__container {
      margin-top: 1rem;
      padding: 0;

      @include media('sm-up') {
        margin-top: 1.5rem;
      }
    }
  }
  .collections-6-stories-widget__article1-container {
    border-bottom: $border-black-dotted;
    margin-top: 0;
  }
}
