$three-grid-item-width: calc(33.3% - (0.75rem * 2));
$two-grid-item-width: calc(50% - (0.75rem * 2));

.ItemGrid {
  min-width: 100%;

  &__container {
    @include media('sm-up') {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }

  &__border {
    margin-top: 1.375rem;

    @include media('xs-up') {
      margin-top: 2.5rem;
    }

    @include media('md') {
      margin-top: 3.875rem;
    }

    @include media('xl-up') {
      margin-top: 5.375rem;
    }

    &--collapse-margin {
      margin-top: 0;
    }
  }

  &__pagination {
    height: 5rem;

    &__arrow {
      width: 1.1em;
      margin-left: 0.4rem;
      img {
        width: auto;
        display: inherit;
        transition: $transition-duration-short;
        transform: rotate(-90deg);
      }
    }

    button:hover {
      img {
        transform: translateY(4px) rotate(-90deg);
      }
    }
  }

  &__container {
    > .ItemGrid__item {
      width: 50%;
      overflow: hidden;
      margin-top: 0.9375rem;

      @media (max-width: 767px) {
        border-right: none;
        padding-bottom: 1.875rem;
        border-bottom: $border-black;
      }

      @include media('sm-up') {
        margin-top: 1.5rem;
        padding-right: 1.5rem;
      }

      > div {
        flex-grow: 1;

        @media (max-width: 767px) {
          padding-right: 0;
          border-bottom: none;
        }
      }

      @include media('sm-up') {
        width: $two-grid-item-width;
      }

      @include media('md-up') {
        width: $three-grid-item-width;
      }

      @include media('lg-up') {
        width: calc(25% - (0.75rem * 2));
      }
    }

    > .ItemGrid__item:nth-child(odd) {
      border-bottom: none;

      @include media('sm-up') {
        border-bottom: $border-black;
      }

      > div {
        @media (max-width: 767px) {
          padding-left: 0.9375rem;
          padding-right: 0;
        }
      }
    }

    > .ItemGrid__item:nth-child(even) {
      border-bottom: none;
      position: relative;
      overflow: visible;

      &::after {
        content: '';
        position: absolute;
        min-width: calc(100vw - 0.9375rem * 2);
        border-bottom: $border-black;
        bottom: 0;
        left: 0;
      }

      @include media('sm-up') {
        border-bottom: $border-black;

        &::after {
          display: none;
        }
      }

      > div {
        @media (max-width: 767px) {
          padding-right: 0.9375rem;
          border-right: $border-black !important;
        }
      }
    }

    > .ItemGrid__item:nth-child(1) {
      width: 100%;
      border-bottom: $border-black;

      > div {
        padding-left: 0;
      }

      @include media('sm-up') {
        width: $two-grid-item-width;
      }

      @include media('md-up') {
        width: $three-grid-item-width;
      }
    }

    > .ItemGrid__item:nth-child(2) {
      width: 100%;

      > div {
        padding-right: 0;
        border-right: none !important;

        @include media('sm-up') {
          border-right: $border-black;
        }
      }

      @include media('sm-up') {
        width: $two-grid-item-width;
      }

      @include media('md-up') {
        width: $three-grid-item-width;
      }
    }

    > .ItemGrid__item:nth-child(3) {
      width: 100%;
      border-bottom: $border-black;

      > div {
        padding-left: 0;
      }

      @include media('sm-up') {
        width: $two-grid-item-width;
      }

      @include media('md-up') {
        width: $three-grid-item-width;
      }
    }
  }
}
