.TeaserGrid {
  &__inner {
    display: grid;
    grid-gap: 15px;

    grid-template-columns: 1fr;

    .Teaser {
      border-bottom: 1px solid currentColor;
      width: 100%;
    }

    @media screen and (min-width: 540px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 25px;
    }

    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: 1140px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
